import { observable, action, computed, makeObservable } from 'mobx';
import { uniq } from 'lodash';

import { DBTeams } from '@firebase/dbUtil';
import { sortAlphabetically } from 'utils/AppUtils';
import { RootStore } from 'stores';

import { Team } from 'objects/Team';

class TeamStore {
  rootStore: any;
  teams: Record<string, Team> = null;
  loaded = false;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      loaded: observable,
      teams: observable,
      fetchTeams: action,
      setTeams: action,
      addNewTeam: action,
      deleteTeam: action,
      deleteTeams: action,
      findTeam: action,
      findTeams: action,
      updateLearnersOnTeams: action,
      getTeams: computed,
      getTeamsLearners: computed,
    });

    this.rootStore = rootStore;
  }

  setLoaded = (loaded) => {
    this.loaded = loaded;
  };

  setTeams = (teams) => {
    Object.keys(teams || {}).forEach((key) => {
      teams[key].key = key;
    });
    this.teams = teams;
    this.loaded = true;
  };

  fetchTeams = (projectUID) => {
    return new DBTeams(projectUID).getAll((snapshot) => {
      this.setTeams(snapshot.val());
    });
  };

  findTeam = (key) => this.teams[key] || null;

  findTeams = (keys) => keys.map((key) => this.findTeam(key));

  findTeamByName = (name): Team => (this.teams ? Object.values(this.teams).find((team) => team.name === name) : null);

  findTeamKeyByName = (name): string =>
    this.teams ? Object.keys(this.teams).find((k) => this.teams[k].name === name) : null;

  addNewTeam = (projectUID, newTeamName): Partial<Team> => {
    const hasDuplicate = this.findTeamByName(newTeamName);
    if (hasDuplicate) return hasDuplicate;

    const newTeam: Team = {
      name: newTeamName,
      learners: [],
    };

    const dbRef = new DBTeams(projectUID).pushItem(newTeam);
    this.teams = { ...this.teams, [dbRef.key]: { ...newTeam, key: dbRef.key } };

    return { ...newTeam, key: dbRef.key };
  };

  addLearnerToTeam = (projectUID: string, teamUID: string, learnerUID: string) => {
    if (!this.teams[teamUID].learners) this.teams[teamUID].learners = [];
    this.teams[teamUID].learners.push(learnerUID);
    new DBTeams(projectUID).setItem(teamUID, this.teams[teamUID]);
  };

  deleteTeam = (projectUID: string, teamKey) => {
    delete this.teams[teamKey];
    new DBTeams(projectUID).removeItem(teamKey);
  };

  deleteTeams = (projectUID: string, keys) => keys.forEach((key) => this.deleteTeam(projectUID, key));

  updateTeam = (projectUID: string, key, team) => {
    this.teams[key] = team;
    new DBTeams(projectUID).updateItem(key, team);
  };

  updateLearnersOnTeams = (projectUID, teamsToDelete, teamsToAdd, learnerUID) => {
    teamsToDelete.forEach((key) => {
      const team = this.teams[key];
      const _team = {
        ...team,
        learners: team.learners.filter((learner) => learner !== learnerUID),
      };
      this.updateTeam(projectUID, key, _team);
    });

    teamsToAdd.forEach((key) => {
      const team = this.teams[key];
      if (!team.learners) team.learners = [];
      team.learners.push(learnerUID);
      team.learners = uniq(team.learners);
      this.updateTeam(projectUID, key, team);
    });
  };

  get getTeams() {
    return this.teams
      ? Object.keys(this.teams).map((key) => ({
          name: this.teams[key].name,
          key,
          learners: this.teams[key].learners || [],
        }))
      : [];
  }

  get getTeamsAlphabeticallyOrdered() {
    return this.getTeams.sort(sortAlphabetically);
  }

  get getTeamsLearners() {
    return this.teams
      ? Object.keys(this.teams).map((key) => ({
          name: this.teams[key].name,
          key,
        }))
      : [];
  }

  get getTeamsLearnersAlphabeticallyOrdered() {
    return this.getTeamsLearners.sort(sortAlphabetically);
  }
}

export default TeamStore;
