import { DBSteps } from './DBSteps';
import { DBList } from './DBStructures';
import Decision from '../../objects/Decision';

export class DBDecisions extends DBList<Decision> {
  public projectUID: string;
  public processUID: string;
  public stepUID: string;

  constructor(projectUID: string, processUID: string, stepUID: string) {
    super();
    this.projectUID = projectUID;
    this.processUID = processUID;
    this.stepUID = stepUID;
    const stepsRef = new DBSteps(projectUID, processUID).ref;
    this.ref = stepsRef.child(this.stepUID).child('decisions');
  }
}
