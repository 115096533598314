import german from './de-DE.json';
import spanish from './es-ES.json';
import french from './fr-FR.json';
import italian from './it-IT.json';
import japanese from './ja-JA.json';
import dutch from './nl-NL.json';
import portuguese from './pt-BR.json';
import swedish from './sv-SV.json';
import english from './en-US.json';

export default {
  'de-DE': german,
  'es-ES': spanish,
  'fr-FR': french,
  'it-IT': italian,
  'ja-JA': japanese,
  'nl-NL': dutch,
  'pt-BR': portuguese,
  'sv-SV': swedish,
  'en-US': english,
};
