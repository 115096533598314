export enum Providers {
  Manual = 'manual',
  Google = 'google',
  Facebook = 'facebook',
  Twitter = 'twitter',
  Microsoft = 'microsoft',
  Anonymous = 'anonymous',
}

export type Provider = {
  available: true;
  label: string;
  value: Providers;
};
