import { StyleTheme } from 'objects/StyleTheme';
import { themedMakeStyles } from 'providers/ThemeProvider';
import { useStores } from 'stores/useStores';
import { CSSVariables } from 'tools/CSSVariables';

const defaultSpacing = '2vh';
const decisionSpacing = '4px';

const useGameStyles = themedMakeStyles<{ theme: StyleTheme }>((theme) => ({
  gamePage: ({ theme: { game, fonts } }) => ({
    color: CSSVariables.getColor(fonts.body.color),
    backgroundColor: CSSVariables.getColor(game.backgroundColor),
    overflow: 'auto',
    zIndex: 0,
  }),

  gamePageInner: ({ theme: { game } }) => ({
    wordBreak: 'break-word',
    margin: '0vh auto 0vh',
    position: 'absolute',
    bottom: '0px',
    left: '0',
    right: '0',
    ...(game.width === 'full'
      ? {
          width: '100%',
        }
      : {
          width: '90%',
          maxWidth: '900px',
        }),

    // '& > *': {
    //   marginTop: defaultSpacing,
    // },
  }),

  decision: ({ theme: { decision, fonts } }) => ({
    ...CSSVariables.getJSSFont(fonts.button),
    ...CSSVariables.getJSSBorder(decision.border),
    backgroundColor: CSSVariables.getColor(decision.backgroundColor),
    boxShadow: CSSVariables.getJSSBoxShadow(decision.boxShadow),
    // transform: decision.angle,

    margin: `0 ${decisionSpacing} 8px ${decisionSpacing}`,

    textAlign: 'left',
    cursor: 'pointer',
    padding: '.5em',

    '&:hover': {
      filter: 'brightness(1.2)',
    },
  }),

  decisionTaken: ({ theme: { decision, decisionTaken, fonts } }) => ({
    opacity: decisionTaken.opacity,
    transform: decisionTaken.angle,

    ...CSSVariables.getJSSFont(fonts.button),

    ...CSSVariables.getJSSBorder(decision.border),
    backgroundColor: CSSVariables.getColor(decision.backgroundColor),
    boxShadow: CSSVariables.getJSSBoxShadow(decision.boxShadow),

    textAlign: 'right',
    width: 'fit-content',
    // maxWidth: '80%',
    lineHeight: 'normal',
    display: 'flex',
    alignItems: 'center',

    padding: '.7em',
    margin: theme.spacing(0, decisionSpacing, 0),

    '& > svg': {
      fontSize: 'inherit',
      marginRight: '0.5em',
      ...CSSVariables.getJSSWidthHeight(fonts.button),
      '& > path': {
        fill: CSSVariables.getColor(fonts.button.color),
      },
    },
  }),

  step: ({ theme: { step, fonts, link } }) => ({
    '& > *': {
      width: 'fit-content',
    },
    '& p': {
      ...CSSVariables.getJSSFont(fonts.body),
    },
    '& h1': {
      ...CSSVariables.getJSSFont(fonts.heading1),
    },
    '& h2': {
      ...CSSVariables.getJSSFont(fonts.heading2),
    },
    '& p, h1, h2': {
      ...CSSVariables.getJSSBorder(step.border),
      backgroundColor: CSSVariables.getColor(step.backgroundColor),
      margin: `0px ${decisionSpacing} ${defaultSpacing} ${decisionSpacing} !important`,
      boxShadow: CSSVariables.getJSSBoxShadow(step.boxShadow),
      transform: step.angle,
      width: 'fit-content',
    },
    '& a': {
      color: CSSVariables.getColor(link.fontColor),
      textDecoration: 'underline !important',

      '&:hover': {
        color: CSSVariables.getColor(link.hoverFontColor),
        textDecoration: 'underline !important',
      },
    },

    '& .primaryColor': {
      color: 'inherit',
      fontWeight: 'inherit',
    },
  }),

  image: ({ theme: { image, step } }) => ({
    ...CSSVariables.getJSSBorder(image.border),
    transform: image.angle,
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto',

    maxWidth: '100%',
    boxSizing: 'border-box',
    objectFit: 'contain',
    height: '30vh',
    marginBottom: '5vh',
  }),

  video: ({ theme: { video } }) => ({
    ...CSSVariables.getJSSBorder(video.border),
    overflow: 'hidden',
  }),

  scoreContainer: ({ theme: { score, fonts, step } }) => ({
    fontSize: 20,
    backgroundColor: CSSVariables.getColor(score.backgroundColor),
    color: CSSVariables.getColor(score.fontColor),
    boxShadow: '5px 5px 10px rgb(0 0 0 / 35%) !important',
    borderRadius: step.border.radius,

    [theme.breakpoints.up('md')]: {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderLeft: 'none !important',
    },
  }),

  separatorStat: ({ theme: { score, step, fonts } }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: '0.5em',
    float: 'left',
    margin: theme.spacing(0, decisionSpacing, 0),

    ...CSSVariables.getJSSFont(fonts.body),

    ...CSSVariables.getJSSBorder(step.border),
    backgroundColor: CSSVariables.getColor(score.backgroundColor),
    color: CSSVariables.getColor(score.fontColor),
    boxShadow: CSSVariables.getJSSBoxShadow(step.boxShadow),

    transform: step.angle,

    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },

    '& > *:not(:first-child)': {
      marginLeft: '8px',
    },
  }),
}));

export const useGameTheme = () => {
  const { styleThemes } = useStores();
  const theme = styleThemes.activeStyleTheme;

  const classes = useGameStyles({ theme });

  return {
    theme,
    classes,
  };
};
