import ReactGA from 'react-ga';

import { firebaseAnalytics } from './firebaseUtil';

function logEvent<T extends { projectUID?: string }>(eventName) {
  return function (eventParams?: T, options?) {
    firebaseAnalytics.logEvent(eventName, eventParams, options);

    const { projectUID } = eventParams || {};

    if (projectUID) {
      ReactGA.event({
        category: `Adeptly - ${projectUID}`,
        action: eventName,
      });
    }
  };
}

export const analytics = {
  // Decisions events
  startGame: logEvent('games_started'),
  firstStartGame: logEvent('games_unique_gameplays'),
  gamesCompleted: logEvent('games_completed'),
  gameFailed: logEvent('games_failed'),
  gamesDecisions: (params) => logEvent(`games_decisions`)(params),
  gamesDecisionsWithOutcomeAndImpact: ({ outcome = 'None', impact = 'None' }, params) =>
    logEvent(`games_decisions_${outcome?.toLowerCase()}_${impact?.toLowerCase()}`)(params),
  gameRestarted: logEvent('games_restarted'),
  gameResumed: logEvent('games_resumed'),
  gameProcessStarted: logEvent('games_process_started'),

  // Learner events
  learnerAdded: logEvent('games_new_signup'),
  playTime: logEvent('games_total_play_duration'),

  // Customer events
  newCustomerSignUp: ({ plan = 'None' }) => logEvent(`customer_new_signup_${plan?.toLowerCase()}`)(),
  projectCreated: logEvent('project_created'),
  projectDeleted: logEvent('project_deleted'),
};
