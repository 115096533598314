import { validate as uuidValidate, v4 as uuid } from 'uuid';
import { DBStyleThemes } from '@firebase/dbUtil';
import { TextField } from '@material-ui/core';
import { makeAutoObservable } from 'mobx';
import _ from 'lodash';
import Bugsnag from '@bugsnag/js';

import { RootStore } from 'stores';
import { styleTheme, StyleTheme, ThemeFontsKey } from 'objects/StyleTheme';
import Project from 'objects/Project';
import { AdeptlyStyleThemeNames, AdeptlyStyleThemes } from 'objects/StyleTheme/AdeptlyStylingThemes';
import { CSSVariables } from 'tools/CSSVariables';
import JsonSchemaValidator from 'tools/JsonSchemaValidator';
import { ExportFileService } from 'services/ExportFileService';
import { exportedStyleThemeSchema } from 'validators/json/styleTheme';

export default class StyleThemesStore {
  rootStore: RootStore;
  loaded = false;
  activeId: string;
  activeStyleTheme: StyleTheme;
  names: Record<string, string>;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);

    this.rootStore = rootStore;
  }

  get projectUID() {
    return this.rootStore.projectStore.uid;
  }

  get dbStyleThemes() {
    return new DBStyleThemes(this.projectUID);
  }

  get activeThemeType(): 'adeptly' | 'custom' {
    return this.activeThemeName ? 'custom' : 'adeptly';
  }

  get activeThemeName(): string {
    return this.names[this.activeId];
  }

  setLoaded = (loaded) => {
    this.loaded = loaded;
  };

  // Migration for old projects
  setupEmptyStyledThemesProjects = async () => {
    await this.dbStyleThemes.set(new Project().styleThemes);
  };

  load = async (isAdmin = false, { projectUID }) => {
    if (!projectUID) return;
    this.setLoaded(false);

    try {
      this.activeId = await this.dbStyleThemes.getActiveID();

      if (isAdmin) {
        this.names = (await this.dbStyleThemes.getAllNames()) || [];

        if (!this.names || !this.activeId) {
          await this.setupEmptyStyledThemesProjects();
          this.load(isAdmin, { projectUID });
          return;
        }
      }

      if (uuidValidate(this.activeId)) {
        const activeStyleTheme = await this.dbStyleThemes.getActiveStyleTheme();
        this.activeStyleTheme = _.merge(_.cloneDeep(AdeptlyStyleThemes.default), activeStyleTheme);
      } else {
        this.activeStyleTheme = _.cloneDeep(AdeptlyStyleThemes[this.activeId]);
      }

      this.loadFonts();

      this.setLoaded(true);
    } catch (e) {
      return null;
    }
  };

  loadFonts = async () => {
    Object.entries(this.activeStyleTheme.fonts).forEach(([fontKey, font]) => {
      CSSVariables.updateFont(fontKey as ThemeFontsKey, font);
    });
  };

  setActiveTheme = async (id: string) => {
    await this.dbStyleThemes.setActiveID(id);
    if (uuidValidate(id)) {
      // customer theme
      const activeStyleTheme = await this.dbStyleThemes.getActiveStyleTheme();
      this.activeStyleTheme = _.merge(_.cloneDeep(AdeptlyStyleThemes.default), activeStyleTheme);
    } else {
      // adeptly theme
      this.activeStyleTheme = _.cloneDeep(AdeptlyStyleThemes[id]);
    }
    this.activeId = id;
  };

  setTheme = (theme: StyleTheme) => {
    this.activeStyleTheme = theme;
    this.loadFonts();
    this.setLoaded(true);
  };

  updateThemeProperty = (path: string | string[], value) => {
    if (this.activeThemeType === 'adeptly') {
      this.rootStore.ui.modal.confirm({
        title: 'Add Theme',
        content: <TextField label="Name" id="theme-input" variant="outlined" />,
        onConfirm: () => {
          const themeName = (document.getElementById('theme-input') as HTMLInputElement).value;
          const themeId = uuid();
          this.dbStyleThemes.addTheme(themeId, themeName, styleTheme);
          this.names[themeId] = themeName;
          this.activeId = themeId;
          this.activeStyleTheme = _.cloneDeep(styleTheme);
          _.set(this.activeStyleTheme, path, value);
          this.updateTheme();
        },
      });
    } else {
      _.set(this.activeStyleTheme, path, value);
      this.updateTheme();
    }
  };

  get projectStore() {
    return this.rootStore.projectStore;
  }

  updateTheme = () => {
    this.projectStore.makeDraft();
    this.dbStyleThemes.updateActiveStyleTheme(this.activeStyleTheme);
  };

  addThemeDialog = () => {
    this.rootStore.ui.modal.confirm({
      title: 'Add Theme',
      content: <TextField label="Name" id="theme-input" variant="outlined" />,
      onConfirm: () => {
        const themeName = (document.getElementById('theme-input') as HTMLInputElement).value;
        const themeId = uuid();
        this.dbStyleThemes.addTheme(themeId, themeName, styleTheme);
        this.names[themeId] = themeName;
        this.activeId = themeId;
        this.activeStyleTheme = _.cloneDeep(styleTheme);
      },
    });
  };

  duplicateThemeDialog = () => {
    this.rootStore.ui.modal.confirm({
      title: 'Duplicate Theme',
      content: <TextField label="Name" id="theme-input" variant="outlined" />,
      onConfirm: () => {
        const themeId = uuid();
        const themeName = (document.getElementById('theme-input') as HTMLInputElement).value;
        this.dbStyleThemes.addTheme(themeId, themeName, this.activeStyleTheme);
        this.names[themeId] = themeName;
        this.activeId = themeId;
      },
    });
  };

  deleteThemeDialog = () => {
    this.rootStore.ui.modal.confirm({
      title: 'Delete Theme',
      onConfirm: () => {
        delete this.names[this.activeId];
        this.dbStyleThemes.removeTheme(this.activeId);
        this.setActiveTheme(AdeptlyStyleThemeNames.default);
      },
    });
  };

  renameThemeDialog = () => {
    this.rootStore.ui.modal.confirm({
      title: 'Rename Theme',
      content: <TextField label="Name" id="theme-input" variant="outlined" defaultValue={this.activeThemeName} />,
      onConfirm: () => {
        const themeId = this.activeId;
        const themeName = (document.getElementById('theme-input') as HTMLInputElement).value;
        this.dbStyleThemes.updateActiveName(themeName);
        this.names[themeId] = themeName;
      },
    });
  };

  importTheme = async (file: File) => {
    if (file && file.type === 'application/json') {
      file
        .text()
        .then(async (text) => {
          // validations on schema and file format
          const payload = await JSON.parse(text);

          const validator = new JsonSchemaValidator(payload, exportedStyleThemeSchema);
          if (!validator.validate()) {
            throw new Error(validator.formattedError);
          }

          return {
            styleTheme: payload.theme,
            themeName: payload.name,
          };
        })
        .then(({ styleTheme, themeName }) => {
          const themeId = uuid();
          this.dbStyleThemes.addTheme(themeId, themeName, styleTheme);
          this.names[themeId] = themeName;
          this.activeId = themeId;
          this.activeStyleTheme = _.cloneDeep(styleTheme);
        })
        .catch((e) => {
          Bugsnag.notify(e);
        });
    }
  };

  exportTheme = () => {
    const exportFileService = new ExportFileService();
    exportFileService.downloadAsJSON(
      {
        name: this.activeThemeName,
        theme: this.activeStyleTheme,
      },
      'theme.json',
    );
  };
}
