import Chapter from 'objects/Chapter';
import Settings from 'objects/Settings';
import { StyleTheme } from 'objects/StyleTheme';

import { AvailableRegions } from 'storage-client';

import PublishChaptersService from './PublishChaptersService';
import PublishSettingsService from './PublishSettingsService';
import PublishStylesService from './PublishStylesService';

interface PublishGameServiceParams {
  projectUID: string;
  locale: string;
  chapters: Chapter[];
  settings: Settings;
  styles: StyleTheme;
  region?: AvailableRegions;
}

export default class PublishGameService {
  locale: string;
  projectUID: string;
  chapters: Chapter[];
  settings: Settings;
  styles: StyleTheme;
  region?: AvailableRegions;
  path: string;

  constructor({ projectUID, locale = 'en-US', region = 'us', chapters, settings, styles }: PublishGameServiceParams) {
    this.projectUID = projectUID;
    this.locale = locale;
    this.region = region;
    this.settings = settings;
    this.styles = styles;
    this.chapters = this.reduceBundle(chapters);
    this.path = this.locale;
  }

  async perform() {
    this.uploadChapters();
    this.uploadSettings();
    this.uploadStyles();
  }

  reduceBundle(chapters: Chapter[]) {
    return chapters.map((chapter) => {
      delete chapter.flowchartConfiguration;

      return { ...chapter };
    });
  }

  private async uploadChapters() {
    const service = new PublishChaptersService({
      projectUID: this.projectUID,
      locale: this.locale,
      chapters: this.chapters,
      region: this.region,
    });
    await service.perform();
  }

  private async uploadSettings() {
    const service = new PublishSettingsService({
      projectUID: this.projectUID,
      locale: this.locale,
      settings: this.settings,
      region: this.region,
    });
    await service.perform();
  }

  private async uploadStyles() {
    const service = new PublishStylesService({
      projectUID: this.projectUID,
      locale: this.locale,
      styles: this.styles,
      region: this.region,
    });
    await service.perform();
  }
}
