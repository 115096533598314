import { createMuiTheme } from '@material-ui/core/styles';

import { componentsSpacing } from './componentsSpacing';
import { spacing } from './spacing';
import { palette } from './palette';
import { typography } from './typography';
import { overrides } from './overrides';

export const defaultTheme = createMuiTheme({
  spacing,
  componentsSpacing,
  palette,
  typography,
  overrides,
});
