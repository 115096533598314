import { generateTimestamp, Timestamp } from 'utils/DateUtils';
import { DBLearners, DBUsers } from '@firebase/dbUtil';

import { Team } from './Team';
import { LearnerStats } from './LearnerStats';
import Settings from './Settings';

export enum LEARNER_STATUS {
  ADMIN = 'admin',
  INVITED = 'invited',
  ACTIVE = 'active',
  REQUESTED = 'requested',
  REJECTED = 'rejected',
}

export class Learner {
  userUID?: string;
  firstName?: string = 'Anon';
  lastName?: string = 'Anon';
  email?: string;
  status: LEARNER_STATUS;
  stats?: LearnerStats;
  lastActive?: Timestamp = generateTimestamp();
  teams?: Team[] | false;
  acceptedTermsTimestamp: Timestamp = null;
  refusedTermsTimestamp: Timestamp = null;

  constructor({
    userUID = null,
    firstName = null,
    lastName = null,
    email,
    status,
    stats = new LearnerStats(),
    teams = [],
    acceptedTermsTimestamp = null,
    refusedTermsTimestamp = null,
  }: Partial<Learner>) {
    Object.assign(this, {
      userUID,
      firstName,
      lastName,
      email,
      status,
      stats,
      teams,
      acceptedTermsTimestamp,
      refusedTermsTimestamp,
    });
  }

  hasAccess(): boolean {
    return (
      this.status === LEARNER_STATUS.ACTIVE ||
      this.status === LEARNER_STATUS.INVITED ||
      this.status === LEARNER_STATUS.ADMIN
    );
  }

  hasRequestedAccess(): boolean {
    return this.status === LEARNER_STATUS.REQUESTED;
  }

  hasRejectedAccess(): boolean {
    return this.status === LEARNER_STATUS.REJECTED;
  }

  hasUserLinked(): boolean {
    return !!this.userUID;
  }

  hasAcceptedNewestTerms(settings: Settings): boolean {
    if (!settings.customerPolicy?.url) {
      return true;
    } else if (!this.acceptedTermsTimestamp) {
      return false;
    } else if (settings?.customerPolicy?.updatedAt > this.acceptedTermsTimestamp) {
      return false;
    }

    return true;
  }

  refuseTerms(): void {
    this.acceptedTermsTimestamp = null;
    this.refusedTermsTimestamp = generateTimestamp();
  }

  acceptTerms(): void {
    this.acceptedTermsTimestamp = generateTimestamp();
    this.refusedTermsTimestamp = null;
  }

  get fullName(): string {
    if (this.firstName && this.lastName && this.firstName !== this.lastName) {
      return `${this.firstName} ${this.lastName}`;
    }

    return this.firstName;
  }

  async setupLearnerUserByEmail(projectUID: string) {
    const user = await new DBUsers().getUserByEmail(this.email);
    const learnerSnapshot = await new DBLearners(projectUID).getByEmail(this.email);

    return {
      user,
      learnerSnapshot,
    };
  }
  async setupLearnerUserByUID(projectUID: string) {
    const user = await new DBUsers().getByUID(this.userUID);
    const learnerSnapshot = await new DBLearners(projectUID).getByUserUID(this.userUID);
    return {
      user,
      learnerSnapshot,
    };
  }
  async setupLearnerUser(projectUID: string): Promise<void> {
    const setup = this.email
      ? await this.setupLearnerUserByEmail(projectUID)
      : await this.setupLearnerUserByUID(projectUID);
    if (!setup.user) return;

    const { user, learnerSnapshot } = setup;
    const { firstName, lastName } = user.data;
    this.firstName = firstName;
    this.lastName = lastName;
    this.userUID = user.key;
    if (learnerSnapshot) {
      await new DBLearners(projectUID).updateItem(learnerSnapshot.key, { userUID: user.key, firstName, lastName });
    }
  }
}
