import { computed, configure, makeObservable, observable } from 'mobx';

import SessionStore from './sessionStore';
import UserStore from './userStore';
import LearnerStore from './learnerStore';
import ChapterStore from './chapterStore';
import SettingsStore from './settingsStore';
import LeaderboardStore from './leaderboardStore';
import TeamStore from './teamStore';
import ProjectStore from './projectStore';
import ThemesStore from './themesStore';
import LanguagesStore from './languagesStore';
import VariablesStore from './variablesStore';
import AdminStore from './adminStore';
import GameStore from './gameStore';
import StyleThemesStore from './styleThemesStore';
import UIStore from './UIStore';
import ActiveUsersStore from './activeUsersStore';
import MembersStore from './membersStore';
import InvitesStore from './invitesStore';

configure({ enforceActions: 'never' });

export interface MobXStores {
  projectUID: string;
  sessionStore: SessionStore;
  userStore: UserStore;
  learnerStore: LearnerStore;
  chapterStore: ChapterStore;
  settingsStore: SettingsStore;
  leaderboardStore: LeaderboardStore;
  teamStore: TeamStore;
  projectStore: ProjectStore;
  themesStore: ThemesStore;
  languagesStore: LanguagesStore;
  variablesStore: VariablesStore;
  adminStore: AdminStore;
  gameStore: GameStore;
  styleThemes: StyleThemesStore;
  ui: UIStore;
  activeUsersStore: ActiveUsersStore;
  membersStore: MembersStore;
  invitesStore: InvitesStore;
}

class RootStore implements MobXStores {
  sessionStore: SessionStore;
  userStore: UserStore;
  learnerStore: LearnerStore;
  chapterStore: ChapterStore;
  settingsStore: SettingsStore;
  leaderboardStore: LeaderboardStore;
  teamStore: TeamStore;
  projectStore: ProjectStore;
  themesStore: ThemesStore;
  languagesStore: LanguagesStore;
  variablesStore: VariablesStore;
  adminStore: AdminStore;
  gameStore: GameStore;
  styleThemes: StyleThemesStore;
  ui: UIStore;
  activeUsersStore: ActiveUsersStore;
  membersStore: MembersStore;
  invitesStore: InvitesStore;

  constructor() {
    makeObservable(this, {
      projectUID: computed,
      sessionStore: observable,
      userStore: observable,
      learnerStore: observable,
      chapterStore: observable,
      settingsStore: observable,
      leaderboardStore: observable,
      teamStore: observable,
      projectStore: observable,
      themesStore: observable,
      languagesStore: observable,
      variablesStore: observable,
      adminStore: observable,
      gameStore: observable,
      styleThemes: observable,
      ui: observable,
      activeUsersStore: observable,
      membersStore: observable,
      invitesStore: observable,
    });

    this.sessionStore = new SessionStore(this);
    this.userStore = new UserStore(this);
    this.learnerStore = new LearnerStore(this);
    this.chapterStore = new ChapterStore(this);
    this.settingsStore = new SettingsStore(this);
    this.leaderboardStore = new LeaderboardStore(this);
    this.teamStore = new TeamStore(this);
    this.projectStore = new ProjectStore(this);
    this.themesStore = new ThemesStore(this);
    this.languagesStore = new LanguagesStore(this);
    this.variablesStore = new VariablesStore(this);
    this.adminStore = new AdminStore(this);
    this.gameStore = new GameStore(this);
    this.styleThemes = new StyleThemesStore(this);
    this.ui = new UIStore(this);
    this.activeUsersStore = new ActiveUsersStore(this);
    this.membersStore = new MembersStore(this);
    this.invitesStore = new InvitesStore(this);
  }

  get projectUID() {
    return this.projectStore.uid;
  }
}

const store = new RootStore();

if (process.env.NODE_ENV === 'development') window['store'] = store;

export default store;

export {
  RootStore,
  GameStore,
  SessionStore,
  UserStore,
  LearnerStore,
  ChapterStore,
  SettingsStore,
  LeaderboardStore,
  TeamStore,
  ThemesStore,
  LanguagesStore,
  VariablesStore,
  AdminStore,
  ProjectStore,
  StyleThemesStore,
  UIStore,
  ActiveUsersStore,
  MembersStore,
  InvitesStore,
};
