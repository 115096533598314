import { UserRoles } from './User';

export type Ability = {
  addMembers: boolean;
  editMembers: boolean;
  removeMembers: boolean;
  seeGame: boolean;
  editGame: boolean;
  seeStyle: boolean;
  editStyle: boolean;
  seeAnalytics: boolean;
  seeLearners: boolean;
  addLearners: boolean;
  editLearners: boolean;
  removeLearners: boolean;
  seeSettings: boolean;
  editSettings: boolean;
  archiveProject: boolean;
  leaveProject: boolean;
  canPublishGame: boolean;
  canAddLanguage: boolean;
};

export const ABILITIES: Record<UserRoles, Ability> = {
  [UserRoles.Owner]: {
    addMembers: true,
    editMembers: true,
    removeMembers: true,
    seeGame: true,
    editGame: true,
    seeStyle: true,
    editStyle: true,
    seeAnalytics: true,
    seeLearners: true,
    addLearners: true,
    editLearners: true,
    removeLearners: true,
    seeSettings: true,
    editSettings: true,
    archiveProject: true,
    leaveProject: false,
    canPublishGame: true,
    canAddLanguage: true,
  },
  [UserRoles.Editor]: {
    addMembers: false,
    editMembers: false,
    removeMembers: false,
    seeGame: true,
    editGame: true,
    seeStyle: true,
    editStyle: true,
    seeAnalytics: true,
    seeLearners: true,
    addLearners: true,
    editLearners: true,
    removeLearners: true,
    seeSettings: true,
    editSettings: true,
    archiveProject: false,
    leaveProject: true,
    canPublishGame: true,
    canAddLanguage: true,
  },
  [UserRoles.Viewer]: {
    addMembers: false,
    editMembers: false,
    removeMembers: false,
    seeGame: true,
    editGame: false,
    seeStyle: false,
    editStyle: false,
    seeAnalytics: false,
    seeLearners: false,
    addLearners: false,
    editLearners: false,
    removeLearners: false,
    seeSettings: false,
    editSettings: false,
    archiveProject: false,
    leaveProject: true,
    canPublishGame: false,
    canAddLanguage: false,
  },
};
