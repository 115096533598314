import { db } from '../firebaseUtil';
import { analytics } from '../analyticsUtil';
import Project from '../../objects/Project';
import { DBList } from './DBStructures';
import { DBUsers } from './DBUsers';

const projectsRef = db.ref('projects');

export class DBProjects extends DBList<Project> {
  constructor() {
    super();
    this.ref = projectsRef;
  }

  static checkIfProjectExists = async (projectUID: string) => {
    const snap = await projectsRef.child(projectUID).child('slug').once('value');
    return snap.exists();
  };

  static addProject = async (projectUID: string, project: Project, callback?) => {
    const projectsRef = new DBProjects().ref;
    const usersRef = new DBUsers().ref;

    await projectsRef.child(projectUID).set(project);
    await usersRef.child(project.adminUID).child('projects').child(projectUID).set(project.name);

    await analytics.projectCreated();
    callback && callback();
  };

  static deleteProject = (projectUID: string, userUID: string, callback?) => {
    db.ref('users').child(userUID).child('projects').child(projectUID).remove();
    projectsRef.child(projectUID).remove(callback);
  };
}
