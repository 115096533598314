import { DBLeaderboard } from '@firebase/dbUtil';
import { observable, action, makeObservable } from 'mobx';

import LeaderboardObject from 'objects/LeaderboardObject';

import { RootStore } from './index';

class LeaderboardStore {
  rootStore: RootStore;
  leaderboard: { [k: string]: LeaderboardObject } = {};
  highscore = 0;
  worstscore = null;
  loaded = false;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      loaded: observable,
      leaderboard: observable,
      highscore: observable,
      worstscore: observable,
      setLoaded: action,
      setLeaderboard: action,
      setHighscore: action,
      reset: action,
    });

    this.rootStore = rootStore;
  }

  setLoaded = (loaded) => {
    this.loaded = loaded;
  };

  setLeaderboard = (leaderboard, highscore, worstscore) => {
    this.leaderboard = leaderboard;
    this.highscore = highscore;
    this.worstscore = worstscore;
    this.loaded = true;
  };

  setHighscore = (highscore) => {
    this.highscore = highscore;
  };

  setWorstscore = (worstscore) => {
    this.worstscore = worstscore;
  };

  reset = () => {
    if (this.rootStore.learnerStore.isAdmin) {
      const ref = new DBLeaderboard(this.rootStore.projectStore.uid).ref;
      ref.remove();
      this.highscore = 0;
      this.worstscore = null;
      this.leaderboard = {};
    }
  };
}

export default LeaderboardStore;
