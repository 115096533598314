import { makeStyles, createStyles, withStyles, Theme } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

export const BorderLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 10,
      borderRadius: 5,
      width: '100%',
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#1a90ff',
    },
  }),
)(LinearProgress);

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      overflow: 'hidden',
      height: '100%',
      width: '100%',
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#E5E5E5',
      backgroundSize: 'cover',
      opacity: '1.0',
      transition: 'background 1.5s linear',
    },
    progress: {
      display: 'flex',
      minWidth: 350,
      width: 350,
      backgroundColor: '#FFFFFF',
      borderRadius: 16,
      flexDirection: 'column',
      padding: 20,
      boxShadow: '0px 1px 50px rgba(0, 0, 0, 0.2)',
      alignItems: 'center',
      transition: 'height 1.5s linear',
    },
    logo: {
      maxWidth: 200,
      maxHeight: 100,
      padding: '20px 0%',
      marginBottom: 20,
    },
  }),
);
