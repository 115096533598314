import React from 'react';
import { DndProvider as ReactDndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';

const DndProvider = ({ options = {}, children }) => {
  return (
    <ReactDndProvider backend={Backend} options={options} debugMode>
      {children}
    </ReactDndProvider>
  );
};

export default DndProvider;
