import { Variables } from 'objects/Variable';
import { firebase } from '@firebase/firebaseUtil';
import { DBMap } from './DBStructures';
import { DBProject } from './DBProject';

export class DBVariables extends DBMap<Variables> {
  public projectUID: string;
  private projectRef: firebase.database.Reference;

  constructor(projectUID: string) {
    super();
    this.projectUID = projectUID;
    this.projectRef = new DBProject(projectUID).ref;
    this.ref = this.projectRef.child('variables');
  }
}
