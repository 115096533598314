import { DBMap } from './DBStructures';
import { DBProject } from './DBProject';
import { firebase } from '@firebase/firebaseUtil';
import Analytics from '../../objects/Analytics';

export class DBAnalytics extends DBMap<Analytics> {
  public projectUID: string;
  public teamsRef: firebase.database.Reference;
  private projectRef: firebase.database.Reference;

  constructor(projectUID: string) {
    super();
    this.projectRef = new DBProject(projectUID).ref;
    this.projectUID = projectUID;
    this.ref = this.projectRef.child('analytics');
    this.teamsRef = this.projectRef.child('teamAnalytics');
  }

  incrementProperty = <K extends keyof Analytics>(propertyKey: K, increment: number, callback?) =>
    this.ref.child(propertyKey).transaction(function (property) {
      return (property || 0) + increment;
    }, callback);

  incrementTeamProperty = <K extends keyof Analytics>(teamUID: string, propertyKey: K, increment: number, callback?) =>
    this.projectRef
      .child('teamAnalytics')
      .child(teamUID)
      .child(propertyKey)
      .transaction(function (property) {
        return (property || 0) + increment;
      }, callback);

  pushProperty = <K extends keyof Analytics>(propertyKey: K, value: number, callback?) =>
    this.ref.child(propertyKey).push(value, callback);

  pushTeamProperty = <K extends keyof Analytics>(teamUID: string, propertyKey: K, value: number, callback?) =>
    this.projectRef.child('teamAnalytics').child(teamUID).child(propertyKey).push(value, callback);
}
