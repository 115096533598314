import React, { FC } from 'react';
import { Button, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';

import { Dialog, DialogTitle } from 'components/Dialogs/Dialog';

export type AlertDialogProps = {
  open: boolean;
  handleClose: () => void;
  title: string;
  description: string;
  handleSecondaryBtn: () => void;
  secondaryBtnText: string;
  handlePrimaryBtn: () => void;
  primaryBtnText: string;
};

export const AlertDialog: FC<AlertDialogProps> = ({
  open,
  handleClose,
  title,
  description,
  handleSecondaryBtn,
  secondaryBtnText,
  handlePrimaryBtn,
  primaryBtnText,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSecondaryBtn} color="primary">
          {secondaryBtnText}
        </Button>
        <Button onClick={handlePrimaryBtn} color="primary" autoFocus>
          {primaryBtnText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
