import { useEffect } from 'react';
import { useSnackbar } from 'notistack';

const POLLING_DELAY_SECONDS = 2;
export function useNetwork() {
  const { enqueueSnackbar } = useSnackbar();

  const updateNetwork = () => {
    if (window.navigator.onLine) {
      enqueueSnackbar('Connection restored', {
        variant: 'success',
        preventDuplicate: true,
        autoHideDuration: 4000,
      });
      clearInterval(window.networkPoll);
      return;
    }

    window.networkPoll = setInterval(() => {
      fetch('//adeptly.ai', {
        mode: 'no-cors',
      })
        .then(() => {
          enqueueSnackbar('Connection restored', {
            variant: 'success',
            preventDuplicate: true,
            autoHideDuration: 2000,
          });
          clearInterval(window.networkPoll);
        })
        .catch(() => {
          enqueueSnackbar('Lost connection. Reconnecting...', {
            variant: 'error',
            persist: true,
            preventDuplicate: true,
          });
        });
    }, POLLING_DELAY_SECONDS * 1000);
  };

  useEffect(() => {
    window.addEventListener('offline', updateNetwork);
    window.addEventListener('online', updateNetwork);
    return () => {
      window.removeEventListener('offline', updateNetwork);
      window.removeEventListener('online', updateNetwork);
    };
  }, []);
}
