import { FC, useState } from 'react';
import ReactCountUp, { CountUpProps as ReactCountUpProps } from 'react-countup';

export type CountUpProps = {
  number: number;
} & Partial<ReactCountUpProps>;

export const CountUp: FC<CountUpProps> = ({ number, ...otherProps }) => {
  const [value, setValue] = useState(0);

  return <ReactCountUp start={value} end={number} duration={2} onEnd={() => setValue(number)} {...otherProps} />;
};
