import { createContext, FC, useCallback, useContext, useMemo } from 'react';

import Chapter from 'objects/Chapter';
import Decision from 'objects/Decision';
import Step from 'objects/Step';

import { useBonusTimer } from './useBonusTimer';
import { useState } from 'react';
import { GameStates } from 'objects/Game/GameStates';
import { StepStylePosition } from 'objects/StepStyle';
import { GameBackgroundConfiguration } from 'components/GameBackground';

export interface GameContextValues {
  readonly characterReady: boolean;
  readonly gameState: GameStates;

  readonly images?: Record<StepStylePosition, GameBackgroundConfiguration>;
  readonly setImage?: (imageKey: StepStylePosition, image: GameBackgroundConfiguration) => void;

  readonly videoBackground?: GameBackgroundConfiguration;
  readonly setVideoBackground?: (video: GameBackgroundConfiguration) => void;

  readonly playerChapters: Chapter[];

  readonly currentStepGroupIndex: number;
  readonly currentStepID: string;
  readonly prevStepID: string;

  readonly decisionSelected: any;
  readonly newChapterBegun: any;
  readonly restartGame: any;
  readonly showSummary: any;
  readonly showScore: any;

  readonly process?: Chapter;
  readonly step?: Step;
  readonly lastDecisionTaken?: Decision;

  readonly onDecisionClick?: any;
}

const initialGameContext = {
  characterReady: false,
  gameState: 'linear' as GameStates,
  images: {
    foreground: null,
    middle: null,
    background: null,
  },
  setImage: (imageKey: StepStylePosition, image: GameBackgroundConfiguration) => null,
  videoBackground: null,
  setVideoBackground: (video: GameBackgroundConfiguration) => null,
  playerChapters: [],
  currentStepGroupIndex: 0,
  currentStepID: '',
  prevStepID: null,
  process: null,
  step: null,
  lastDecisionTaken: null,
  decisionSelected: () => null,
  newChapterBegun: () => null,
  restartGame: () => null,
  showSummary: () => null,
  showScore: () => null,
  onDecisionClick: () => null,
};

const GameContext = createContext<GameContextValues>(initialGameContext);

export const useGameContext = () => useContext(GameContext);

export const GameContextProvider: FC<GameContextValues> = ({ children, ...values }) => {
  const [lastDecisionTaken, setLastDecisionTaken] = useState(null);
  const [videoBackground, setVideo] = useState<GameBackgroundConfiguration>(null);
  const [images, setImages] = useState<GameContextValues['images']>({
    foreground: null,
    middle: null,
    background: null,
  });

  const setVideoBackground = (url) => {
    setVideo(url);
  };

  const setImage = (imageKey: StepStylePosition, image: GameBackgroundConfiguration) =>
    setImages((prev) => ({ ...prev, [imageKey]: image }));

  const { getSpeedBonus } = useBonusTimer();

  const process = useMemo(
    () => values.playerChapters[values.currentStepGroupIndex],
    [values.playerChapters, values.currentStepGroupIndex],
  );

  const step = useMemo(() => process.storys[values.currentStepID], [process, values.currentStepID]);

  const onDecisionClick = useCallback(
    (decision: Partial<Decision>) => () => {
      const speedBonus = getSpeedBonus();

      setLastDecisionTaken(decision);

      values.decisionSelected(decision, speedBonus);
    },
    [step],
  );

  return (
    <GameContext.Provider
      value={{
        ...values,
        images,
        setImage,
        videoBackground,
        setVideoBackground,
        onDecisionClick,
        process,
        step,
        lastDecisionTaken,
      }}
    >
      {children}
    </GameContext.Provider>
  );
};
