import React, { FC } from 'react';
import { useRemoteConfigContext } from 'providers/RemoteConfigProvider';

import { MaintenancePage } from './MaintenancePage';

export const MaintenanceProvider: FC<any> = ({ children }) => {
  const { maintenance } = useRemoteConfigContext();
  if (maintenance.app) {
    return <MaintenancePage time={maintenance.app} />;
  }

  return children;
};
