import { createOption } from './utils';

export const font = {
  family: {
    breeSerif: createOption('Bree Serif', 'Bree Serif'),
    googleSans: createOption('Google Sans', 'Google Sans'),
    lato: createOption('Lato', 'Lato'),
    montserrat: createOption('Montserrat', 'Montserrat'),
    openSans: createOption('Open Sans', 'Open Sans'),
    patuaOne: createOption('Patua One', 'Patua One'),
    roboto: createOption('Roboto', 'Roboto'),
    robotoSlab: createOption('Roboto Slab', 'Roboto Slab'),
    varelaRound: createOption('Varela Round', 'Varela Round'),
  },

  size: {
    small: createOption('small', 'small'),
    regular: createOption('regular', 'regular'),
    big: createOption('big', 'big'),
    veryBig: createOption('very big', 'veryBig'),
    giant: createOption('giant', 'giant'),
  },

  weight: {
    light: createOption('light', 300),
    regular: createOption('regular', 400),
    bold: createOption('bold', 700),
  },
};
