import { useMemo, useState, FC, useRef, ReactElement } from 'react';
import clsx from 'clsx';
import { Element } from 'domhandler/lib/node';
import { createPortal } from 'react-dom';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import ReactQuill, { Quill } from 'react-quill';
import QuillPasteSmart from 'quill-paste-smart';
import quillEmoji from 'quill-emoji';
import 'react-quill/dist/quill.snow.css'; // ES6
import 'sass/quill-emoji.css';
import 'sass/quill.css';

import { Tooltip, Slide } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import FavoriteIcon from '@material-ui/icons/Favorite';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import TimerIcon from '@material-ui/icons/Timer';

import { useStores } from 'stores/useStores';

import Helpers from '../../tools/Helpers';
import { useQuillStyles } from './Quill.styles';
import { EffectText } from 'components/EffectText';
import { Pause } from 'windups';
import ReactHtmlParser, { HTMLReactParserOptions } from 'utils/htmlParser';
import { useGameTheme } from 'features/Game/useGameTheme';

function insertCustomOption(option) {
  const cursorPosition = this.quill.getSelection()?.index;
  const text = `{${option}}`;
  const nextCursorPosition = cursorPosition + text.length;
  this.quill.insertText(cursorPosition, text);
  this.quill.setSelection(nextCursorPosition);
}

function insertName() {
  insertCustomOption.bind(this)('name');
}
function insertStat() {
  insertCustomOption.bind(this)('stat');
}
function insertScore() {
  insertCustomOption.bind(this)('score');
}
function insertProcessName() {
  insertCustomOption.bind(this)('processName');
}
function insertTimer() {
  insertCustomOption.bind(this)('d=100');
}

const CustomButtonUser = () => <PersonIcon />;
const CustomButtonStat = () => <FavoriteIcon />;
const CustomButtonScore = () => <EmojiEventsIcon />;
const CustomButtonProcessName = () => <MenuBookIcon />;
const CustomButtonTimer = () => <TimerIcon />;

export const CustomToolbar: FC<any> = observer(
  ({ id, showUserOptions = true, showProcessOptions = true, hidden = false }) => {
    const { settingsStore, styleThemes } = useStores();
    const customThemeStyles = settingsStore?.settings?.customThemeStyles;
    const {
      settings: { stat, customStat },
    } = settingsStore;
    const { activeStyleTheme } = styleThemes;
    const currentStat = customStat || stat;
    const textRef = useRef();
    const classes = useQuillStyles({ fonts: activeStyleTheme.fonts, link: activeStyleTheme.link });

    if (hidden) return <div>‎‎&nbsp;</div>;

    return (
      <div id={`toolbar-${id}`} className={classes.root}>
        <Tooltip title="Text Size" placement="top">
          <span style={{ display: 'inline-block', float: 'left' }}>
            <select className="ql-header" defaultValue="body" onChange={(e) => e.persist()} ref={textRef}>
              <option value="body">Body</option>
              <option value="1" />
              <option value="2" />
            </select>
          </span>
        </Tooltip>

        <Tooltip title="Bold" placement="top">
          <button className="ql-bold" />
        </Tooltip>

        <Tooltip title="Italic" placement="top">
          <button className="ql-italic" />
        </Tooltip>

        <Tooltip title="Text colour" placement="top">
          <span style={{ display: 'inline-block', float: 'left' }}>
            <select defaultValue={''} className="ql-color">
              <option
                value={`rgba(
            ${customThemeStyles?.primaryColor?.r},
            ${customThemeStyles?.primaryColor?.g},
            ${customThemeStyles?.primaryColor?.b},
            ${customThemeStyles?.primaryColor?.a})`}
              />
              <option
                value={`rgba(
            ${customThemeStyles?.backgroundColor?.r},
            ${customThemeStyles?.backgroundColor?.g},
            ${customThemeStyles?.backgroundColor?.b},
            ${customThemeStyles?.backgroundColor?.a})`}
              />
              <option
                value={`rgba(
            ${customThemeStyles?.answerBackgroundColor?.r},
            ${customThemeStyles?.answerBackgroundColor?.g},
            ${customThemeStyles?.answerBackgroundColor?.b},
            ${customThemeStyles?.answerBackgroundColor?.a})`}
              />
              <option value="#6a6c6a" />
              <option value="#12C4C4" />
              <option value="#6F69B4" />
              <option value="#5AA5EF" />
              <option value="#93CF47" />
              <option value="#E66882" />
              <option value="green" />
              <option value="blue" />
              <option value="orange" />
              <option value="violet" />
              <option value="#FFFFFF" />
            </select>
          </span>
        </Tooltip>

        <Tooltip title="Subscript" placement="top">
          <button className="ql-script" value="sub" />
        </Tooltip>

        <Tooltip title="Superscript" placement="top">
          <button className="ql-script" value="super" />
        </Tooltip>

        <Tooltip title="Add delay" placement="top">
          <button className="ql-insertTimer">
            <CustomButtonTimer />
          </button>
        </Tooltip>

        {showUserOptions && (
          <>
            <Tooltip title="Player name" placement="top">
              <button className="ql-insertName">
                <CustomButtonUser />
              </button>
            </Tooltip>

            <Tooltip title={currentStat} placement="top">
              <button className="ql-insertStat">
                <CustomButtonStat />
              </button>
            </Tooltip>

            <Tooltip title="Player score" placement="top">
              <button className="ql-insertScore">
                <CustomButtonScore />
              </button>
            </Tooltip>
          </>
        )}
        {showProcessOptions && (
          <Tooltip title="Current process name" placement="top">
            <button className="ql-insertProcessName">
              <CustomButtonProcessName />
            </button>
          </Tooltip>
        )}
        <Tooltip title="Emoji" placement="top">
          <button
            className="ql-emoji"
            onClick={() => {
              const emojiPalette = document.getElementById('emoji-palette');
              try {
                emojiPalette.addEventListener('wheel', (e) => {
                  // Blocks the flowchart to zoom in
                  e.stopPropagation();
                });
              } catch (e) {
                console.error(e);
              }
            }}
          />
        </Tooltip>
        <Tooltip title="Link" placement="top">
          <button className="ql-link" />
        </Tooltip>
        <Tooltip title="Remove formatting" placement="top">
          <button className="ql-clean" />
        </Tooltip>
      </div>
    );
  },
);
const { EmojiBlot, ShortNameEmoji, ToolbarEmoji } = quillEmoji;

const FORMATS = [
  'font',
  'header',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'code-block',
  'color',
  'background',
  'list',
  'indent',
  'align',
  'link',
  'image',
  'clean',
  'emoji',
  'script',
];

function registerQuill(callbacks?) {
  return Quill.register(
    {
      'formats/emoji': EmojiBlot,
      'modules/emoji-shortname': ShortNameEmoji,
      'modules/emoji-toolbar': ToolbarEmoji,
      'modules/clipboard': QuillPasteSmart,
      'modules/maxlength': (quill, options) => {
        quill.on('text-change', function () {
          callbacks?.maxlength?.onSuccess();
          if (quill.getText().length > options.value) {
            quill.history.undo();
            callbacks?.maxlength?.onFail();
            return;
          } else {
            callbacks?.maxlength?.onSuccess();
            return;
          }
        });
      },
    },
    true,
  );
}

function buildModules({ id, maxLength, onHitEnter }) {
  return {
    toolbar: {
      container: `#toolbar-${id}`,
      handlers: {
        insertName,
        insertStat,
        insertScore,
        insertProcessName,
        insertTimer,
      },
    },
    'emoji-toolbar': true,
    'emoji-shortname': true,
    maxlength: { value: maxLength },
    history: { delay: 100, userOnly: true },
    clipboard: {
      matchVisual: true,
    },
    keyboard: {
      bindings: {
        enter: {
          key: 'Enter',
          handler: function () {
            if (onHitEnter) {
              return onHitEnter();
            }

            return true;
          },
        },
        // linebreak: {
        //   key: 13,
        //   shiftKey: true,
        //   handler: function (range, _context) {
        //     this.quill.clipboard.dangerouslyPasteHTML(range.index, '<br /><br />');
        //     return true;
        //   },
        // },
      },
    },
  };
}

export const QuillComponent: FC<any> = ({
  id,
  value,
  customThemeStyles,
  onChange,
  showUserOptions,
  showProcessOptions,
  maxLength,
  onHitEnter,
  quillRef = null,
  hasToolbar = true,
  portalId,
  ...rest
}) => {
  const portalIdRef = document.getElementById(portalId);
  const [maxLengthReached, setMaxLengthReached] = useState(false);
  const toggleToolbarState = () => buildModules({ id, maxLength, onHitEnter });
  const modules = useMemo(() => toggleToolbarState(), []);

  registerQuill({
    maxlength: {
      onSuccess: () => setMaxLengthReached(false),
      onFail: () => setMaxLengthReached(true),
    },
  });

  return (
    <div className="text-editor">
      {portalIdRef ? (
        createPortal(
          <CustomToolbar id={id} showUserOptions={showUserOptions} showProcessOptions={showProcessOptions} />,
          portalIdRef,
        )
      ) : (
        <CustomToolbar id={id} showUserOptions={showUserOptions} showProcessOptions={showProcessOptions} />
      )}
      <Tooltip
        title={maxLength ? `Max ${maxLength} characters` : ''}
        placement="top-end"
        open={maxLengthReached}
        arrow
        disableFocusListener
      >
        <ReactQuill
          id={id}
          ref={quillRef}
          value={value}
          onFocus={() => {
            portalIdRef && (portalIdRef['className'] = 'active');
          }}
          onBlur={() => {
            portalIdRef && (portalIdRef['className'] = '');
          }}
          onChange={onChange}
          modules={modules}
          formats={FORMATS}
          {...rest}
        />
      </Tooltip>
    </div>
  );
};

const DecodedQuillText: FC<any> = ({ text, name, stat, score, processName }) => {
  if (text) {
    return ReactHtmlParser(
      text
        .replace(/{processName}/g, `<b class="primaryColor">${processName}</b>`)
        .replace(/{name}/g, `<b class="primaryColor">${name}</b>`)
        .replace(/{stat}/g, `<b class="primaryColor">${Helpers.formattingFn(stat)}</b>`)
        .replace(/{score}/g, `<b class="primaryColor">${Helpers.formattingFn(score)}</b>`),
    ) as ReactElement<any, any>;
  }

  return null;
};

export const InjectedDecodedQuillText: FC<any> = compose(
  inject((store: any) => ({
    name: store.learnerStore.learner.firstName,
    stat: store.learnerStore.learner.stats.stat,
    score: store.learnerStore.learner.stats.score,
  })),
  observer,
)(DecodedQuillText);

export type DecodedQuillWindupTextProps = {
  text: string;
  processName: string;
  style?: any;
  className?: any;
  onFinished?: () => void;
  skipped?: boolean;
  pace?: number;
};

let pauseKey = 0;

const options: HTMLReactParserOptions = {
  replace: (domNode: Element) => {
    if (domNode.data) {
      const timerRegex = /{d=\d+}/g;
      const pauseValues = domNode.data.match(timerRegex)?.map((v) => Number(v.replace(/[{d=]|}/g, '')));
      const newData = domNode.data
        .split(timerRegex)
        .reduce((prev, cur) => [...prev, <Pause ms={pauseValues} key={`pause-${pauseKey++}`} />, cur], []);
      domNode.data = newData;
    }
  },
};

const EMPTY_HTML_TEXT = '<p><br></p>';
const DEFAULT_DELAY = '{d=100}';

export const DecodedQuillWindupText: FC<DecodedQuillWindupTextProps> = ({
  text,
  style,
  processName,
  className,
  ...otherProps
}) => {
  const { learnerStore } = useStores();
  const { classes } = useGameTheme();

  const parsedHtml = useMemo(() => {
    if (!text) return ReactHtmlParser(DEFAULT_DELAY, options);
    if (text === '') return ReactHtmlParser(DEFAULT_DELAY, options);
    if (text === '<p> </p>') return ReactHtmlParser(DEFAULT_DELAY, options);
    if (text === EMPTY_HTML_TEXT) return ReactHtmlParser(DEFAULT_DELAY, options);

    const replacedText = text
      .replace(/{processName}/g, `<b class="primaryColor">${processName}</b>`)
      .replace(/{name}/g, `<b class="primaryColor">${learnerStore.learner.firstName}</b>`)
      .replace(/{stat}/g, `<b class="primaryColor">${Helpers.formattingFn(learnerStore.learner.stats.stat)}</b>`)
      .replace(/{score}/g, `<b class="primaryColor">${Helpers.formattingFn(learnerStore.learner.stats.score)}</b>`)
      .replace('contenteditable="false"', '');

    if (replacedText) return ReactHtmlParser(replacedText, options);

    return DEFAULT_DELAY;
  }, [text]);

  return (
    <Slide direction="up" in={true}>
      <div className={clsx(classes.step, 'game-step')} style={style} suppressContentEditableWarning>
        <div className={className}>
          <EffectText {...otherProps}>{parsedHtml}</EffectText>
        </div>
      </div>
    </Slide>
  );
};
