import Chapter from 'objects/Chapter';
import RootStore from 'stores';
import FileUploadService from '../FileUploadService';
import { AvailableRegions } from 'storage-client';

interface PublishChaptersServiceParams {
  projectUID: string;
  locale: string;
  chapters: Chapter[];
  region?: AvailableRegions;
}

export default class PublishChaptersService {
  locale: string;
  projectUID: string;
  chapters: Chapter[];
  region?: AvailableRegions;
  path: string;

  constructor({ projectUID, locale = 'en-US', region = 'us', chapters = [] }: PublishChaptersServiceParams) {
    this.projectUID = projectUID;
    this.locale = locale;
    this.region = region;
    this.chapters = this.reduceBundle(chapters);
    this.path = this.locale;
  }

  async perform() {
    const service = new FileUploadService({
      type: 'bundles',
      projectUID: this.projectUID,
      region: this.region,
    });

    service.addJSON(this.chapters, this.path);
    RootStore.projectStore.resetLocales();
    return await service.upload();
  }

  reduceBundle(chapters: Chapter[]) {
    return chapters.map((chapter) => {
      delete chapter.flowchartConfiguration;

      return { ...chapter };
    });
  }
}
