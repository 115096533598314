import { observable, action, makeObservable, computed } from 'mobx';
import _ from 'lodash';

import { DBSettings, DBServerProviders } from '@firebase/dbUtil';
import Settings, { LRSCredentials } from 'objects/Settings';
import { Provider } from 'objects/Providers';
import { RootStore } from 'stores';

class SettingsStore {
  rootStore: RootStore;
  settings = new Settings();
  serverProviders: { [key: string]: Provider } = {};
  loaded = false;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      loaded: observable,
      settings: observable,
      serverProviders: observable,
      setLoaded: action,
      setServerProviders: action,
      setSettings: action,
      setSettingsProperty: action,
      setSettingsTextProperty: action,
      setSettingsImpactProperty: action,
      setSettingsCustomThemeStyleProperty: action,
      updateLanguages: action,
      setFallbackLanguage: action,
      setDefaultLanguage: action,
      setLRSProperty: action,
      setLRSPPassword: action,
      lrsCredentials: computed,
      lrsPassword: computed,
    });

    this.rootStore = rootStore;
    const providers = new DBServerProviders().list();
    this.setServerProviders(providers);
  }

  get projectStore() {
    return this.rootStore.projectStore;
  }

  setLoaded = (loaded) => {
    this.loaded = loaded;
  };

  setServerProviders = (providers) => {
    this.serverProviders = providers;
  };

  setSettings = (settings) => {
    this.settings = _.merge(_.cloneDeep(new Settings()), settings);
    this.settings.providers = settings.providers;
    this.loaded = true;
  };

  setSettingsProperty = (propertyKey, propertyValue, makeDraft = true) => {
    if (makeDraft) this.projectStore.makeDraft();

    this.settings[propertyKey] = propertyValue;
    new DBSettings(this.rootStore.projectUID).setProperty(propertyKey, propertyValue);
  };

  setLRSProperty = (propertyKey, propertyValue, makeDraft = true) => {
    if (makeDraft) this.projectStore.makeDraft();

    this.settings.lrs[propertyKey] = propertyValue;
    new DBSettings(this.rootStore.projectUID).setLRS(propertyKey, propertyValue);
  };

  setLRSPPassword = (value) => {
    try {
      this.projectStore.makeDraft();
      let password = value;

      if (password.length > 0) {
        password = btoa(value);
      }

      this.setLRSProperty('password', password);
    } catch {
      return;
    }
  };

  get hasValidLRSConfig(): boolean {
    try {
      const { endpoint, username, password } = this.settings.lrs;
      return endpoint.length > 0 || username.length > 0 || password.length > 0;
    } catch {
      return false;
    }
  }

  get lrsCredentials(): LRSCredentials {
    try {
      return {
        username: this.settings.lrs.username,
        endpoint: this.settings.lrs.endpoint,
        password: this.lrsPassword,
      };
    } catch {
      return {
        username: '',
        endpoint: '',
        password: '',
      };
    }
  }

  get lrsPassword(): string {
    try {
      if (!this.settings.lrs?.password || this.settings.lrs?.password?.length === 0) return '';
      return atob(this.settings.lrs.password);
    } catch (e) {
      return '';
    }
  }

  setSettingsTextProperty = (propertyKey, propertyValue, makeDraft = true) => {
    if (makeDraft) this.projectStore.makeDraft();

    this.settings.text[propertyKey] = propertyValue;
    new DBSettings(this.rootStore.projectUID).setText(propertyKey, propertyValue);
  };

  setSettingsCustomThemeStyleProperty = (propertyKey, propertyValue, makeDraft = true) => {
    if (makeDraft) this.projectStore.makeDraft();

    this.settings.customThemeStyles[propertyKey] = propertyValue;
    new DBSettings(this.rootStore.projectUID).setCustomThemeStyles(propertyKey, propertyValue);
  };

  setSettingsImpactProperty = (propertyKey, propertyValue, makeDraft = true) => {
    if (makeDraft) this.projectStore.makeDraft();

    this.settings.impactStatValues[propertyKey] = propertyValue;
    new DBSettings(this.rootStore.projectUID).setStatProperty(propertyKey, propertyValue);
  };

  updateLanguages = (value) => {
    this.setSettingsProperty('languages', { ...this.settings.languages, ...value }, false);
  };

  setFallbackLanguage = (value) => {
    this.setSettingsProperty('languages', { ...this.settings.languages, fallback: value });
  };

  setDefaultLanguage = (value) => {
    const availableLocales = this.getResetedLocaleValues(value);
    this.updateLanguages({ default: value, availableLocales });

    this.setSettingsProperty('languages', { ...this.settings.languages, default: value });
  };

  getResetedLocaleValues = (key) => {
    const clone = { ...this.settings.languages.availableLocales };
    clone[key] = {
      error: false,
      filename: '-',
      uploadedAt: null,
    };

    return clone;
  };
}

export default SettingsStore;
