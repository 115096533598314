const colorSchema = {
  type: 'object',
  properties: {
    r: { type: ['string', 'number'] },
    g: { type: ['string', 'number'] },
    b: { type: ['string', 'number'] },
    a: { type: ['string', 'number'] },
  },
};

const fontSchema = {
  type: 'object',
  properties: {
    family: { type: 'string' },
    size: { type: 'string' },
    color: colorSchema,
  },
};

const boxShadowSchema = {
  type: 'object',
  properties: {
    blur: { type: 'string' },
    size: { type: 'string' },
    color: colorSchema,
  },
};

const borderSchema = {
  type: 'object',
  properties: {
    radius: { type: 'string' },
    width: { type: 'string' },
    color: colorSchema,
  },
};

const styleThemeSchema = {
  type: 'object',
  properties: {
    game: {
      type: 'object',
      properties: {
        width: { type: 'string' },
        backgroundColor: colorSchema,
        flow: { type: 'string' },
      },
      additionalProperties: false,
    },

    score: {
      type: 'object',
      properties: {
        backgroundColor: colorSchema,
        fontColor: colorSchema,
      },
    },

    fonts: {
      type: 'object',
      properties: {
        body: fontSchema,
        heading1: fontSchema,
        heading2: fontSchema,
        button: fontSchema,
      },
    },

    step: {
      type: 'object',
      properties: {
        angle: { type: 'string' },
        backgroundColor: colorSchema,

        boxShadow: boxShadowSchema,

        border: borderSchema,
      },
    },

    decisionTaken: {
      type: 'object',
      properties: {
        angle: { type: 'string' },
        opacity: { type: 'number' },
      },
    },

    decision: {
      type: 'object',
      properties: {
        angle: { type: 'string' },
        backgroundColor: colorSchema,

        boxShadow: boxShadowSchema,

        border: borderSchema,
      },
    },

    image: {
      type: 'object',
      properties: {
        angle: { type: 'string' },

        border: borderSchema,
      },
    },

    video: {
      type: 'object',
      properties: {
        border: borderSchema,

        playerControls: { type: 'boolean' },
        autoplay: { type: 'boolean' },
      },
    },

    link: {
      type: 'object',
      properties: {
        fontColor: colorSchema,
        hoverFontColor: colorSchema,
      },
    },
  },
  additionalProperties: false,
};

export const exportedStyleThemeSchema = {
  type: 'object',
  required: ['name', 'theme'],
  properties: {
    name: { type: 'string' },
    theme: styleThemeSchema,
  },
  additionalProperties: false,
};
