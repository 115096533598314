import PrivateHttpRequester from 'utils/HttpRequester';

export type AvailableRegions = 'us' | 'uk' | 'ap';

export enum Regions {
  us = 'United States',
  uk = 'Europe',
  ap = 'Asia & Ocenia',
}

export interface UploadFileResponse {
  region: string;
  filename: string;
  projectUID: string;
  url: string;
}

export const REACT_APP_STORAGE_SERVICE_URL = process.env.REACT_APP_STORAGE_SERVICE_URL;

export const HttpRequester = new PrivateHttpRequester({
  baseURL: REACT_APP_STORAGE_SERVICE_URL,
  timeout: 60_000,
  headers: {},
});

export const STORAGE_BUCKETS = {
  staging: {
    us: '//adeptly-sandbox.s3.us-west-2.amazonaws.com',
    uk: '//adeptly-sandbox.s3.us-west-2.amazonaws.com',
    ap: '//adeptly-sandbox.s3.us-west-2.amazonaws.com',
  },
  production: {
    us: '//us-adeptly.s3.us-west-2.amazonaws.com',
    uk: '//adeptly-uk.s3.eu-west-2.amazonaws.com',
    ap: '//adeptly-ap.s3.ap-southeast-2.amazonaws.com',
  },
};

export const getStorageBucket = (region: AvailableRegions, env = 'staging') => {
  return STORAGE_BUCKETS[env][region];
};
