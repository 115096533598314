import { Icon } from 'react-icons-kit';
import { ic_more_time_twotone as speedBonusIcon } from 'react-icons-kit/md/ic_more_time_twotone';
import Decision from 'objects/Decision';
import { useGameTheme } from 'features/Game/useGameTheme';
import { CountUp } from 'components/CountUp';
import { useStores } from 'stores/useStores';
import Helpers from 'tools/Helpers';
import { StoryTextBoxTimings } from '../StoryTextBox';

interface ScoreBonusProps {
  readonly decisionSelected: Decision;
  readonly showStat: boolean;
  readonly show: boolean;
  readonly speedBonus: number;
}

export const ScoreBonus = ({ showStat, show, speedBonus }: ScoreBonusProps) => {
  const { classes: gameClasses } = useGameTheme();
  const {
    settingsStore: { settings },
  } = useStores();
  const { prefix } = settings;
  if (showStat && show && speedBonus) {
    return (
      <div className={gameClasses.separatorStat} style={{ padding: '0.55em' }}>
        <Icon size="1em" icon={speedBonusIcon} style={{ display: 'flex' }} />
        <CountUp
          preserveValue
          number={speedBonus}
          duration={StoryTextBoxTimings.StatCountUpSpeed}
          formattingFn={(number) => Helpers.formattingFn(number, `+${prefix}`)}
        />
      </div>
    );
  }

  return <></>;
};
