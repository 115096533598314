import { DBMap } from './DBStructures';
import { DBProjects } from './DBProjects';
import Project from '../../objects/Project';

export class DBProject extends DBMap<Project> {
  constructor(projectUID: string) {
    super();
    const projectsRef = new DBProjects().ref;
    this.ref = projectsRef.child(projectUID);
  }

  getName = (callback?) => this.getProperty('name', callback);

  setName = (name: string, callback?) => this.setProperty('name', name, callback);

  isPublic = (callback?) =>
    this.ref.once('value', callback).then((snapshot) => snapshot.exists() && snapshot.val().settings.isPublic);

  isOwner = (userUID: string) =>
    this.ref
      .child('adminUID')
      .once('value')
      .then((snapshot) => snapshot.val() === userUID);

  getChildProperty = (childKey: string, propertyKey: string, callback?) =>
    this.ref.child(childKey).child(propertyKey).once('value', callback);
}
