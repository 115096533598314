import { DBMap } from './DBStructures';
import { DBInvites } from './DBInvites';
import Invite from 'objects/Invite';

export class DBUserInvite extends DBMap<Invite> {
  constructor(email: string) {
    super();
    const base64ID = btoa(email);
    const invitesRef = new DBInvites().ref;
    this.ref = invitesRef.child(base64ID);
  }

  async all() {
    return (await this.ref.once('value')).val();
  }

  async refuseInvite(projectUID: string) {
    return await this.ref.child(projectUID).remove();
  }

  async acceptInvite(projectUID: string) {
    // Add project to users projects list
    return await this.ref.child(projectUID).remove();
  }
}
