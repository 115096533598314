import { DBList } from './DBStructures';
import { DBProject } from './DBProject';
import LeaderboardObject from '../../objects/LeaderboardObject';

export class DBLeaderboard extends DBList<LeaderboardObject> {
  public projectUID: string;

  constructor(projectUID: string) {
    super();
    this.projectUID = projectUID;
    const projectRef = new DBProject(projectUID).ref;
    this.ref = projectRef.child('leaderboard');
  }

  getOrdered = (callback?) => this.ref.orderByChild('score').limitToFirst(100).on('child_added', callback);
}
