import { DBList } from './DBStructures';
import { DBProject } from './DBProject';
import { Team } from '../../objects/Team';

export class DBTeams extends DBList<Team> {
  public projectUID: string;

  constructor(projectUID: string) {
    super();
    this.projectUID = projectUID;
    const projectRef = new DBProject(projectUID).ref;
    this.ref = projectRef.child('teams');
  }
}
