import { DBProject } from '@firebase/dbUtil';

import projectSchema from 'validators/json/projects';

import JsonSchemaValidator from './JsonSchemaValidator';

export default class Helpers {
  public static formattingFn = (number = 0, prefix = '', suffix = ''): string => {
    let text = number.toString();
    if (number >= 1e9) {
      text = (number / 1e9).toFixed(1).replace(/\.0$/, '') + 'b';
    } else if (number >= 1e6) {
      text = (number / 1e6).toFixed(1).replace(/\.0$/, '') + 'm';
    } else if (number >= 1e3) {
      text = (number / 1e3).toFixed(1).replace(/\.0$/, '') + 'k';
    }
    return `${prefix}${text}${suffix}`;
  };

  public static importProjectFromJSON = async (file: File, projectUID: string): Promise<any> => {
    if (file && file.type === 'application/json') {
      const updatePayload = JSON.parse(await file.text());
      const validator = new JsonSchemaValidator(updatePayload, projectSchema);

      if (!validator.validate()) throw new Error(validator.formattedError);

      const project = new DBProject(projectUID);
      await project.update(updatePayload);
      return updatePayload;
    } else {
      throw new Error('Invalid file format');
    }
  };

  public static slugify = (text: string, { keepLastChar = false } = {}): string => {
    // Use hash map for special characters
    const specialChars: object = {
      à: 'a',
      ä: 'a',
      á: 'a',
      â: 'a',
      æ: 'a',
      å: 'a',
      ë: 'e',
      è: 'e',
      é: 'e',
      ê: 'e',
      î: 'i',
      ï: 'i',
      ì: 'i',
      í: 'i',
      ò: 'o',
      ó: 'o',
      ö: 'o',
      ô: 'o',
      ø: 'o',
      ù: 'o',
      ú: 'u',
      ü: 'u',
      û: 'u',
      ñ: 'n',
      ç: 'c',
      ß: 's',
      ÿ: 'y',
      œ: 'o',
      ŕ: 'r',
      ś: 's',
      ń: 'n',
      ṕ: 'p',
      ẃ: 'w',
      ǵ: 'g',
      ǹ: 'n',
      ḿ: 'm',
      ǘ: 'u',
      ẍ: 'x',
      ź: 'z',
      ḧ: 'h',
      '·': '-',
      '/': '-',
      _: '-',
      ',': '-',
      ':': '-',
      ';': '-',
    };

    let slug = text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(/./g, (target, _index, _str) => specialChars[target] || target) // Replace special characters using the hash map
      .replace(/&/g, '-and-') // Replace & with 'and'
      .replace(/[^\w\-]+/g, '') // Remove all non-word chars
      .replace(/\-\-+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, ''); // Trim - from start of text

    if (!keepLastChar) {
      slug = slug.replace(/-+$/, ''); // Trim - from end of text
    }

    return slug;
  };
}
