import React, { useState, MouseEvent, useEffect, FC } from 'react';
import { Button, DialogContent, DialogContentText, DialogActions, TextField, DialogProps } from '@material-ui/core';

import { Dialog, DialogTitle } from 'components/Dialogs/Dialog';

export type VideoDialogProps = {
  videoUrl: string;
  handleClose: (event: MouseEvent) => any;
  handleConfirm: (videoUrl: string) => any;
} & DialogProps;

export const VideoDialog: FC<VideoDialogProps> = ({ open, handleClose, handleConfirm, ...rest }) => {
  const [videoUrl, setVideoUrl] = useState(rest.videoUrl);

  useEffect(() => {
    setVideoUrl(rest.videoUrl);
  }, [rest.videoUrl]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...rest}
    >
      <DialogTitle id="alert-dialog-title">Add video background</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Enter the url for your video below. Your video can be a link to your own video, or to a video on YouTube,
          Facebook, Twitch, SoundCloud, Streamable, Vimeo, Wistia, Mixcloud, and DailyMotion.
        </DialogContentText>
        <TextField
          onChange={(e) => setVideoUrl(e.target.value)}
          autoFocus
          margin="dense"
          id="videoUrl"
          label="Url to your video"
          fullWidth
          value={videoUrl}
          autoComplete="off"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => handleConfirm(videoUrl)} color="primary" autoFocus>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
