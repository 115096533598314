import { observable, action, makeObservable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

import { DBStepGroups, DBSteps, DBDecisions } from '@firebase/dbUtil';
import { DBFlowchart } from '@firebase/dbUtil';

import { RootStore } from 'stores';
import Step from 'objects/Step';
import Chapter from 'objects/Chapter';
import { generateTimestamp } from 'utils/DateUtils';

import { NodePosition } from 'features/FlowchartEditor/diagrams/DiagramSerializer';
import { FlowchartConfiguration } from 'objects/FlowchartConfiguration';
import { moveArrayItem } from 'utils/ArrayUtils';
import States from 'objects/States';

class ChapterStore {
  rootStore: RootStore;
  chapters: Chapter[] = [];
  loaded = false;
  gameLoaded = false;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      loaded: observable,
      gameLoaded: observable,
      chapters: observable,
      setLoaded: action,
      setGameLoaded: action,
      setChapters: action,
      getChapterById: action,
      addNewChapter: action,
      deleteChapter: action,
      updateChapterProperty: action,

      getStepById: action,
      addNewStep: action,
      deleteStepById: action,

      updatePageProperty: action,
      updateDecisionProperty: action,
      moveDecision: action,
      addNewDecision: action,
      deleteDecision: action,

      getFlowchartConfiguration: action,
      setFlowchartConfiguration: action,
      deleteFlowchartConfiguration: action,
      updateFlowchartConfigurationProperty: action,
      setNodePosition: action,
      getNodePosition: action,
    });

    this.rootStore = rootStore;
  }

  get projectUID(): string {
    return this.rootStore.projectUID;
  }

  updateChapterUpdatedAt = (stepGroupIndex) => {
    this.chapters[stepGroupIndex].updatedAt = generateTimestamp();
  };

  setLoaded = (loaded) => {
    this.loaded = loaded;
  };

  setChapters = (chapters) => {
    this.chapters = chapters;
    this.loaded = true;
  };

  setGameLoaded = (value = true) => {
    this.gameLoaded = value;
  };

  getChapterById = (id) => {
    if (!id) return;
    return this.chapters.find((c) => c.id.toString() === id.toString());
  };

  addNewChapter = (projectUID, chapter) => {
    this.chapters = [...this.chapters, chapter];
    new DBStepGroups(projectUID).setAll(this.chapters);
  };

  deleteChapter = (projectUID, index) => {
    this.chapters.forEach((chapter) => {
      Object.keys(chapter?.storys).forEach((stepID) => {
        const step = chapter.storys[stepID];
        step?.decisions.forEach((decision) => {
          if (decision.jumpChapter === this.chapters[index].id) {
            decision.jumpChapter = '-1';
          }
        });
      });
    });

    if (index !== -1) {
      this.chapters.splice(index, 1);
    }
    new DBStepGroups(projectUID).setAll(this.chapters);
  };

  updateChapterProperty = (projectUID, stepGroupIndex, propertyKey, property, callback) => {
    const chapter = this.chapters[stepGroupIndex];
    chapter[propertyKey] = property;
    chapter.updatedAt = generateTimestamp();
    new DBStepGroups(projectUID).setItemProperty(stepGroupIndex, propertyKey, property, callback);
  };

  copyStep = (stepGroupIndex, stepID): Step => {
    this.updateChapterUpdatedAt(stepGroupIndex);

    const id = uuidv4();
    const copiedStep: Step = {
      ...this.chapters[stepGroupIndex].storys[stepID],
      decisions: this.chapters[stepGroupIndex].storys[stepID].decisions.map((decision) => ({
        ...decision,
        id: uuidv4(),
      })),
      id,
    };

    this.chapters[stepGroupIndex].storys[id] = copiedStep;
    new DBStepGroups(this.projectUID).setAll(this.chapters);

    return this.chapters[stepGroupIndex].storys[id];
  };

  updatePageWithoutTimestamp = (stepGroupIndex, stepID, propertyKey, property, callback?) => {
    const step = this.chapters[stepGroupIndex].storys[stepID];
    step[propertyKey] = property;
    new DBSteps(this.projectUID, stepGroupIndex).updateItem(
      stepID,
      {
        [propertyKey]: property,
      },
      callback,
    );
  };

  updatePageProperty = (stepGroupIndex, stepID, propertyKey, property, callback?) => {
    const step = this.chapters[stepGroupIndex].storys[stepID];
    step[propertyKey] = property;
    new DBSteps(this.projectUID, stepGroupIndex).updateItem(
      stepID,
      {
        [propertyKey]: property,
        updatedAt: generateTimestamp(),
      },
      callback,
    );
  };

  getStepById = (stepGroupIndex, id) => {
    return this.chapters[stepGroupIndex]?.storys[id];
  };

  getStepIndexById = (stepGroupIndex, id) => {
    return this.chapters[stepGroupIndex]?.storys[id];
  };

  getDecisionById = (stepGroupIndex, stepID, id) => {
    return this.chapters[stepGroupIndex]?.storys?.[stepID]?.decisions?.findIndex((decision) => decision.id === id);
  };

  addNewStep = (stepGroupIndex: number, step: Step = { ...new Step() }): Step => {
    new DBSteps(this.projectUID, stepGroupIndex.toString()).setItem(step.id, step);
    this.updateChapterUpdatedAt(stepGroupIndex);
    this.chapters[stepGroupIndex].storys[step.id] = step;

    return this.chapters[stepGroupIndex].storys[step.id];
  };

  deleteStepById = (stepGroupIndex, id) => {
    this.chapters.forEach((chapter) => {
      Object.keys(chapter?.storys).forEach((stepID) => {
        const step = chapter.storys[stepID];
        step.decisions.forEach((decision) => {
          if (decision.jumpChapter === this.chapters[stepGroupIndex].id && decision.jumpPage === id) {
            decision.jumpPage = States.NA;
            this.chapters[stepGroupIndex].storys[id].hasError = true;
          }
        });
      });
    });

    delete this.chapters[stepGroupIndex].storys[id];
    // if (stepIndex !== -1) {
    //   this.chapters[stepGroupIndex].storys.splice(stepIndex, 1);
    // }
    this.updateChapterUpdatedAt(stepGroupIndex);

    new DBStepGroups(this.projectUID).setAll(this.chapters);
  };

  updateDecisionProperty = (stepGroupIndex, stepID, decisionIndex, propertyKey, property, callback?) => {
    const decision = this.chapters[stepGroupIndex].storys[stepID]?.decisions[decisionIndex];
    this.updateChapterUpdatedAt(stepGroupIndex);

    if (decision) {
      decision[propertyKey] = property;

      new DBDecisions(this.projectUID, stepGroupIndex, stepID).setItemProperty(
        decisionIndex,
        propertyKey,
        property,
        callback,
      );
    }
  };

  moveDecision = (projectUID, stepGroupIndex, stepID, oldDecisionIndex, newDecisionIndex) => {
    this.updateChapterUpdatedAt(stepGroupIndex);
    this.chapters[stepGroupIndex].storys[stepID].decisions = moveArrayItem(
      this.chapters[stepGroupIndex].storys[stepID].decisions,
      oldDecisionIndex,
      newDecisionIndex,
    );
    new DBDecisions(projectUID, stepGroupIndex, stepID).setAll(this.chapters[stepGroupIndex].storys[stepID].decisions);
  };

  firstStep = (chapterIndex = 0) => {
    return this.chapters[chapterIndex]?.firstStep;
  };

  addNewDecision = (projectUID, stepGroupIndex, stepID, decision) => {
    let { decisions } = this.chapters[stepGroupIndex].storys[stepID];
    if (!decisions) {
      decisions = [];
    }
    this.updateChapterUpdatedAt(stepGroupIndex);
    decisions.push({ ...decision });
    new DBDecisions(projectUID, stepGroupIndex, stepID).setAll(decisions);
  };

  getDecision = (stepGroupIndex, stepUID, decisionUID) => {
    return this.chapters[stepGroupIndex]?.storys[stepUID]?.decisions[decisionUID];
  };

  deleteDecision = (projectUID, stepGroupIndex, stepID, decisionIndex, callback?) => {
    this.chapters[stepGroupIndex].storys[stepID].decisions.splice(decisionIndex, 1);
    this.updateChapterUpdatedAt(stepGroupIndex);
    new DBDecisions(projectUID, stepGroupIndex, stepID).setAll(
      this.chapters[stepGroupIndex].storys[stepID].decisions,
      callback,
    );
  };

  // Logic Step
  updateLogicStepProperty = <K extends keyof Step['logic']>(
    stepGroupIndex,
    stepID,
    key: K,
    value: Step['logic'][K],
  ) => {
    const stepLogic = this.chapters[stepGroupIndex].storys[stepID].logic;
    stepLogic[key] = value;
    new DBSteps(this.projectUID, stepGroupIndex).updateItemProperty(stepID, 'logic', stepLogic);
  };

  updateLogicStepPathProperty = (stepGroupIndex, stepID, path, key, value) => {
    const stepLogicPath = this.chapters[stepGroupIndex].storys[stepID].logic[path];
    stepLogicPath[key] = value;
    new DBSteps(this.projectUID, stepGroupIndex).updateItemProperty(stepID, 'logic', {
      [path]: stepLogicPath,
    } as any);
  };

  // Flowchart Configuration
  getFlowchartConfiguration = (stepGroupIndex: number) => {
    return this.chapters[stepGroupIndex]?.flowchartConfiguration || new FlowchartConfiguration();
  };

  setFlowchartConfiguration = (stepGroupIndex: number, flowchartConfiguration: FlowchartConfiguration) => {
    // new DBFlowchart(this.projectUID, stepGroupIndex.toString()).set(flowchartConfiguration);
    this.chapters[stepGroupIndex].flowchartConfiguration = flowchartConfiguration;
  };

  deleteFlowchartConfiguration = (stepGroupIndex: number) => {
    new DBFlowchart(this.projectUID, stepGroupIndex.toString()).remove();
    this.chapters[stepGroupIndex].flowchartConfiguration = null;
  };

  updateFlowchartConfigurationProperty = <K extends keyof Chapter['flowchartConfiguration']>(
    stepGroupIndex: number,
    key: K,
    value: Chapter['flowchartConfiguration'][K],
  ) => {
    try {
      new DBFlowchart(this.projectUID, stepGroupIndex.toString()).setProperty(key, value);
      this.chapters[stepGroupIndex].flowchartConfiguration[key] = value;
    } catch (e) {
      return null;
    }
  };

  setNodePosition = (stepGroupIndex, id: string, position: NodePosition) => {
    new DBFlowchart(this.projectUID, stepGroupIndex.toString()).updateNodePosition(id, position);
    if (!this.chapters[stepGroupIndex].flowchartConfiguration) {
      this.chapters[stepGroupIndex].flowchartConfiguration = new FlowchartConfiguration();
    }
    if (!this.chapters[stepGroupIndex].flowchartConfiguration.nodesPosition) {
      this.chapters[stepGroupIndex].flowchartConfiguration.nodesPosition = {};
    }
    this.chapters[stepGroupIndex].flowchartConfiguration.nodesPosition[id] = position;
  };

  getNodePosition = (stepGroupIndex, id: string) => {
    return this.chapters[stepGroupIndex].flowchartConfiguration?.nodesPosition?.[id];
  };
}

export default ChapterStore;
