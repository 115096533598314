export enum AvailableCurrencies {
  GBP = 'GBP',
}

export enum CurrencySymbols {
  GBP = '£',
  USD = '$',
  EURO = '€',
  BITCOIN = '₿',
  YEN = '¥',
  OTHER = '',
}
