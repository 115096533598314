import { observable, action, makeObservable, computed } from 'mobx';

import { DBLearners } from '@firebase/dbUtil';

import { RootStore } from './index';
import { Learner } from 'objects/Learner';

class AdminStore {
  rootStore: RootStore;
  projectUID: string;
  loaded: boolean;
  learners: { [key: string]: Learner };

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    this.loaded = false;
    this.learners = {};

    makeObservable(this, {
      learners: observable,
      setProjectUID: action,
      loaded: observable,
      setLoaded: observable,
      learnersCount: computed,
    });
  }

  setProjectUID = (uid) => {
    this.projectUID = uid;
  };

  init({ projectUID }) {
    this.setProjectUID(projectUID);
    this.loadLearners();
  }

  setLoaded = (loaded: boolean) => {
    this.loaded = loaded;
  };

  setLearners = (learners: any) => {
    this.learners = learners;
    this.setLoaded(true);
  };

  loadLearners = () => {
    new DBLearners(this.projectUID).getAll().then((data) => this.setLearners(data.val()));
  };

  get learnersCount(): number {
    return Object.keys(this.learners).length;
  }
}

export default AdminStore;
