import { makeStyles } from '@material-ui/core';

export const useOnboardingDialogStyles = makeStyles({
  footerActions: {
    display: 'flex',
    marginLeft: 16,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});
