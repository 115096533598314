export type Timestamp = number;

export function generateTimestamp(): number {
  return new Date().valueOf();
}

export function timestampToDate(timestamp: number, locale = 'en-GB'): string {
  if (!timestamp) return null;
  return new Intl.DateTimeFormat(locale).format(new Date(timestamp));
}

export function timestampToDatetime(timestamp: number, locale = 'en-GB'): string {
  if (!timestamp) return null;
  return new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }).format(new Date(timestamp));
}
