import { StyleTheme, ThemeFonts } from 'objects/StyleTheme';
import { themedMakeStyles } from 'providers/ThemeProvider';

export const useQuillStyles = themedMakeStyles<{ fonts: ThemeFonts; link: StyleTheme['link'] }>((theme) => ({
  root: ({ fonts }) => ({
    fontSize: '12px !important',

    '& .ql-picker-item[data-value="body"], p': {
      fontSize: '1em !important',
      padding: '5px 0 !important',
      fontFamily: fonts.body.family,
    },
    '& .ql-picker-item[data-value="1"], h1': {
      fontSize: '1.3em !important',
      padding: '5px 0 !important',
      fontFamily: fonts.heading1.family,
    },
    '& .ql-picker-item[data-value="2"], h2': {
      fontSize: '1.1em !important',
      padding: '5px 0 !important',
      fontFamily: fonts.heading2.family,
    },
    '& a': {
      fontSize: 'inherit !important',
      color: '#12c4c4',
    },
  }),
  normalize: {
    '& p, h1, h2, a': {
      margin: '0 !important',
      wordBreak: 'break-word !important',
    },
  },
}));
