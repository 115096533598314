import { createOption } from './utils';

export const border = {
  shape: {
    none: createOption('right angle', '0px'),
    small: createOption('small curve', '0.2em'),
    regular: createOption('regular curve', '0.5em'),
    big: createOption('big curve', '1em'),
  },
  width: {
    none: createOption('no border', '0px'),
    small: createOption('small', '1px'),
    regular: createOption('regular', '2px'),
    big: createOption('big', '5px'),
  },
};
