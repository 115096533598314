import { firebase } from '@firebase/firebaseUtil';
import { generateTimestamp } from 'utils/DateUtils';

type PropertyKey<T> = keyof T & string;

type KeyValueFn<T, Return> = <K extends keyof T>(
  UID: string,
  propertyKey: K & string,
  propertyValue: T[K],
  ...args: any[]
) => Return;

type FirebaseSetPropertyFn<T> = KeyValueFn<T, Promise<any>>;
type FirebaseUpdatePropertyFn<T> = KeyValueFn<T, Promise<any>>;

export class DBList<T> {
  public ref: firebase.database.Reference;

  getAll = (callback?) => this.ref.once('value', callback); // FIREBASE_TODO: maybe its child_added

  setAll = (values: T[], callback?) => this.ref.set(values, callback);

  updateAll = (values: Partial<T[]>, callback?) => this.ref.update(values, callback);

  removeAll = (callback?) => this.ref.remove(callback);

  getItem = (UID: string, callback?) => this.ref.child(UID).once('value', callback);

  pushItem = (values: T, callback?) => this.ref.push(values, callback);

  setItem = (UID: string, values: T, callback?) => this.ref.child(UID).set(values, callback);

  updateItem = (UID: string, values: Partial<T>, callback?) => this.ref.child(UID).update(values, callback);

  removeItem = (UID: string, callback?) => this.ref.child(UID).remove(callback);

  getItemProperty = (UID: string, propertyKey: PropertyKey<T>, callback?) =>
    this.ref.child(UID).child(propertyKey).once('value', callback);

  setItemProperty: FirebaseSetPropertyFn<T> = (UID, propertyKey, propertyValue, callback?) =>
    this.ref.child(UID).child(propertyKey).set(propertyValue, callback);

  updateItemProperty: FirebaseUpdatePropertyFn<T> = (UID, propertyKey, propertyValue, callback?) =>
    this.ref.child(UID).child(propertyKey).update(propertyValue, callback);

  removeItemProperty = (UID: string, propertyKey: PropertyKey<T>, callback?) =>
    this.ref.child(UID).child(propertyKey).remove(callback);

  changeUpdatedAtTimestamp = () => {
    this.ref.child('updatedAt').set(generateTimestamp());
  };
}
