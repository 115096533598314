import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import smoothscroll from 'smoothscroll-polyfill';
import Bugsnag from '@bugsnag/js';

import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import { version as AdeptlyGamesVersion } from 'adeptly-games/package.json';
import { version as AdeptlyPlatformVersion } from '../package.json';

import IntlProvider from 'providers/IntlProvider';
import ThemeProvider from 'providers/ThemeProvider';
import MobXProvider from 'providers/MobXProvider';
import DndProvider from 'providers/DndProvider';
import MaintenanceProvider from 'providers/MaintenanceProvider';
import RemoteConfigContextProvider from 'providers/RemoteConfigProvider';
import SnackbarProvider from 'providers/SnackbarProvider';

import { App } from 'features/App';

import { ErrorBoundary } from 'components/ErrorBoundary';
import { KeyBindingsProvider } from 'providers/KeyBindingsProvider';

smoothscroll.polyfill();

Bugsnag.addMetadata('Versions', { AdeptlyGamesVersion, AdeptlyPlatformVersion });

console.log(`%c----- Running Adeptly Games at ${AdeptlyGamesVersion} --------`, 'background: #6f42c1; color: #ffc107;');
console.log(
  `%c----- Running Adeptly Platform at ${AdeptlyPlatformVersion} -----`,
  'background: #6f42c1; color: #ffc107;',
);

console.log('Url:' + window.location.href);

ReactDOM.render(
  <RemoteConfigContextProvider>
    <ErrorBoundary>
      <MobXProvider>
        <ThemeProvider>
          <DndProvider>
            <IntlProvider>
              <BrowserRouter>
                <SnackbarProvider>
                  <MaintenanceProvider>
                    <KeyBindingsProvider>
                      <App />
                    </KeyBindingsProvider>
                  </MaintenanceProvider>
                </SnackbarProvider>
              </BrowserRouter>
            </IntlProvider>
          </DndProvider>
        </ThemeProvider>
      </MobXProvider>
    </ErrorBoundary>
  </RemoteConfigContextProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();
