import { db } from '../firebaseUtil';
import { DBList } from './DBStructures';
import Theme from '../../objects/Theme';
import file from 'static/themes.json';

export class DBThemes extends DBList<Theme> {
  constructor() {
    super();
    this.ref = db.ref('themes');
  }

  list() {
    return file;
  }
}
