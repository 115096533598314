import { GameText } from '../objects/Settings';
import { version } from '../../package.json';
import { CurrencySymbols } from 'objects/Currency';

interface GameStates {
  unset: string;
  continue: string;
  restart: string;
}

interface Colors {
  green: string;
  red: string;
  purple: string;
  teal: string;
  white: string;
  grey: string;
  lightGrey: string;
  redShades: ColorShades;
  greenShades: ColorShades;
  yellowShades: ColorShades;
}

interface ColorShades {
  c1: string;
  c2: string;
  c3: string;
  c4: string;
  c5: string;
  c6: string;
}

export default class AppConfig {
  public static BUILD_NUMBER = version; // Major Number, Minor Number, Build Number
  public static TOOLTIP_DELAY = 500;
  public static STORY_TEXT_DELAY = 500;
  public static INTRO_LENGTH = 4; // total stories in the intro
  public static MAX_DECISIONS = 4; // max decisions

  public static authPageRoute = '';
  public static projectName = '';
  public static projectSlug = '';

  public static gameStates: GameStates = {
    unset: 'unset',
    continue: 'continue',
    restart: 'restart',
  };

  public static colors: Colors = {
    green: '#79cabadd', // green
    red: '#f47661ee', // red
    purple: '#6F69B4', // purple
    teal: '#12C4C4', // teal
    grey: '#6a6c6a', // grey
    lightGrey: '#DDDDDD', // light grey
    white: '#FFFFFF',
    redShades: {
      c1: '#FF7B65',
      c2: '#EF735F',
      c3: '#E66F5A',
      c4: '#D76855',
      c5: '#C05D4C',
      c6: '#AE5445',
    },
    greenShades: {
      c1: '#9FF0E1',
      c2: '#97E4D5',
      c3: '#89D0C2',
      c4: '#81C4B7',
      c5: '#73AFA4',
      c6: '#669D93',
    },
    yellowShades: {
      c1: '#F9E79F',
      c2: '#DAF7A6',
      c3: '#FFC300',
      c4: '#F1C40F',
      c5: '#D4AC0D',
      c6: '#9A7D0A',
    },
  };

  public static gameLoaded = false;
  public static gameState: string = AppConfig.gameStates.unset;
  public static gameText: GameText = {
    subHeading: '',
    title: '',
    displayTitle: '',
    description: '',
    gameOver: '',
    playerDied: '',
    milestone: '',
  };

  // THIS MUST MATCH THE 'THEME NAME' in the game
  public static SETTINGS_THEMES = {
    blank: { name: 'Blank Theme', slug: 'blank', available: true },
    treasureHunter: { name: 'Cave Exploration', slug: 'treasureHunter', available: false },
    cityHunter: { name: 'City Dash', slug: 'cityHunter', available: false },
    cyberSecurity: { name: 'Cyber Security', slug: 'cyberSecurity', available: false },
    bridgeBuilder: { name: 'Bridge Building', slug: 'bridgeBuilder', available: false },
    hospital: { name: 'Hospital', slug: 'hospital', available: false },
    army: { name: 'Military', slug: 'army', available: false },
    construction: { name: 'Construction', slug: 'construction', available: false },
    office: { name: 'Office', slug: 'office', available: false },
    school: { name: 'School', slug: 'school', available: false },
    marsExploration: { name: 'Mars Exploration', slug: 'marsExploration', available: false },
  };

  public static SETTINGS_VEHICLE_NAMES = {
    Car: 'Car',
    Taxi: 'Taxi',
    FireEngine: 'Fire Engine',
    Ambulance: 'Ambulance',
    PoliceCar: 'Police Car',
    Bus: 'Bus',
  };

  public static SETTINGS_STAT_NAMES = {
    Custom: 'Custom',
    Defence: 'Defence',
    Health: 'Health',
    Money: 'Money',
    Security: 'Security',
    Economy: 'Economy',
    Popularity: 'Popularity',
    Satisfaction: 'Satisfaction',
    People: 'People',
    Time: 'Time',
    Oil: 'Oil',
    Fire: 'Fire',
    Tickets: 'Tickets',
    Speed: 'Speed',
    Weight: 'Weight',
  };

  public static SETTINGS_CURRENCY_NAMES = CurrencySymbols;

  public static MOBILE_WIDTH = 500;
  public static INCOGNITO = true;

  public static COPY_NO_STORY_TEXT =
    "<h2>Game Error!</h2><p>This wasn't meant to happen. If you are the author of this game please check for errors in the editor.</p>";
  public static COPY_MILESTONE_TEXT = "<p>We've reached a milestone! Let's see how you're doing...</p>";
  public static COPY_NO_DECISION_TEXT =
    "<h2>Game Error!</h2><p>This wasn't meant to happen. This step has no decisions available.</p>";

  public static MAX_CHARACTERS = {
    STEP: 400,
    DECISION: 200,
  };
}
