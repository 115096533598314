import { db } from '../firebaseUtil';
import User from '../../objects/User';
import { DBList } from './DBStructures';

export class DBUsers extends DBList<User> {
  constructor() {
    super();
    this.ref = db.ref('users');
  }

  async getByUID(uid, callback?) {
    return this.ref
      .child(uid)
      .once('value', callback)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          return { key: uid, data: data };
        }
        return null;
      });
  }

  getUserByEmail = (email: string, callback?) =>
    this.ref
      .orderByChild('email')
      .equalTo(email)
      .limitToFirst(1)
      .once('value', callback)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const userSnapshot = snapshot.val();
          const key = Object.keys(userSnapshot)[0];
          const data = userSnapshot[key];
          return { key, data };
        }
        return null;
      });

  add = (userUID: string, user: User) => this.ref.child(userUID).set(user);

  updateMeta = (userUID, meta) => this.ref.child(userUID).child('meta').set(meta);

  setProjectName = (userUID: string, projectUID: string, projectName: string) =>
    this.setItemProperty(userUID, `projects/${projectUID}` as any, projectName);

  removeProject = (userUID: string, projectUID: string) => {
    return this.ref.child(userUID).child('projects').child(projectUID).remove();
  };

  removeInvitedProject = (userUID: string, projectUID: string) => {
    return this.ref.child(userUID).child('invitedProjects').child(projectUID).remove();
  };

  addProject = (userUID: string, projectUID: string) => {
    return this.ref.child(userUID).child('projects').child(projectUID).set(projectUID);
  };

  addInvitedProject = (userUID: string, projectUID: string) => {
    return this.ref.child(userUID).child('invitedProjects').child(projectUID).set(projectUID);
  };

  hasProject = async (userUID: string, projectUID: string): Promise<boolean> => {
    return (await this.ref.child(userUID).child('projects').child(projectUID).once('value')).exists();
  };

  hasInvitedProject = async (userUID: string, projectUID: string): Promise<boolean> => {
    return (await this.ref.child(userUID).child('invitedProjects').child(projectUID).once('value')).exists();
  };
}
