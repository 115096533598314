import Ajv from 'ajv';

export default class JsonSchemaValidator {
  schema: any;
  jsonInput: any;
  validator: Ajv.Ajv;
  errors: Array<any>;
  hasError: boolean;
  formattedError: string;

  constructor(jsonInput: any, schema: any) {
    this.jsonInput = jsonInput;
    this.schema = schema;
    this.hasError = false;
    this.errors = [];
    this.validator = new Ajv({ removeAdditional: true });

    return this;
  }

  public validate(): boolean {
    if (!this.validator.validate(this.schema, this.jsonInput)) {
      this.hasError = true;
      this.errors = this.validator.errors;
      this.formattedError = this.validator.errorsText();
      return false;
    }

    return true;
  }
}
