import { Timestamp } from 'utils/DateUtils';
import AppConfig from 'config/AppConfig';

import { Providers } from '../Providers';
import { PAGE_POSITIONS } from 'features/Styling/Tabs/SettingsThemeStep/SettingsStepPositionToggleComponent';
import { STEP_IMAGE_POSITIONS } from 'features/Styling/Tabs/SettingsThemeStep/SettingsStepImageFitToggleComponent';
import Languages from './Languages';
import { AvailableRegions } from 'storage-client';
import { ImpactIconType } from 'components/GameDecisionTaken';
import { IconList } from 'react-fa-icon-picker';
export interface ImpactScoreValues {
  tiny: number;
  small: number;
  medium: number;
  large: number;
  huge: number;
}

export interface ThemeColor {
  r: string;
  g: string;
  b: string;
  a: string;
}

export enum StatTriggers {
  GameOver = 'Game Over',
  GameComplete = 'Game Complete',
  DoNothing = 'Do Nothing',
}

export interface CustomThemeStyles {
  primaryColor: ThemeColor;
  backgroundColor: ThemeColor;
  questionBackgroundColor: ThemeColor;
  questionColor: ThemeColor;
  answerColor: ThemeColor;
  answerBackgroundColor: ThemeColor;
}

export interface CustomerPolicy {
  url: string;
  filename: string;
  updatedAt: Timestamp;
}

export enum BadDecisionDirection {
  Decrease = 'decrease',
  Increase = 'increase',
}

export type DecisionDirectionSymbol = '+' | '-';

export interface GameText {
  subHeading: string;
  title: string;
  displayTitle: string;
  description: string;
  gameOver: string;
  playerDied: string;
  milestone: string;
}

export interface LRSCredentials {
  username: string;
  password: string;
  endpoint: string;
}

export default class Settings {
  logoUrl = '';
  theme: string = AppConfig.SETTINGS_THEMES.blank.name;
  vehicle: string = AppConfig.SETTINGS_VEHICLE_NAMES.Bus;
  region: AvailableRegions = 'us';
  stat: string = AppConfig.SETTINGS_STAT_NAMES.Custom;
  badDecisionDirection: BadDecisionDirection = BadDecisionDirection.Decrease;
  faviconUrl = '';
  customStat = '';
  customStatIcon: IconList = '';
  companyLogo = '';
  authBackgroundImg = '';
  maxStat = 100;
  maxStatTrigger = StatTriggers.GameComplete;
  minStat = 0;
  minStatTrigger = StatTriggers.GameOver;
  startingStat = 50;
  prefix = '';
  suffix = '%';
  stepAlignment: string = PAGE_POSITIONS.bottom;
  stepImageFit: string = STEP_IMAGE_POSITIONS.contain;
  isPublic = true;
  isLocked = false;
  projectLanguage = 'en-US';
  allowLearnersMultipleTeams = true;
  googleAnalyticsKey = '';
  languages: Languages = new Languages();
  providers = [Providers.Manual, Providers.Microsoft, Providers.Google];
  customerPolicy: CustomerPolicy = {
    filename: null,
    url: null,
    updatedAt: null,
  };
  autoLogout = false;
  customThemeStyles: CustomThemeStyles = {
    primaryColor: { r: '74', g: '144', b: '226', a: '1' },
    backgroundColor: { r: '0', g: '0', b: '0', a: '1' },
    questionBackgroundColor: { r: '32', g: '36', b: '48', a: '0.95' },
    questionColor: { r: '256', g: '256', b: '256', a: '1' },
    answerColor: { r: '256', g: '256', b: '256', a: '1' },
    answerBackgroundColor: { r: '74', g: '144', b: '226', a: '1' },
  };

  impactScoreValues: ImpactScoreValues = {
    tiny: 20,
    small: 50,
    medium: 100,
    large: 250,
    huge: 500,
  };

  impactStatValues: ImpactScoreValues = {
    tiny: 5,
    small: 15,
    medium: 30,
    large: 50,
    huge: 100,
  };

  impactIconType: ImpactIconType = ImpactIconType.None;

  text: GameText = {
    subHeading: 'Do you know...',
    title: 'Insert Title',
    displayTitle: 'Insert Title',
    description: 'You can add a title and description for your learning game in the admin settings',
    gameOver: "<h2>Congratulations!</h2><p>You've made it to the end!</p>",
    playerDied: "<h2>Bad Decision!</h2><p>You haven't made it to the end!</p>",
    milestone: "We've reached a milestone! Let's see how you're doing...",
  };

  lrs: LRSCredentials = {
    username: '',
    password: '',
    endpoint: '',
  };
}
