import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { auth } from '@firebase/firebaseUtil';

const releaseStage = process.env.REACT_APP_BUGSNAG_ENV;

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_KEY,
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ['production', 'staging'],
  releaseStage,
  onError: function (event) {
    if (auth.currentUser) {
      const { uid, email, displayName } = auth.currentUser;
      event.setUser(uid, email, displayName);
    }
  },
});

export const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
