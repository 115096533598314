import React, { FC, ReactNode } from 'react';
import { Button, DialogActions, DialogContent, DialogContentText, DialogProps, makeStyles } from '@material-ui/core';

import { DialogTitle, Dialog } from 'components/Dialogs/Dialog';

export interface ConfirmDialogProps extends DialogProps {
  confirmLabel?: string;
  cancelLabel?: string;
  isConfirmDisabled?: boolean;
  isCancelDisabled?: boolean;
  onConfirm: (...args) => void;
  onCancel?: () => void;
  onClose?: () => void;
  content?: ReactNode;
  subtitle?: string;
}
const useStyles = makeStyles({
  subtitle: {
    padding: '0 24px',
  },
});

function Content({ content }) {
  if (React.isValidElement(content)) {
    return content;
  }
  return <DialogContentText id="confirm-dialog-description">{content}</DialogContentText>;
}

function Subtitle({ subtitle, className }) {
  if (!subtitle) {
    return null;
  }
  return (
    <DialogContentText className={className} id="confirm-dialog-subtitle">
      {subtitle}
    </DialogContentText>
  );
}

export const ConfirmDialog: FC<ConfirmDialogProps> = ({
  confirmLabel = 'Confirm',
  cancelLabel = 'Cancel',
  isConfirmDisabled = false,
  isCancelDisabled = false,
  title,
  content,
  onConfirm,
  onCancel,
  onClose,
  open,
  subtitle,
  ...otherProps
}) => {
  const classes = useStyles({});

  return (
    <Dialog
      open={open}
      onClose={onClose || onCancel}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
      fullWidth
      maxWidth="sm"
      {...otherProps}
    >
      <DialogTitle id="confirm-dialog-title">{title}</DialogTitle>
      <Subtitle className={classes.subtitle} subtitle={subtitle} />
      <DialogContent>
        <Content content={content} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary" disabled={isCancelDisabled}>
          {cancelLabel}
        </Button>
        <Button onClick={onConfirm} color="primary" autoFocus disabled={isConfirmDisabled}>
          {confirmLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
