import { firebase } from '@firebase/firebaseUtil';
import { storage } from '@firebase/firebaseUtil';
import { AvailableRegions } from 'storage-client';
import FileUploader from 'storage-client/FileUploader';

type UploadTypes = 'steps' | 'bundles' | 'settings' | 'documents' | 'styles';

interface FileUploadServiceParams {
  projectUID: string;
  type?: UploadTypes;
  region?: AvailableRegions;
  acceptedFileTypes?: string[];
}

const BUNDLE_CONTENT_TYPE = 'application/json';

const MAX_FILE_SIZE = 5242880; // 5 MB

export default class FileUploadService {
  storage: firebase.storage.Storage = storage;
  path?: string = 'images';
  projectUID: string;
  type?: UploadTypes;
  region?: AvailableRegions;
  acceptedFileTypes?: string[];
  files: Array<{ file: File; id: string }> = [];
  processedFiles: Array<any> = [];
  progress: number;
  onComplete?: (complete: any) => any | void;
  onProgress?: (snapshot: firebase.storage.UploadTaskSnapshot) => any | void;
  onError?: (error: Error | any, friendlyMessage?: String) => any | void;
  onFileUpload?: (uuid: string) => any | void;

  constructor({ projectUID, type = 'steps', region = 'us', acceptedFileTypes }: FileUploadServiceParams) {
    this.projectUID = projectUID;
    this.type = type;
    this.region = region;
    this.acceptedFileTypes = acceptedFileTypes;
  }

  add(file: File, id: string): Array<{ file: File; id: string }> {
    if (this.acceptedFileTypes && !this.acceptedFileTypes.includes(file.type)) {
      this.onError?.(new Error('File type not allowed!'));
      return this.files;
    }

    if (file.size > MAX_FILE_SIZE) {
      this.onError?.(new Error('File too big! Max file size 5 MB'));
      return this.files;
    }

    this.files = [...this.files, { file, id }];
    return this.files;
  }

  async upload(): Promise<any> {
    await this.files.forEach(async ({ file, id }) => {
      FileUploader.upload({
        projectUID: this.projectUID,
        file,
        filename: `${this.type}/${id}`,
        region: this.region,
      })
        .then((response) => {
          this.onFileUpload?.(response.url);
          this.onComplete?.call(true);
        })
        .catch((e) => {
          this.onError?.(e, 'Uh oh! File upload failed!');
          this.onComplete?.call(true);
        });
    });
  }

  async destroy(): Promise<void> {
    return;
  }

  async addJSON(json: any, id: string) {
    const file = new File([JSON.stringify(json)], this.path, {
      type: BUNDLE_CONTENT_TYPE,
    });

    this.files = [...this.files, { file, id }];
    return this.files;
  }
}
