import { firebase } from '@firebase/firebaseUtil';
import Step from 'objects/Step';
import { generateTimestamp } from 'utils/DateUtils';

import { DBList } from './DBStructures';
import { DBStepGroups } from './DBStepGroups';

export class DBSteps extends DBList<Step> {
  public projectUID: string;
  public processUID: string;
  private processesRef: firebase.database.Reference;

  constructor(projectUID: string, processUID: string) {
    super();
    this.projectUID = projectUID.toString();
    this.processUID = processUID.toString();
    this.processesRef = new DBStepGroups(this.projectUID).ref;
    this.ref = this.processesRef.child(this.processUID).child('storys');
  }

  changeUpdatedAtTimestamp = () => {
    this.ref.child('updatedAt').set(generateTimestamp());
  };
}
