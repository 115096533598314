import { Icon } from 'react-icons-kit';
import { gbp } from 'react-icons-kit/fa/gbp';
import { fireExtinguisher } from 'react-icons-kit/fa/fireExtinguisher';
import { rocket } from 'react-icons-kit/fa/rocket';
import { ticket } from 'react-icons-kit/fa/ticket';
import { user } from 'react-icons-kit/fa/user';
import { heart } from 'react-icons-kit/fa/heart';
import { hourglass3 } from 'react-icons-kit/fa/hourglass3';
import { droplet } from 'react-icons-kit/icomoon/droplet';
import { dashboard } from 'react-icons-kit/fa/dashboard';
import { diamond } from 'react-icons-kit/fa/diamond';
import SecurityIcon from '@material-ui/icons/Security';
import { IconList, IconPickerItem } from 'react-fa-icon-picker';

import AppConfig from 'config/AppConfig';

const RIKIcon = ({ icon }) => <Icon size="1em" icon={icon} style={{ display: 'flex' }} />;

export function getIcon(stat = AppConfig.SETTINGS_STAT_NAMES.Health, customIconType: IconList = ''): any {
  let icon: any;

  switch (stat) {
    case AppConfig.SETTINGS_STAT_NAMES.Money:
      icon = <RIKIcon icon={gbp} />;
      break;
    case AppConfig.SETTINGS_STAT_NAMES.Economy:
      icon = <RIKIcon icon={gbp} />;
      break;
    case AppConfig.SETTINGS_STAT_NAMES.Oil:
      icon = <RIKIcon icon={droplet} />;
      break;
    case AppConfig.SETTINGS_STAT_NAMES.Health:
      icon = <RIKIcon icon={heart} />;
      break;
    case AppConfig.SETTINGS_STAT_NAMES.Time:
      icon = <RIKIcon icon={hourglass3} />;
      break;
    case AppConfig.SETTINGS_STAT_NAMES.Weight:
      icon = <RIKIcon icon={dashboard} />;
      break;
    case AppConfig.SETTINGS_STAT_NAMES.Tickets:
      icon = <RIKIcon icon={ticket} />;
      break;
    case AppConfig.SETTINGS_STAT_NAMES.Fire:
      icon = <RIKIcon icon={fireExtinguisher} />;
      break;
    case AppConfig.SETTINGS_STAT_NAMES.People:
      icon = <RIKIcon icon={user} />;
      break;
    case AppConfig.SETTINGS_STAT_NAMES.Speed:
      icon = <RIKIcon icon={rocket} />;
      break;
    case AppConfig.SETTINGS_STAT_NAMES.Security:
      icon = <SecurityIcon />;
      break;
    case AppConfig.SETTINGS_STAT_NAMES.Defence:
      icon = <SecurityIcon />;
      break;
    case AppConfig.SETTINGS_STAT_NAMES.Custom:
      icon = (
        <IconPickerItem
          size={21}
          color="#fff"
          icon={customIconType}
          containerStyles={{ display: 'flex', padding: 0 }}
        />
      );
      break;
    default:
      icon = <RIKIcon icon={diamond} />;
      break;
  }

  return icon;
}
