import StylingOptions from 'config/StylingOptions';
import { StepAlignmentOptions } from 'objects/Step';

export interface ThemeColor {
  r: string;
  g: string;
  b: string;
  a: string;
}

export type ThemeFontsKey = 'body' | 'heading1' | 'heading2' | 'button';
export type ThemeFont = {
  family: string;
  size: 'small' | 'regular' | 'big' | 'veryBig' | 'giant';
  weight: number;
  color: ThemeColor;
};
export type ThemeFonts = Record<ThemeFontsKey, ThemeFont>;
export type ThemeBorder = {
  radius: number;
  width: number;
  color: ThemeColor;
};

export type ThemeBoxShadow = {
  size: string;
  blur: string;
  opacity: string;
  color: ThemeColor;
};

export type ThemeBox = {
  angle: string;
  border: ThemeBorder;
};

export type ThemeBackgroundBox = ThemeBox & {
  backgroundColor: ThemeColor;
};

export type ThemeStep = ThemeBackgroundBox & {
  boxShadow: ThemeBoxShadow;
  textAlignment: StepAlignmentOptions;
  speed: number;
};

export type ThemeDecision = ThemeBackgroundBox & {
  boxShadow: ThemeBoxShadow;
};

export interface StyleTheme {
  game: {
    width: string;
    backgroundColor: ThemeColor;
    flow: 'vertical-scrolling' | 'horizontal-scrolling' | 'single-step';
  };
  score: {
    backgroundColor: ThemeColor;
    fontColor: ThemeColor;
  };
  fonts: ThemeFonts;
  step: ThemeStep;
  decisionTaken: {
    angle: string;
    opacity: number;
  };
  decision: ThemeDecision;
  image: ThemeBox;
  video: {
    border: ThemeBorder;
    playerControls: boolean;
    autoplay: boolean;
  };
  link: {
    fontColor: ThemeColor;
    hoverFontColor: ThemeColor;
  };
}

export const styleTheme: StyleTheme = {
  game: {
    width: 'fixed',
    backgroundColor: { r: '19', g: '122', b: '230', a: '1' },
    flow: 'vertical-scrolling',
  },
  score: {
    backgroundColor: { r: '0', g: '0', b: '0', a: '1' },
    fontColor: { r: '255', g: '255', b: '255', a: '1' },
  },
  fonts: {
    body: {
      family: StylingOptions.font.family.varelaRound.value,
      size: StylingOptions.font.size.regular.value,
      weight: StylingOptions.font.weight.regular.value,
      color: { r: '255', g: '255', b: '255', a: '1' },
    },
    heading1: {
      family: StylingOptions.font.family.breeSerif.value,
      size: StylingOptions.font.size.big.value,
      weight: StylingOptions.font.weight.regular.value,
      color: { r: '255', g: '255', b: '255', a: '1' },
    },
    heading2: {
      family: StylingOptions.font.family.breeSerif.value,
      size: StylingOptions.font.size.big.value,
      weight: StylingOptions.font.weight.regular.value,
      color: { r: '255', g: '255', b: '255', a: '1' },
    },
    button: {
      family: StylingOptions.font.family.varelaRound.value,
      size: StylingOptions.font.size.regular.value,
      weight: StylingOptions.font.weight.regular.value,
      color: { r: '255', g: '255', b: '255', a: '1' },
    },
  },
  step: {
    angle: StylingOptions.angle.noAngle.value,
    backgroundColor: { r: '26', g: '48', b: '80', a: '1' },
    textAlignment: 'left',
    speed: StylingOptions.speed.medium.value,
    boxShadow: {
      blur: StylingOptions.boxShadow.blur.regular.value,
      size: StylingOptions.boxShadow.size.regular.value,
      opacity: StylingOptions.boxShadow.opacity[30].value,
      color: { r: '0', g: '0', b: '0', a: '1' },
    },
    border: {
      radius: StylingOptions.border.shape.regular.value,
      width: StylingOptions.border.width.none.value,
      color: { r: '0', g: '0', b: '0', a: '1' },
    },
  },
  decisionTaken: {
    angle: StylingOptions.angle.noAngle.value,
    opacity: 0.7,
  },
  decision: {
    angle: StylingOptions.angle.noAngle.value,
    backgroundColor: { r: '37', g: '78', b: '135', a: '1' },
    boxShadow: {
      blur: StylingOptions.boxShadow.blur.regular.value,
      size: StylingOptions.boxShadow.size.regular.value,
      opacity: StylingOptions.boxShadow.opacity[30].value,
      color: { r: '0', g: '0', b: '0', a: '1' },
    },
    border: {
      radius: StylingOptions.border.shape.regular.value,
      width: StylingOptions.border.width.none.value,
      color: { r: '0', g: '0', b: '0', a: '1' },
    },
  },
  image: {
    angle: StylingOptions.angle.noAngle.value,
    border: {
      radius: StylingOptions.border.shape.regular.value,
      width: StylingOptions.border.width.none.value,
      color: { r: '0', g: '0', b: '0', a: '1' },
    },
  },
  video: {
    border: {
      radius: StylingOptions.border.shape.regular.value,
      width: StylingOptions.border.width.none.value,
      color: { r: '0', g: '0', b: '0', a: '1' },
    },
    playerControls: false,
    autoplay: true,
  },
  link: {
    fontColor: { r: '255', g: '255', b: '255', a: '1' },
    hoverFontColor: { r: '232', g: '232', b: '232', a: '1' },
  },
};

const padding = {
  regular: '0.5em',
};

export const stylingFontSizes = {
  small: {
    fontSize: 'calc(11px + .5vh) !important',
    lineHeight: '150%',
    padding: padding.regular,
  },
  regular: {
    fontSize: 'calc(11px + 1vh) !important',
    lineHeight: '150%',
    padding: padding.regular,
  },
  big: {
    fontSize: 'calc(11px + 2vh) !important',
    lineHeight: '120%',
    padding: padding.regular,
  },
  veryBig: {
    fontSize: 'calc(11px + 3vh) !important',
    lineHeight: '120%',
    padding: padding.regular,
  },
  giant: {
    fontSize: 'calc(11px + 5vh) !important',
    lineHeight: '100%',
    padding: padding.regular,
  },
};
