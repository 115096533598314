/*
  Plan rules documentation:

  None:
    User is a learner and does not have any project.

  Beginner:
    - Invite 50 learners
    - 1 game
    - Access basic features
    - Analytics & reporting

  Starter:
    - 250 monthly active learners
    - Build up to 3 games
    - Access all platform features
    - Analytics & reporting
    - Custom branding
    - Add teams

  Professional:
    - 750 monthly active learners
    - Unlimited number of games
    - Access all platform features
    - Analytics & reporting
    - Custom branding
    - Add teams
    - Technical Support

  Enterprise:
    - 2000 monthly active learners included
    - + £0.10 each additional active learner
    - Unlimited number of games
    - Access full platform
    - Analytics & reporting
    - Custom branding
    - Add teams
    - Custom domain
    - Technical support
    - Custom reporting
    - Custom authentication
*/

import { AvailableCurrencies } from '../Currency';

export type Price = {
  amount: number;
  currency: AvailableCurrencies.GBP;
};

export type ExtraLearnerPrice = Price;
export type PlanPrice = Price;

export enum Plan {
  None = 'none',
  Beginner = 'beginner',
  Starter = 'starter',
  Professional = 'professional',
  Enterprise = 'enterprise',
}

export type Rules = {
  maxProjects: number;
  maxLearners: number;
  price: PlanPrice;
  analytics: boolean;
  customBranding: boolean;
  teamsAvailable: boolean;
  techSupport: boolean;
  customDomains: boolean;
  customReport: boolean;
  customAuth: boolean;
  canPurchaseExtraLearner: boolean;
  extraLearnerPrice: ExtraLearnerPrice;
};

type PlanRules = {
  [x in Plan]: Rules;
};

export const planRules: PlanRules = {
  [Plan.None]: {
    maxProjects: 0,
    maxLearners: 0,
    price: {
      amount: 0.0,
      currency: AvailableCurrencies.GBP,
    },
    analytics: false,
    customBranding: false,
    teamsAvailable: false,
    techSupport: false,
    customDomains: false,
    customReport: false,
    customAuth: false,
    canPurchaseExtraLearner: false,
    extraLearnerPrice: {
      amount: 0.0,
      currency: AvailableCurrencies.GBP,
    },
  },
  [Plan.Beginner]: {
    maxProjects: 1,
    maxLearners: +Infinity,
    price: {
      amount: 0.0,
      currency: AvailableCurrencies.GBP,
    },
    analytics: true,
    customBranding: false,
    teamsAvailable: false,
    techSupport: false,
    customDomains: false,
    customReport: false,
    customAuth: false,
    canPurchaseExtraLearner: false,
    extraLearnerPrice: {
      amount: 0.0,
      currency: AvailableCurrencies.GBP,
    },
  },
  [Plan.Starter]: {
    maxProjects: 3,
    maxLearners: +Infinity,
    price: {
      amount: 195.0,
      currency: AvailableCurrencies.GBP,
    },
    analytics: true,
    customBranding: true,
    teamsAvailable: true,
    techSupport: false,
    customDomains: false,
    customReport: false,
    customAuth: false,
    canPurchaseExtraLearner: false,
    extraLearnerPrice: {
      amount: 0.0,
      currency: AvailableCurrencies.GBP,
    },
  },
  [Plan.Professional]: {
    maxProjects: +Infinity,
    maxLearners: +Infinity,
    price: {
      amount: 345.0,
      currency: AvailableCurrencies.GBP,
    },
    analytics: true,
    customBranding: true,
    teamsAvailable: true,
    techSupport: true,
    customDomains: false,
    customReport: false,
    customAuth: false,
    canPurchaseExtraLearner: false,
    extraLearnerPrice: {
      amount: 0.0,
      currency: AvailableCurrencies.GBP,
    },
  },
  [Plan.Enterprise]: {
    maxProjects: +Infinity,
    maxLearners: +Infinity,
    price: {
      amount: 595.0,
      currency: AvailableCurrencies.GBP,
    },
    analytics: true,
    customBranding: true,
    teamsAvailable: true,
    techSupport: true,
    customDomains: true,
    customReport: true,
    customAuth: true,
    canPurchaseExtraLearner: true,
    extraLearnerPrice: {
      amount: 0.1,
      currency: AvailableCurrencies.GBP,
    },
  },
};
