import { SnackbarProvider } from 'notistack';
import { SnackbarCloseButton } from 'components/SnackbarCloseButton';
import { themedMakeStyles } from './ThemeProvider';

const useStyles = themedMakeStyles(() => ({
  variantSuccess: {
    backgroundColor: 'rgb(34, 174, 173) !important',
  },
}));

export default ({ children }) => {
  const classes = useStyles();

  return (
    <SnackbarProvider classes={classes} maxSnack={1} action={() => <SnackbarCloseButton />}>
      {children}
    </SnackbarProvider>
  );
};
