import { generateTimestamp, Timestamp } from 'utils/DateUtils';

import Analytics from './Analytics';
import { Variables } from './Variable';

export class LearnerProgress {
  currentStepGroupId: number;
  currentPageId: any;
  currentScore: number;
}

export class LearnerStats {
  analytics = JSON.stringify(new Analytics()); // stringified Analytics
  score = 0;
  stat = 0;
  currentPositive = 0;
  currentNegative = 0;
  currentDecisions = 0;
  currentStepGroup = 0;
  currentStep = '';
  bestGradePercent = -1;
  progressHistory: LearnerProgress[] = [];
  highscore = 0;
  startedAt: Timestamp = generateTimestamp();
  finished = false;
  finishedAt: Timestamp = null;
  variables: Variables = {};

  finishGameplay(): number {
    this.finished = true;
    this.finishedAt = generateTimestamp();

    return this.finishedAt;
  }
}
