import React, { useState, FC } from 'react';
import { Button, DialogActions, DialogContent, DialogContentText, MobileStepper } from '@material-ui/core';

import { DialogTitle, Dialog } from 'components/Dialogs/Dialog';

import { useOnboardingDialogStyles } from './OnboardingDialog.styles';

export type OnboardingStep = {
  title: string;
  description: string;
  media?: any;
};

export type OnboardingDialogProps = {
  open: boolean;
  handleClose: () => void;
  steps: OnboardingStep[];
};

export const OnboardingDialog: FC<OnboardingDialogProps> = ({ steps, open, handleClose }) => {
  const classes = useOnboardingDialogStyles();
  const [activeStep, setActiveStep] = useState(0);

  const step = steps[activeStep];

  const goToNext = () => setActiveStep((prevStep) => prevStep + 1);

  const goToStart = () => setActiveStep(0);

  return (
    <Dialog
      open={open}
      aria-labelledby="onboarding-dialog-title"
      aria-describedby="onboarding-dialog-description"
      disableBackdropClick
      TransitionProps={{
        onExited: goToStart,
      }}
    >
      <DialogTitle id="onboarding-dialog-title">{step.title}</DialogTitle>

      <DialogContent>
        <DialogContentText id="onboarding-dialog-description">{step.description}</DialogContentText>
      </DialogContent>

      {step.media}

      <div className={classes.footerActions}>
        <MobileStepper
          variant="dots"
          steps={steps.length}
          position="static"
          activeStep={activeStep}
          backButton={null}
          nextButton={null}
        />
        <DialogActions>
          {activeStep === steps.length - 1 ? (
            <>
              <Button color="primary" onClick={goToStart}>
                Start Again
              </Button>
              <Button color="primary" variant="contained" autoFocus onClick={handleClose}>
                Finish
              </Button>
            </>
          ) : (
            <>
              <Button color="primary" onClick={handleClose}>
                Skip
              </Button>
              <Button color="primary" variant="contained" autoFocus onClick={goToNext}>
                Next
              </Button>
            </>
          )}
        </DialogActions>
      </div>
    </Dialog>
  );
};
