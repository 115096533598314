import { generateTimestamp, Timestamp } from 'utils/DateUtils';

import { Plan } from './UserPlan';
import Theme from './Theme';

export class UserProgress {
  currentStepGroupId: number;
  currentPageId: number;
}

export enum UserStatus {
  None = 'none',
  Invited = 'invited',
  Requested = 'requested',
  Active = 'active',
  Inactive = 'inactive',
}

export enum UserRoles {
  Owner = 'owner',
  Editor = 'editor',
  Viewer = 'viewer',
}

type UserMeta = {
  firstTimeFlowchart: boolean;
};

export default class User {
  plan = Plan.None;
  email = '';
  uid = '';
  lastActive: Timestamp = generateTimestamp();
  firstName = '';
  lastName = '';
  status: UserStatus = UserStatus.None;
  projects: Record<string, string> = {};
  invitedProjects: Record<string, string> = {};
  themes: Array<Theme> = [];
  meta: UserMeta = {
    firstTimeFlowchart: true,
  };
  fonts?: {
    [key: string]: { name: string; value: string };
  } = {};
}
