import { MouseEvent, useState, FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { Icon } from 'react-icons-kit';
import { ic_vertical_align_top as ICTop } from 'react-icons-kit/md/ic_vertical_align_top';
import { ic_vertical_align_center as ICCenter } from 'react-icons-kit/md/ic_vertical_align_center';
import { ic_vertical_align_bottom as ICBottom } from 'react-icons-kit/md/ic_vertical_align_bottom';

import SettingsStore from 'stores/settingsStore';

export enum PAGE_POSITIONS {
  top = 'top',
  middle = 'middle',
  bottom = 'bottom',
}

const useStyles = makeStyles((theme) => ({
  toggleContainer: {
    margin: theme.spacing(2, 0),
  },
}));

export interface SettingsStepPositionToggleComponentProps {
  settingsStore: SettingsStore;
}

export const SettingsStepPositionToggleComponent: FC<SettingsStepPositionToggleComponentProps> = ({
  settingsStore,
}) => {
  const classes = useStyles();
  const [alignment, setAlignment] = useState<string | null>(settingsStore.settings.stepAlignment);

  const handleAlignment = (_event: MouseEvent<HTMLElement>, newAlignment: string | null) => {
    setAlignment(newAlignment);
    settingsStore.setSettingsProperty('stepAlignment', newAlignment);
  };

  return (
    <div className={classes.toggleContainer}>
      <ToggleButtonGroup
        value={alignment}
        exclusive
        size="small"
        onChange={handleAlignment}
        aria-label="text alignment"
      >
        <ToggleButton value={PAGE_POSITIONS.bottom} aria-label={PAGE_POSITIONS.bottom}>
          <Icon size={24} icon={ICBottom} />
        </ToggleButton>
        <ToggleButton value={PAGE_POSITIONS.middle} aria-label={PAGE_POSITIONS.middle}>
          <Icon size={24} icon={ICCenter} />
        </ToggleButton>
        <ToggleButton value={PAGE_POSITIONS.top} aria-label={PAGE_POSITIONS.top}>
          <Icon size={24} icon={ICTop} />
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};
