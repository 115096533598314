import { auth, firebase } from './firebaseUtil';

import { Providers } from 'objects/Providers';

export const userUID = () => auth?.currentUser?.uid || '';

// Sign Up
export const doCreateUserWithEmailAndPassword = (email, password) =>
  auth.createUserWithEmailAndPassword(email, password);

const authWithAutoLogout = (callback) => {
  return firebase
    .auth()
    .setPersistence(firebase.auth.Auth.Persistence.SESSION)
    .then(() => callback);
};

// Sign In
export const doSignInWithEmailAndPassword = (email, password, autoLogout = false) => {
  const callback = auth.signInWithEmailAndPassword(email, password);
  if (autoLogout) {
    return authWithAutoLogout(callback);
  }
  return callback;
};

export const doSignInWithPopup = (provider, autoLogout = false) => {
  const callback = auth.signInWithPopup(provider);
  if (autoLogout) {
    return authWithAutoLogout(callback);
  }
  return callback;
};

export const doSignInAnonymously = (autoLogout = false) => {
  if (autoLogout) {
    return authWithAutoLogout(auth.signInAnonymously());
  }
  return auth.signInAnonymously();
};

// Sign out
export const doSignOut = () => auth.signOut();

// Password Reset
export const doPasswordReset = (email) => auth.sendPasswordResetEmail(email);

// Password Change
export const doPasswordUpdate = (password) => (auth?.currentUser ? auth.currentUser.updatePassword(password) : '');

export const providers = {
  [Providers.Google]: new firebase.auth.GoogleAuthProvider(),
  [Providers.Facebook]: new firebase.auth.FacebookAuthProvider(),
  [Providers.Twitter]: new firebase.auth.TwitterAuthProvider(),
  [Providers.Microsoft]: new firebase.auth.OAuthProvider('microsoft.com'),
  [Providers.Anonymous]: () => null,
};
