import { DBMap } from './DBStructures';
import { DBProject } from './DBProject';
import Settings from '../../objects/Settings';

export class DBSettings extends DBMap<Settings> {
  public projectUID: string;

  constructor(projectUID: string) {
    super();
    this.projectUID = projectUID;
    const projectRef = new DBProject(projectUID).ref;
    this.ref = projectRef.child('settings');
  }

  setStatProperty = <K extends keyof Settings['impactStatValues']>(
    propertyKey: K,
    propertyValue: Settings['impactStatValues'][K],
    callback?,
  ) => this.setProperty(`impactStatValues/${propertyKey}` as any, propertyValue as any, callback);

  setText = <K extends keyof Settings['text']>(propertyKey: K, propertyValue: Settings['text'][K], callback?) =>
    this.setProperty(`text/${propertyKey}` as any, propertyValue as any, callback);

  setCustomThemeStyles = <K extends keyof Settings['customThemeStyles']>(
    propertyKey: K,
    propertyValue: Settings['customThemeStyles'][K],
    callback?,
  ) => this.setProperty(`customThemeStyles/${propertyKey}` as any, propertyValue as any, callback);

  setLRS = <K extends keyof Settings['lrs']>(propertyKey: K, propertyValue: Settings['lrs'][K], callback?) =>
    this.setProperty(`lrs/${propertyKey}` as any, propertyValue as any, callback);
}
