import { FC } from 'react';

import { useStores } from 'stores/useStores';
import { useGameTheme } from 'features/Game/useGameTheme';
import { EffectText } from 'components/EffectText';
import Decision, { DecisionOutcome } from 'objects/Decision';

import {
  Positive5,
  Positive4,
  Positive3,
  Positive2,
  Positive1,
  Neutral,
  Negative1,
  Negative2,
  Negative3,
  Negative4,
  Negative5,
  Tick,
  Cross,
} from 'components/Icons/ImpactIcons';
import { StoryTextBoxTimings } from 'components/WindupStorytelling/States/StoryTextBox';
import { themedMakeStyles } from 'providers/ThemeProvider';

export type GameDecisionTakenProps = {
  decision: Decision;
  onFinished: () => void;
};

const useDecisionTakenStyles = themedMakeStyles<{ decision: Decision }>(() => ({
  decisionTakenNegative: {
    animation: '$shake 250ms',
    animationIterationCount: 3,
  },
  '@keyframes shake': {
    '0%': { transform: 'translate(1px, 1px) rotate(0deg)' },
    '10%': { transform: 'translate(-1px, -2px) rotate(-1deg)' },
    '20%': { transform: 'translate(-3px, 0px) rotate(1deg)' },
    '30%': { transform: 'translate(3px, 2px) rotate(0deg)' },
    '40%': { transform: 'translate(1px, -1px) rotate(1deg)' },
    '50%': { transform: 'translate(-1px, 2px) rotate(-1deg)' },
    '60%': { transform: 'translate(-3px, 1px) rotate(0deg)' },
    '70%': { transform: 'translate(3px, 1px) rotate(-1deg)' },
    '80%': { transform: 'translate(-1px, -1px) rotate(1deg)' },
    '90%': { transform: 'translate(1px, 2px) rotate(0deg)' },
    '100%': { transform: 'translate(1px, -2px) rotate(-1deg)' },
  },
}));

const Icons = {
  Emoji: {
    Positive: {
      Tiny: Positive1,
      Small: Positive2,
      Medium: Positive3,
      Large: Positive4,
      Huge: Positive5,
    },
    Negative: {
      None: Neutral,
      Tiny: Negative1,
      Small: Negative2,
      Medium: Negative3,
      Large: Negative4,
      Huge: Negative5,
    },
  },
  TickCross: {
    Positive: {
      Tiny: Tick,
      Small: Tick,
      Medium: Tick,
      Large: Tick,
      Huge: Tick,
    },
    Negative: {
      None: null,
      Tiny: Cross,
      Small: Cross,
      Medium: Cross,
      Large: Cross,
      Huge: Cross,
    },
  },
};

export enum ImpactIconType {
  None = 'None',
  Emoji = 'Emoji',
  TickCross = 'TickCross',
}

const getImpactIcon = (decision: Decision, iconType: ImpactIconType) => {
  if (iconType !== ImpactIconType.None && decision.outcome && decision.impact) {
    const outcome = Icons[iconType][decision.outcome];
    return (outcome && outcome[decision.impact]) || '';
  }
  return '';
};

export const GameDecisionTaken: FC<GameDecisionTakenProps> = ({ decision, onFinished }) => {
  const gameTheme = useGameTheme();
  const text: String = decision.decision;
  const classes = useDecisionTakenStyles({ decision });

  const { settingsStore } = useStores();
  const {
    settings: { impactIconType },
  } = settingsStore;

  const ImpactIcon = getImpactIcon(decision, impactIconType);

  if (decision?.hidden) return null;

  return (
    <div
      className={decision.outcome === DecisionOutcome.Negative ? classes.decisionTakenNegative : null}
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', float: 'left' }}
    >
      <EffectText
        onFinished={onFinished}
        inEffectStyle={{ transformOrigin: '150% 50% 0' }}
        pace={StoryTextBoxTimings.DecisionTakenPace}
      >
        <p className={gameTheme.classes.decisionTaken}>
          {decision.outcome !== DecisionOutcome.None && <ImpactIcon />}
          {text}
        </p>
      </EffectText>
    </div>
  );
};
