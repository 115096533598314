import { DBList } from './DBStructures';
import { Member } from '../../objects/Project';
import { DBProject } from './DBProject';

export class DBMembers extends DBList<Member> {
  public projectUID: string;

  constructor(projectUID: string) {
    super();
    this.projectUID = projectUID;
    const projectRef = new DBProject(projectUID).ref;
    this.ref = projectRef.child('members');
  }

  getMemberRole = (userUID: string, callback?) => this.ref.child(userUID).child('role').once('value', callback);

  // getMemberRole = async (userUID: string) => {
  //   // const activeID = await this.getActiveID();

  //   const memberRole: string = await this.ref
  //     .child(userUID)
  //     .once('value')
  //     .then((snap) => snap.val());

  //   return memberRole;
  // };
}
