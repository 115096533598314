import { generateTimestamp } from 'utils/DateUtils';
import { v4 as uuidv4 } from 'uuid';

import Step, { Steps, StepType } from './Step';
import { FlowchartConfiguration } from './FlowchartConfiguration';

export enum ChapterStates {
  GAME_WIN = 'GAME_WIN',
  GAME_FAIL = 'GAME_FAIL',
}

function generateInitialSteps() {
  const startStep = new Step();
  const secondStep = new Step();

  startStep.type = StepType.Start;
  const firstDecision = startStep.decisions[0];
  firstDecision.jumpChapter = this.id;
  firstDecision.jumpPage = secondStep.id;
  firstDecision.hidden = true;
  startStep.decisions = [firstDecision];

  this.storys[startStep.id] = startStep;
  this.storys[secondStep.id] = secondStep;
  this.firstStep = startStep.id;
}

export default class Chapter {
  id = uuidv4();
  name = 'My first process';
  storys: Steps = {};
  firstStep = '';
  updatedAt?: number = generateTimestamp();
  flowchartConfiguration: FlowchartConfiguration = null;

  constructor() {
    generateInitialSteps.bind(this)();
  }

  public static getIndexFromID(chapters: Chapter[], id: number | string): number {
    return chapters.findIndex((chapter) => chapter.id.toString() === id.toString());
  }
}
