import story from './story';

export default {
  type: 'object',
  required: ['name', 'storys'],
  properties: {
    id: { type: ['string', 'number'] },
    name: { type: 'string' },
    storys: {
      type: 'object',
      items: { ...story },
    },
    updatedAt: { type: 'number' },
  },
};
