import { firebase } from '@firebase/firebaseUtil';

type PropertyKey<T> = keyof T & string;

type KeyValueFn<T, Return> = <K extends keyof T>(
  propertyKey: K & string,
  propertyValue: T[K],
  ...args: any[]
) => Return;

type FirebaseSetPropertyFn<T> = KeyValueFn<T, Promise<any>>;

type FirebasePushPropertyFn<T> = <K extends keyof T>(
  propertyKey: K & string,
  propertyValue: any,
  ...args: any[]
) => firebase.database.ThenableReference;

export class DBMap<T> {
  public ref: firebase.database.Reference;

  get = (callback?) => this.ref.once('value', callback);

  set = (values: T, callback?) => this.ref.set(values, callback);

  update = (values: Partial<T>, callback?) => this.ref.update(values, callback);

  remove = (callback?) => this.ref.remove(callback);

  getProperty = (propertyKey: PropertyKey<T>, callback?) => this.ref.child(propertyKey).once('value', callback);

  setProperty: FirebaseSetPropertyFn<T> = (propertyKey, propertyValue, callback?) =>
    this.ref.child(propertyKey).set(propertyValue, callback);

  removeProperty = (propertyKey: PropertyKey<T>, callback?) => this.ref.child(propertyKey).remove(callback);

  pushToProperty: FirebasePushPropertyFn<T> = (propertyKey, propertyValue, callback?) =>
    this.ref.child(propertyKey).push(propertyValue, callback);
}
