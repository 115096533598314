import React, { FC } from 'react';
import { DialogTitle as MuiDialogTitle, DialogTitleProps as MuiDialogTitleProps, Typography } from '@material-ui/core';

export type DialogTitleProps = MuiDialogTitleProps;

export const DialogTitle: FC<DialogTitleProps> = ({ id, children }) => (
  <MuiDialogTitle id={id} disableTypography>
    <Typography variant="h5">{children}</Typography>
  </MuiDialogTitle>
);
