import { FC } from 'react';
import { inject, Provider } from 'mobx-react';

import defaultStore, { RootStore } from '../stores';
import { IStoresToProps } from 'mobx-react/dist/types/IStoresToProps';

export function mobxInject<S extends RootStore>(fn: IStoresToProps<S, {}, {}, {}>) {
  return inject(fn);
}

const MobXProvider: FC<{ store?: RootStore }> = ({ children, store = defaultStore }) => (
  <Provider {...store}>{children}</Provider>
);

export default MobXProvider;
