import { observable, action, makeObservable } from 'mobx';

class SessionStore {
  authUser = null;

  // public rootStore;
  constructor(rootStore) {
    makeObservable(this, {
      authUser: observable,
      setAuthUser: action,
    });

    this.rootStore = rootStore;
  }

  setAuthUser = (authUser) => {
    this.authUser = authUser;
  };
}

export default SessionStore;
