enum States {
  link = 'Link',
  gameOverFail = 'Game Over Fail',
  restart = 'Restart Game',
  restartProcess = 'Restart Process',
  nextChapter = 'Next Chapter',
  gameOverSuccess = 'Game Over Success',
  backAStep = 'Previous Step',
  nextPage = 'Next Step',
  signOut = 'Sign Out',
  jumpToStep = 'Jump to Step',
  jumpToProcess = 'Jump to Process',
  acceptPrivacyPolicy = 'Accept Privacy Policy',
  refusePrivacyPolicy = 'Refuse Privacy Policy',
  NA = 'NA',
}

export default States;
