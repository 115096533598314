import AppConfig from 'config/AppConfig';

export const Routes = {
  SignUp: '/signup',
  SignIn: '/signin',
  Auth: '/auth',
  PasswordForget: '/forgot-password',
  Home: '/',
  Play: '/',
  Account: '/account',
  Edit: '/edit',
  OldEditor: '/legacy_editor',
  Learners: '/learners',
  NoAccess: '/noaccess',
  Teams: '/teams',
  Styling: '/styling',
  KnowledgeDashboard: '/measure',
  View: '/view',
  Settings: '/settings',
  SelectTeam: '/select-your-team',
  NotFound: '/404',
  Loading: '/loading',
};

export const getPageName = (route: string) => {
  let pageName = '';
  switch (route) {
    case Routes.Learners:
      pageName = 'Learners';
      break;
    case Routes.Teams:
      pageName = 'Teams';
      break;
    case Routes.Edit:
      pageName = 'Edit';
      break;
    case Routes.KnowledgeDashboard:
      pageName = 'Behaviour Intelligence';
      break;
    case Routes.Settings:
      pageName = 'Settings';
      break;
    case Routes.Styling:
      pageName = 'Styling';
      break;
    case Routes.Home:
      pageName = AppConfig.gameText.title;
      break;
    default:
      break;
  }
  return pageName;
};
