import axios from 'axios';

export interface Requester {
  get: (url: string, config?: any) => Promise<any>;
  post: (url: string, data?: any, config?: any) => Promise<any>;
  put: (url: string, data?: any, config?: any) => Promise<any>;
  patch: (url: string, data?: any, config?: any) => Promise<any>;
  delete: (url: string, config?: any) => Promise<any>;
}

export type HttpRequesterConfig = {
  baseURL: string;
  timeout: number;
  headers: any;
};

export default class PrivateHttpRequester {
  private config: HttpRequesterConfig = {
    baseURL: '',
    timeout: 60000,
    headers: {},
  };

  constructor({ baseURL, timeout, headers }: HttpRequesterConfig) {
    this.config.baseURL = baseURL;
    this.config.timeout = timeout;
    this.config.headers = headers;
  }

  get requester(): Requester {
    const requester = axios.create(this.config);
    requester.interceptors.request.use(
      function (config) {
        // Do something before request is sent
        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      },
    );

    // Add a response interceptor
    requester.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response.data;
      },
      function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
      },
    );

    return requester;
  }

  get(url: string, config?: any): Promise<any> {
    return this.requester.get(url, config);
  }

  post(url: string, data?: any, config?: any): Promise<any> {
    return this.requester.post(url, data, config);
  }

  put(url: string, data?: any, config?: any): Promise<any> {
    return this.requester.put(url, data, config);
  }

  patch(url: string, data?: any, config?: any): Promise<any> {
    return this.requester.patch(url, data, config);
  }

  delete(url: string, config?: any): Promise<any> {
    return this.requester.delete(url, config);
  }
}
