import { firebase } from '@firebase/firebaseUtil';
import { DBList } from './DBStructures';
import { DBProject } from './DBProject';
import Chapter from '../../objects/Chapter';

export class DBStepGroups extends DBList<Chapter> {
  public projectUID: string;
  private projectRef: firebase.database.Reference;

  constructor(projectUID: string) {
    super();
    this.projectUID = projectUID;
    this.projectRef = new DBProject(projectUID).ref;
    this.ref = this.projectRef.child('chapters'); // FIREBASE_TODO: change to processes
  }
}
