import { useEffect, useState, useRef } from 'react';
import ScoreUtils from 'utils/ScoreUtils';

export const useBonusTimer = () => {
  const [counter, setCounter] = useState(ScoreUtils.BONUS_COUNTDOWN);
  const id = useRef(null);
  const clear = () => {
    window.clearInterval(id.current);
  };
  const getSpeedBonus = () => {
    return counter;
  };

  useEffect(() => {
    id.current = window.setInterval(() => {
      setCounter((time) => time - 1);
    }, 500);
    return () => {
      clear();
    };
  }, []);

  useEffect(() => {
    if (counter === 0) {
      clear();
    }
  }, [counter]);

  return {
    getSpeedBonus,
    stopSpeedBonus: clear,
  };
};
