import { DBUserInvite } from '@firebase/dbUtil';
import { auth } from '@firebase/firebaseUtil';
import { makeAutoObservable } from 'mobx';

import { RootStore } from './';

class InvitesStore {
  rootStore: RootStore;
  invites: Record<string, string> = {};
  isLoaded = false;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    this.invites = {};
  }

  get dbRef(): DBUserInvite {
    return new DBUserInvite(auth.currentUser.email);
  }

  async load() {
    try {
      this.invites = (await this.dbRef.all().catch(() => ({}))) || {};
      this.isLoaded = true;
    } catch {
      this.invites = {};
      this.isLoaded = true;
    }
  }

  async refuseInvite(projectUID: string) {
    this.removeInviteEntry(projectUID);
    return await this.dbRef.refuseInvite(projectUID);
  }

  async acceptInvite(projectUID: string) {
    this.removeInviteEntry(projectUID);
    return await this.dbRef.acceptInvite(projectUID);
  }

  private removeInviteEntry(projectUID: string): void {
    const prev = this.invites;
    delete prev[projectUID];
    this.invites = prev;
  }
}

export default InvitesStore;
