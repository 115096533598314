import { makeStyles, Theme } from '@material-ui/core';

export const useMaintenancePageStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#f2f3f7',
    width: '100vw',
    height: '100vh',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  pageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
    alignItems: 'center',
    maxWidth: '700px',
  },
  infosWrapper: {
    maxWidth: '390px',
    padding: theme.spacing(4),
  },
  title: {
    fontWeight: 'bold',
  },
  description: {
    marginTop: theme.spacing(2),
  },
  buttonsWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(5),
    '& a': {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  divider: {
    margin: theme.spacing(1, 0),
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(0, 1),
    },
  },
}));
