import { observer } from 'mobx-react';
import { useStores } from 'stores/useStores';

export const ModalContainer = observer(() => {
  const { ui } = useStores();

  const { Component } = ui.modal;

  if (!Component) return null;

  return <Component />;
});
