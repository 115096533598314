import { makeAutoObservable } from 'mobx';
import { firebase, auth } from '@firebase/firebaseUtil';
import { DBUsers, DBMembers, DBProject, DBInvites } from '@firebase/dbUtil';

import { Member } from 'objects/Project';
import { UserRoles } from 'objects/User';
import { sendInviteMemberMail } from 'mailer-client/Mailer';

import { RootStore } from './';
import { encryptEmail } from 'utils/StringUtils';

class MembersStore {
  rootStore: RootStore;
  members: Record<string, Member> = {};
  isLoaded = false;
  isAdmin = false;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    this.members = {};
  }

  get projectUID() {
    return this.rootStore.projectStore.uid;
  }

  get dbRef(): DBMembers {
    return new DBMembers(this.projectUID);
  }

  get invitesDbRef(): DBInvites {
    return new DBInvites();
  }

  isOwner(): boolean {
    if (this.isAdmin) return true;

    try {
      const uid = encryptEmail(auth.currentUser.email);
      return this.members[uid].role === UserRoles.Owner;
    } catch {
      return false;
    }
  }

  isMember(): boolean {
    try {
      const uid = encryptEmail(auth.currentUser.email);
      return !!this.members[uid];
    } catch {
      return false;
    }
  }

  hasAccess(): boolean {
    return this.isMember() || this.isOwner();
  }

  get currentMember(): Member {
    const uid = encryptEmail(auth.currentUser.email);
    return new Member(this.members[uid]);
  }

  get currentRole(): UserRoles {
    try {
      const uid = encryptEmail(auth.currentUser.email);
      return this.members[uid].role;
    } catch {
      return UserRoles.Viewer;
    }
  }

  async load() {
    this.isAdmin = await new DBProject(this.projectUID).isOwner(auth.currentUser.uid);

    await this.dbRef
      .getAll(async (snapshot) => {
        this.members = snapshot.val() || {};
        this.isLoaded = true;
      })
      .catch(async () => {
        this.members = {};
        this.isLoaded = true;
      });
  }

  private sendInviteOnFirebase(email: string) {
    return this.invitesDbRef.inviteUser(email, this.projectUID);
  }

  async addEditor(email: string, params: Partial<Member>, callback?) {
    const { key: userUID } = (await this.doesMemberExist(email)) || { key: null };
    const member = new Member({ ...params, email, role: UserRoles.Editor, userUID });
    const uid = encryptEmail(email);
    this.members[uid] = member;
    // if (member.userUID) await this.addProjectOnMember(member.userUID);
    sendInviteMemberMail({
      email,
      userUID,
      projectUID: this.projectUID,
      projectName: this.rootStore.projectStore.name,
      adminEmail: auth.currentUser.email,
      adminName: auth.currentUser.displayName,
      permission: 'edit',
    });
    this.sendInviteOnFirebase(email);
    return this.dbRef.setItem(uid, member, callback);
  }

  async addViewer(email: string, params: Partial<Member>, callback?) {
    const { key: userUID } = (await this.doesMemberExist(email)) || { key: null };
    const member = new Member({ ...params, email, role: UserRoles.Viewer, userUID });
    const uid = encryptEmail(email);
    this.members[uid] = member;
    // if (member.userUID) await this.addProjectOnMember(member.userUID);
    sendInviteMemberMail({
      email,
      userUID,
      projectUID: this.projectUID,
      projectName: this.rootStore.projectStore.name,
      adminEmail: auth.currentUser.email,
      adminName: auth.currentUser.displayName,
      permission: 'view',
    });
    this.sendInviteOnFirebase(email);
    return this.dbRef.setItem(uid, member, callback);
  }

  async addOwner(email: string, params: Partial<Member>, callback?) {
    const { key: userUID } = (await this.doesMemberExist(email)) || { key: null };
    const member = new Member({ ...params, email, role: UserRoles.Owner, userUID });
    const uid = encryptEmail(email);
    this.members[uid] = member;
    // if (member.userUID) await this.addProjectOnMember(member.userUID);
    return this.dbRef.setItem(uid, member, callback);
  }

  private async doesMemberExist(email: string): Promise<{ key: string; data: any }> {
    return await new DBUsers().getUserByEmail(email);
  }

  async updateMember(email: string, params: Partial<Member>, callback?) {
    const uid = encryptEmail(email);
    const prev = this.findMember(email);
    const member = new Member({ ...prev, ...params });
    this.members[uid] = member;
    return this.dbRef.updateItem(uid, member, callback);
  }

  async removeMember(email: string): Promise<void> {
    const projectUID = this.projectUID;
    const member = this.findMember(email);
    const uid = encryptEmail(email);
    if (member.role !== UserRoles.Owner) {
      const dbRef = new DBMembers(projectUID);
      await dbRef.removeItem(uid);
      delete this.members[uid];
      await this.removeProjectFromMember(member.userUID, projectUID);
    }
  }

  async leaveProject(): Promise<void> {
    const { email } = auth.currentUser;
    await this.removeMember(email);
  }

  private findMember(email: string): Member {
    const uid = encryptEmail(email);
    return this.members[uid];
  }

  private dbUserRef(userUID: string): firebase.database.Reference {
    return new DBUsers().ref.child(userUID);
  }

  async addProjectOnMember(userUID: string) {
    // Dispatch email
    this.dbUserRef(userUID).child('invitedProjects').child(this.projectUID).set(this.projectUID);
  }

  async removeProjectFromMember(userUID: string, projectUID: string) {
    this.dbUserRef(userUID).child('invitedProjects').child(projectUID).remove();
  }

  async updateCurrentMemberAction(params: Partial<Member>) {
    return await this.updateMember(this.currentMember.email, params);
  }

  // private addMemberOnProject(uid: string, member: Member, callback?) {
  //   try {
  //     return this.dbRef.updateItem(uid, member, callback);
  //   } catch {
  //     new DBProject(this.projectUID).setProperty('members', { [uid]: member }, callback);
  //   }
  // }
}

export default MembersStore;
