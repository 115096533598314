export class ExportFileService {
  public async asJSON(obj): Promise<Blob> {
    return new Blob([JSON.stringify(obj, null, 2)], { type: 'application/json' });
  }

  public sendFile(blob: Blob, filename: string): void {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  public async downloadAsJSON(obj, filename: string) {
    try {
      const json = await this.asJSON(obj);
      this.sendFile(json, filename);
    } catch (e) {
      console.log(e);
    }
  }
}
