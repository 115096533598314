import { DBList } from './DBStructures';
import { ActiveUser } from '../../objects/Project';
import { DBProject } from './DBProject';

export class DBActiveUsers extends DBList<ActiveUser> {
  public projectUID: string;

  constructor(projectUID: string) {
    super();
    this.projectUID = projectUID;
    const projectRef = new DBProject(projectUID).ref;
    this.ref = projectRef.child('activeUsers');
  }
}
