import { v4 as uuidv4 } from 'uuid';
import { ImpactOptions } from './Impact';

export enum Movement {
  Continue = 'Continue',
  Move = 'Move',
  Stay = 'Stay',
}

export enum DecisionOutcome {
  None = 'None',
  Positive = 'Positive',
  Negative = 'Negative',
}

export default class Decision {
  id = uuidv4();
  decision = 'Continue';
  impact = ImpactOptions.tiny;
  outcome: DecisionOutcome = DecisionOutcome.None;
  jumpChapter = '-1';
  jumpPage = '-1';
  move = Movement.Continue;
  linkURL?: string = '';
  termsAcceptance?: string = '';
  showPlayerStats = false;
  hidden = false;
}
