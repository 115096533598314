import { FC, useEffect, useState } from 'react';
import { Fade } from '@material-ui/core';
import clsx from 'clsx';

import { DecodedQuillWindupText } from 'components/QuillComponent';
import { GameDecisionButton } from 'components/GameDecisionButton';
import { GameDecisionTaken } from 'components/GameDecisionTaken';
import StylingOptions from 'config/StylingOptions';
import { useGameContext } from 'features/Game/GameContext';
import { useGameTheme } from 'features/Game/useGameTheme';
import { useStores } from 'stores/useStores';
import { themedMakeStyles } from 'providers/ThemeProvider';
import Decision, { DecisionOutcome } from 'objects/Decision';
import { Impact } from 'objects/Impact';
import { StepAlignmentOptions } from 'objects/Step';
import { CountUp } from 'components/CountUp';
import { getIcon } from 'components/ScoreContainer/getIcon';
import Helpers from 'tools/Helpers';
import { useBonusTimer } from 'features/Game/useBonusTimer';
import ScoreUtils from 'utils/ScoreUtils';
import { ScoreBonus } from './ScoreBonus';

interface StoryTextBoxProps {
  readonly text: string;
  readonly decisions: Partial<Decision>[];
  readonly onDecisionSelect: any;
  readonly below?: boolean;
  readonly avatar?: string;
  readonly textAlignment?: StepAlignmentOptions;
  readonly fullscreen?: boolean;
}

const alignments = {
  left: 'flex-start',
  right: 'flex-end',
  center: 'center',
  default: 'flex-start',
};

const textAlignments = {
  right: '-webkit-right',
  center: '-webkit-center',
};

const useStoryTextBoxStyles = themedMakeStyles<{ avatar: string; alignment?: StepAlignmentOptions }>(() => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: ({ alignment = 'left' }) => alignments[alignment],
    // maxHeight: '0',
    // overflowY: 'hidden',
    webkitTransition: 'max-height 3s ease-out',
    mozTransition: 'max-height 3s ease-out',
    transition: 'max-height 3s ease-out',
    marginBottom: '5vh',
    textAlign: ({ alignment = 'left' }) => textAlignments[alignment],
  },
  decisionsOuterContainer: {
    // display: 'flex',
    // flexDirection: 'column',
    // alignItems: ({ alignment = 'left' }) => alignments[alignment],
    display: 'flex',
    flexDirection: 'column',
    alignItems: ({ alignment = 'left' }) => alignments[alignment],
    textAlign: ({ alignment = 'left' }) => alignment,
  },
  decisionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: ({ alignment = 'left' }) => alignments[alignment],
    justifyContent: ({ alignment = 'left' }) => alignments[alignment],
    flexWrap: 'wrap',
    textAlign: ({ alignment = 'left' }) => alignment,
  },
  decisionsSelected: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    alignItems: ({ alignment = 'left' }) => alignments[alignment],
    textAlign: ({ alignment = 'left' }) => alignment,
  },
  container: {
    display: 'flex',
    flexDirection: ({ alignment }) =>
      alignment === 'center' ? 'column' : alignment === 'right' ? 'row-reverse' : 'row',
    alignItems: ({ alignment }) => (alignment === 'center' ? alignment : 'flex-end'),
  },
  textContainer: {
    textAlign: ({ alignment = 'left' }) => alignment,
  },
  avatar: {
    width: 100,
    height: 100,
    position: 'inherit',
    top: 50,
    zIndex: 2,
    overflow: 'hidden',
    borderRadius: '0.5em',
    marginBottom: '8px',
    marginLeft: ({ alignment }) => (alignment === 'right' ? '8px' : '0'),
  },
  textWithAvatar: {
    minHeight: ({ avatar }) => (avatar ? 76 : 'auto'),
    paddingLeft: 12,
    fontStyle: 'italic',
    textAlign: ({ alignment = 'left' }) => alignment,
  },
}));

export enum StatesIDS {
  error = 'error',
  view_score = 'view_score',
  play_again = 'play_again',
  view_leaderboard = 'view_leaderboard',
}

export enum StoryTextBoxTimings {
  DefaultTextPace = 10, // Default speed of letters in steps
  DialogTextPace = 30, // Speed of letters in dialog steps
  DecisionTakenPace = 10, // Speed of letters in decision taken
  NoDecisionDelay = 500, // Steps that have no decision will have this delay after them
  StatCountUpSpeed = 1.5, // Speed the Stat count up takes
}

export const StoryTextBox: FC<StoryTextBoxProps> = ({
  children,
  avatar,
  text,
  decisions,
  onDecisionSelect,
  below = false,
  fullscreen = false,
  ...props
}) => {
  const {
    styleThemes: {
      activeStyleTheme: {
        step: { textAlignment },
      },
    },
  } = useStores();
  const alignment = props.textAlignment || textAlignment;
  const [showAvailableDecisions, setShowAvailableDecisions] = useState(false);
  const [stepMaxHeight, setStepMaxHeight] = useState<number>(0);
  const [revealStat, setRevealStat] = useState(false);
  const [revealBonus, setRevealBonus] = useState(false);
  const [decisionSelected, setDecisionSelected] = useState<Decision>(null);
  const [avatarIsLoaded, setAvatarLoaded] = useState<boolean>(false);
  const { process } = useGameContext();
  const classes = useStoryTextBoxStyles({ avatar, alignment });
  const firstDecision = decisions[0] as Decision;
  const isOrphanDecision = firstDecision.hidden;
  const { getSpeedBonus, stopSpeedBonus } = useBonusTimer();

  const { classes: gameClasses } = useGameTheme();
  const {
    settingsStore: { settings },
    styleThemes: {
      activeStyleTheme: {
        step: { speed: pace },
      },
    },
  } = useStores();

  const { prefix, suffix } = settings;

  useEffect(() => {
    setStepMaxHeight(1000);
  }, []);

  const timeoutDecision = () => {
    setTimeout(() => {
      setDecisionSelected({ ...firstDecision, id: 0 });
      onDecisionSelect({ ...firstDecision, id: 0 });
    }, StoryTextBoxTimings.NoDecisionDelay);
  };

  const onTextFinished = () => {
    if (isOrphanDecision) {
      timeoutDecision();
    } else {
      setShowAvailableDecisions(true);
    }
  };

  const onDecisionTakenComplete = () => {
    if (showStat) {
      setRevealStat(true);
    } else {
      onDecisionSelect(decisionSelected);
    }
    if (showBonus) {
      stopSpeedBonus();
      setRevealBonus(true);
    }
  };

  const showBonus = decisionSelected && decisionSelected.outcome === DecisionOutcome.Positive && getSpeedBonus() > 0;
  const showStat =
    decisionSelected &&
    (decisionSelected.outcome === DecisionOutcome.Negative || decisionSelected.outcome === DecisionOutcome.Positive);

  return (
    <div className={classes.mainContainer} style={{ maxHeight: stepMaxHeight }}>
      {/* {fullscreen && <div style={{ height: window.innerHeight + 150 }} />} */}

      {!below && children}

      {avatar && (
        <div className={classes.container}>
          <Fade in={avatarIsLoaded} timeout={StylingOptions.transitionTimeout}>
            <img src={avatar} alt="NPC" className={classes.avatar} onLoad={() => setAvatarLoaded(true)} />
          </Fade>

          {avatarIsLoaded && (
            <div className={classes.textContainer}>
              <DecodedQuillWindupText
                className={classes.textWithAvatar}
                text={text}
                processName={process.name}
                onFinished={onTextFinished}
                pace={pace}
              />
            </div>
          )}
        </div>
      )}

      {!avatar && (
        <DecodedQuillWindupText pace={pace} text={text} processName={process.name} onFinished={onTextFinished} />
      )}

      {below && children}

      {!isOrphanDecision && (
        <div className={classes.decisionsOuterContainer}>
          {decisionSelected && (
            <div className={classes.decisionsSelected}>
              <Fade in={decisionSelected != null} timeout={StylingOptions.transitionTimeout}>
                <GameDecisionTaken
                  decision={decisionSelected}
                  onFinished={() => {
                    onDecisionTakenComplete();
                    // if (!showStat) {
                    // onDecisionSelect(decisionSelected);
                    // }
                  }}
                />
              </Fade>

              {revealStat && (
                // <Fade in={showStat} timeout={StylingOptions.transitionTimeout}>
                <div className={gameClasses.separatorStat} style={{ padding: '0.55em' }}>
                  {getIcon(settings.stat, settings.customStatIcon)}
                  <CountUp
                    preserveValue
                    number={ScoreUtils.getImpactScores(decisionSelected.impact, settings).stat}
                    duration={StoryTextBoxTimings.StatCountUpSpeed}
                    onEnd={() => onDecisionSelect(decisionSelected)}
                    formattingFn={(number) =>
                      Helpers.formattingFn(
                        number,
                        new Impact({ settings }).getImpactSymbol(decisionSelected.outcome) + prefix,
                        suffix,
                      )
                    }
                  />
                </div>
                // </Fade>
              )}
              <ScoreBonus
                decisionSelected={decisionSelected}
                showStat={showStat}
                show={revealBonus}
                speedBonus={getSpeedBonus()}
              />
            </div>
          )}

          <div>
            <Fade in={showAvailableDecisions} timeout={StylingOptions.transitionTimeout}>
              <div className={clsx(classes.decisionsContainer)}>
                {decisions?.map((decision: Decision, decisionIndex: number) => (
                  <GameDecisionButton
                    key={decision.id}
                    label={decision.decision}
                    onClick={() => {
                      setShowAvailableDecisions(false);
                      setDecisionSelected({ ...decision, id: decisionIndex });
                      // onDecisionSelect(decision, getSpeedBonus());
                    }}
                  />
                ))}
              </div>
            </Fade>
          </div>
        </div>
      )}
    </div>
  );
};
