import { createOption } from './utils';

export const boxShadow = {
  size: {
    none: createOption('none', '0px'),
    small: createOption('small', '0.25vh'),
    regular: createOption('regular', '0.5vh'),
    big: createOption('big', '1vh'),
  },
  blur: {
    none: createOption('none', '0px'),
    small: createOption('small', '1vh'),
    regular: createOption('regular', '5vh'),
    big: createOption('big', '10vh'),
  },
  opacity: {
    30: createOption('30%', '0.3'),
    40: createOption('40%', '0.4'),
    50: createOption('50%', '0.5'),
    60: createOption('60%', '0.6'),
    70: createOption('70%', '0.7'),
    80: createOption('80%', '0.8'),
    90: createOption('90%', '0.9'),
    100: createOption('100%', '1'),
  },
};
