import { makeAutoObservable } from 'mobx';

import { DBLeaderboard, DBLearners } from '@firebase/dbUtil';

import { Learner } from 'objects/Learner';
import { LearnerProgress, LearnerStats } from 'objects/LearnerStats';
import { RootStore } from 'stores';
import LeaderboardObject from 'objects/LeaderboardObject';
import { userUID } from '@firebase/authUtil';
import LocalStorageService from 'services/LocalStorageService';
import { LRSCredentials } from 'objects/Settings';
import Bugsnag from '@bugsnag/js';

class LearnerStore {
  rootStore: RootStore;
  isAdmin = false;
  loaded = false;
  learner: Learner = null;
  learnerUID = '';
  lmsParams: any = {};

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;

    // Help debug on development
    // const lmsParams = LocalStorageService.get('lmsParams');

    // if (lmsParams) {
    //   this.setLMSParams(lmsParams);
    //   this.startLMS();
    // }
  }

  setIsAdmin = (isAdmin: boolean) => {
    this.isAdmin = isAdmin;
  };

  setLoaded = (loaded: boolean) => {
    this.loaded = loaded;
  };

  setLearner = (learner: Learner) => {
    this.learner = learner;
    this.loaded = true;
  };

  setLMSParams = (params: any) => {
    LocalStorageService.set('lmsParams', params);
    this.lmsParams = params;
  };

  clearLms() {
    LocalStorageService.delete('lmsParams');
    this.lmsParams = {};
  }

  startLMS() {
    // Export logic to small service/class
    const { actor } = this.lmsParams;

    if (actor?.mbox) {
      const { mbox, name } = actor;
      const [email] = mbox;
      const [fullName] = name;
      const values = fullName.split(' ');
      const [firstName, lastName] = values;

      const learner = new Learner({ firstName, lastName, email });
      this.setLearner(learner);
    } else if (actor?.name) {
      const firstName = actor.name.split(' ')[0];
      const lastName = actor.name.split(' ')[1];
      const email = 'support@adeptly.ai';

      const learner = new Learner({ firstName, lastName, email });
      this.setLearner(learner);
    }
  }

  setLearnerUID = (learnerUID: string) => (this.learnerUID = learnerUID);

  clear = () => {
    this.learner = null;
    this.isAdmin = false;
    this.lmsParams = {};
    this.loaded = false;
  };

  updateLearner = (learner: Learner) => {
    this.learner = learner;
    new DBLearners(this.rootStore.projectUID).updateItem(this.learnerUID, JSON.parse(JSON.stringify(this.learner)));
  };

  patchLearner = (params: Partial<Learner>) => {
    new DBLearners(this.rootStore.projectUID).updateItem(this.learnerUID, JSON.parse(JSON.stringify(params)));
  };

  acceptTerms = (): void => {
    this.learner.acceptTerms();
    this.updateLearner(this.learner);
  };

  refuseTerms = (): void => {
    this.learner.refuseTerms();
    this.updateLearner(this.learner);
  };

  resetProgressHistory = (): void => {
    this.learner.stats.progressHistory = [];
  };

  resetProgress = () => {
    const startingStat = this.rootStore.settingsStore.settings.startingStat;
    const { analytics, highscore } = this.learner.stats;
    this.learner.stats = new LearnerStats();
    this.learner.stats.currentStep = null;
    this.learner.stats.stat = Number(startingStat);
    this.learner.stats.analytics = analytics;
    this.learner.stats.highscore = highscore;
    this.learner.stats.finishGameplay();
    this.patchLearner(this.learner);
  };

  resetProcessProgress = (startingStat, chapterId, firstStepId) => {
    if (this.learner.stats.progressHistory.length > 0) {
      let chapterScore = this.learner.stats.progressHistory[this.learner.stats.progressHistory.length - 1].currentScore;
      for (let i = this.learner.stats.progressHistory.length - 1; i >= 0; i--) {
        if (chapterId === this.learner.stats.progressHistory[i].currentStepGroupId) {
          chapterScore = this.learner.stats.progressHistory[i].currentScore || 0;
          this.learner.stats.progressHistory.pop();
        } else {
          break;
        }
      }
      const chapterStartProgressHistory = new LearnerProgress();
      chapterStartProgressHistory.currentStepGroupId = chapterId;
      chapterStartProgressHistory.currentPageId = firstStepId;
      chapterStartProgressHistory.currentScore = chapterScore;
      this.learner.stats.progressHistory.push(chapterStartProgressHistory);
      this.learner.stats.score = chapterScore;
      this.learner.stats.stat = parseFloat(startingStat);
      this.learner.stats.currentStepGroup = chapterId;
      this.learner.stats.currentStep = firstStepId;

      this.updateLearner(this.learner);
    }
  };

  updateUserScore = async () => {
    const data: LeaderboardObject = {
      name: this.learner.firstName + ' ' + this.learner.lastName,
      score: this.learner.stats.highscore * -1, // Invert the number. This get's turned back into a positive inside Leaderboard.brs. Seems like this is the only way to pull top 10 scores from firebase
    };

    const snapshot = await new DBLeaderboard(this.rootStore.projectUID).getItem(userUID());

    // The leaderboard saves data using negatives values to help ordering at Firebase level
    if (!snapshot.exists() || (snapshot.val()?.score || 0) > data.score) {
      new DBLeaderboard(this.rootStore.projectUID).setItem(userUID(), data);
    }
  };

  get lmsParamsHasValidCredentials(): boolean {
    if (this.lmsParams?.endpoint && this.lmsParams?.auth) {
      return true;
    }

    return false;
  }

  get lrsCredentials(): LRSCredentials {
    try {
      const token = this.lmsParams.auth.replace('Basic ', '');
      const [username, password] = atob(token)?.split(':');

      return {
        endpoint: this.lmsParams?.endpoint,
        username,
        password,
      };
    } catch (e) {
      Bugsnag.addMetadata('LMS params', { params: this.lmsParams, projectUID: this.rootStore.projectStore.uid });
      Bugsnag.notify(e);

      return {
        endpoint: '',
        username: '',
        password: '',
      };
    }
  }
}

export default LearnerStore;
