import ScoreUtils from 'utils/ScoreUtils';
import { DecisionOutcome } from './Decision';
import Settings, { BadDecisionDirection, DecisionDirectionSymbol, ImpactScoreValues } from './Settings';

export enum ImpactOptions {
  tiny = 'Tiny',
  small = 'Small',
  medium = 'Medium',
  large = 'Large',
  huge = 'Huge',
  none = 'None',
}

export class Impact {
  private settings: Settings;

  constructor({ settings }) {
    this.settings = settings;
  }

  getImpact(decision): string | 'No Impact' {
    let impact = '';
    const symbol = this.getImpactSymbol(decision.outcome);
    if (decision.outcome === DecisionOutcome.Positive) {
      impact =
        symbol +
        this.settings.prefix +
        ScoreUtils.getImpactScores(decision.impact, this.settings).stat +
        this.settings.suffix;
    } else if (decision.outcome === DecisionOutcome.Negative) {
      impact =
        symbol +
        this.settings.prefix +
        ScoreUtils.getImpactScores(decision.impact, this.settings).stat +
        this.settings.suffix;
    } else if (decision.outcome === DecisionOutcome.None) {
      impact = 'No Impact';
    }
    return impact;
  }

  getImpactSymbol(outcome): string {
    if (outcome === DecisionOutcome.None) return '';
    const symbol = outcome === DecisionOutcome.Positive ? '+' : '-';

    if (this.settings.badDecisionDirection === BadDecisionDirection.Increase) {
      return symbol === '+' ? '-' : '+';
    }

    return symbol;
  }

  getDecisionDirectionSymbol(symbol: DecisionDirectionSymbol): string {
    if (this.settings.badDecisionDirection === BadDecisionDirection.Increase) {
      if (symbol === '+') {
        return '-';
      } else {
        return '+';
      }
    }

    return symbol;
  }

  getImpactString(symbol: DecisionDirectionSymbol, impactString: keyof ImpactScoreValues): string {
    const impact = this.settings.impactStatValues[impactString] || 0;

    return this.getDecisionDirectionSymbol(symbol) + this.settings.prefix + impact.toString() + this.settings.suffix;
  }
}
