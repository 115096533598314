import React, { createContext, FC, useContext, useEffect, useState } from 'react';

import { remoteConfig } from '@firebase/remoteConfigUtil';

const remoteConfigValues = () => {
  const allValues = remoteConfig.getAll();
  return {
    flowchart: allValues.flowchart.asBoolean(),
    maintenance: JSON.parse(allValues.maintenance.asString()),
  };
};

export const RemoteConfigContext = createContext<any>(remoteConfigValues());

const RemoteConfigContextProvider: FC = ({ children }) => {
  const [values, setValues] = useState(remoteConfigValues());

  useEffect(() => {
    remoteConfig.fetchAndActivate().then(() => {
      setValues(remoteConfigValues());
    });
  }, []);

  return <RemoteConfigContext.Provider value={values}>{children}</RemoteConfigContext.Provider>;
};

export const useRemoteConfigContext = () => useContext(RemoteConfigContext);

export default RemoteConfigContextProvider;
