import { makeStyles } from '@material-ui/core';

export const useOnboardingVideoStyles = makeStyles(({ palette }) => ({
  root: {
    height: 400,
    backgroundColor: '#C9D1D7',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  video: {
    height: '100%',
    width: '100%',
  },
  loading: {
    position: 'absolute',
  },
}));
