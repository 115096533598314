import { FC } from 'react';
import {
  ThemeProvider as MuiThemeProvider,
  createMuiTheme,
  makeStyles as MuiMakeStyles,
  Theme,
  CssBaseline,
} from '@material-ui/core';

import { defaultTheme } from 'themes';
import { Styles, WithStylesOptions } from '@material-ui/styles';

const ThemeProvider: FC = ({ children }) => (
  <MuiThemeProvider theme={createMuiTheme(defaultTheme)}>
    <CssBaseline />
    {children}
  </MuiThemeProvider>
);

export function themedMakeStyles<Props extends {}, ClassKey extends string = string>(
  ...args: [styles: Styles<Theme, Props, ClassKey>, options?: Omit<WithStylesOptions<Theme>, 'withTheme'>]
) {
  return MuiMakeStyles<Theme, Props, ClassKey>(...args);
}

export default ThemeProvider;
