import { observable, action, makeObservable } from 'mobx';

import { userUID } from '@firebase/authUtil';
import { DBUsers } from '@firebase/dbUtil';

import User from 'objects/User';
import { UserPlan } from 'objects/UserPlan';
import { RootStore } from 'stores';

class UserStore {
  rootStore: RootStore;
  user = new User();
  plan = new UserPlan();
  loaded = false;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      loaded: observable,
      user: observable,
      plan: observable,
      clear: action,
      setLoaded: action,
      setUser: action,
      updateProjectName: action,
      updateMeta: action,
      updateUserPlan: action,
      addNewInvitedProject: action,
      removeInvitedProject: action,
      addNewProject: action,
      deleteProject: action,
    });

    this.rootStore = rootStore;
  }

  setLoaded = (loaded) => {
    this.loaded = loaded;
  };

  setUser = (user) => {
    this.user = user;
    this.plan.setUser(user);
    this.setLoaded(true);
  };

  clear = () => {
    this.user = new User();
    this.setLoaded(false);
  };

  addNewProject = (projectUID, project) => {
    const user = this.user;
    this.plan.projects++;
    user.projects[projectUID] = project;

    this.user = user;
  };

  addNewInvitedProject = (projectUID, project) => {
    const user = this.user;
    user.invitedProjects[projectUID] = project;

    this.user = user;
  };

  removeInvitedProject = (projectUID) => {
    const user = this.user;
    delete user.invitedProjects[projectUID];

    this.user = user;
  };

  deleteProject = (projectUID) => {
    const user = this.user;
    delete user.projects[projectUID];

    this.user = user;
    this.plan.projects--;
    this.plan.setUser(user);
  };

  addNewLearner = () => {
    this.plan.learners++;
  };

  deleteLearner = () => {
    this.plan.learners--;
  };

  updateProjectName = (projectUID, name) => {
    this.user.projects[projectUID] = name;
    new DBUsers().setProjectName(userUID(), projectUID, name);
  };

  updateMeta = <K extends keyof User['meta']>(key: K, value: User['meta'][K]) => {
    this.user.meta[key] = value;
    new DBUsers().updateMeta(userUID(), value);
  };

  updateUserPlan = (plan) => {
    this.user.plan = plan;
    new DBUsers().setItemProperty(userUID(), 'plan', plan);
    this.plan.setUser(this.user);
  };
}

export default UserStore;
