import { Timestamp } from 'utils/DateUtils';

export const DEFAULT_LOCALE = 'en-US';

export type Locale =
  | 'en-US'
  | 'en-GB'
  | 'es-US'
  | 'es-ES'
  | 'nl-NL'
  | 'fr-FR'
  | 'de-DE'
  | 'it-IT'
  | 'ja-JA'
  | 'pt-PT'
  | 'sv-SV';

export enum LocaleLabels {
  'nl-NL' = 'Dutch (nl-NL)',
  'en-US' = 'English (en-US)',
  'en-GB' = 'English (en-GB)',
  'es-US' = 'Spanish (es-US)',
  'es-ES' = 'Spanish (es-ES)',
  'fr-FR' = 'French (fr-FR)',
  'de-DE' = 'German (de-DE)',
  'it-IT' = 'Italian (it-IT)',
  'ja-JA' = 'Japanese (ja-JA)',
  'pt-PT' = 'Portuguese (pt-PT)',
  'sv-SV' = 'Swedish (sv-SV)',
}

export default class Languages {
  default: Locale = 'en-US';
  fallback: Locale = 'en-US';
  availableLocales: {
    [K in Locale]: {
      filename: string;
      uploadedAt: Timestamp;
      error: boolean;
    };
  } = {
    'en-US': {
      filename: '-',
      uploadedAt: null,
      error: false,
    },
    'en-GB': {
      filename: '-',
      uploadedAt: null,
      error: false,
    },
    'nl-NL': {
      filename: '-',
      uploadedAt: null,
      error: false,
    },
    'fr-FR': {
      filename: '-',
      uploadedAt: null,
      error: false,
    },
    'de-DE': {
      filename: '-',
      uploadedAt: null,
      error: false,
    },
    'it-IT': {
      filename: '-',
      uploadedAt: null,
      error: false,
    },
    'ja-JA': {
      filename: '-',
      uploadedAt: null,
      error: false,
    },
    'pt-PT': {
      filename: '-',
      uploadedAt: null,
      error: false,
    },
    'sv-SV': {
      filename: '-',
      uploadedAt: null,
      error: false,
    },
    'es-ES': {
      filename: '-',
      uploadedAt: null,
      error: false,
    },
    'es-US': {
      filename: '-',
      uploadedAt: null,
      error: false,
    },
  };
}
