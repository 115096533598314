import React, { FC } from 'react';
import { Typography, Button, Link } from '@material-ui/core';
import image404 from 'assets/maintenance-image.png';
import { useMaintenancePageStyles } from './MaintenancePage.styles';

export const MaintenancePage: FC<any> = ({
  title = 'Maintenance',
  description = `Adeptly is under maintenance, we will return in`,
  time = '1 hour',
  btn = {
    label: 'Contact Us',
    href: 'mailto:info@adeptly.ai',
  },
}) => {
  const classes = useMaintenancePageStyles();

  return (
    <div className={classes.root}>
      <div className={classes.pageWrapper}>
        <img src={image404} width="300px" alt="Maintenance" />
        <div className={classes.infosWrapper}>
          <Typography variant="h5" className={classes.title}>
            {title}
          </Typography>
          <Typography variant="body1" className={classes.description}>
            {`${description} ${time}`}
          </Typography>
          <div className={classes.buttonsWrapper}>
            <Link href={btn.href}>
              <Button variant="contained" color="primary" size="large" fullWidth>
                {btn.label}
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
