import { DBMap } from './DBStructures';
import { DBProject } from './DBProject';
import { styleTheme, StyleTheme } from 'objects/StyleTheme';

interface StyleThemes {
  activeID: string;
  names: Record<string, string>;
  themes: Record<string, StyleTheme>;
}

export class DBStyleThemes extends DBMap<StyleThemes> {
  public projectUID: string;

  constructor(projectUID: string) {
    super();
    this.projectUID = projectUID;
    const projectRef = new DBProject(projectUID).ref;
    this.ref = projectRef.child('styleThemes');
  }

  addTheme = async (id, name, theme) => {
    try {
      this.ref.child('activeID').set(id);
      this.ref.child('names').child(id).set(name);
      this.ref.child('themes').child(id).set(theme);
    } catch {
      return;
    }
  };

  removeTheme = async (id) => {
    try {
      this.ref.child('activeID').set('default');
      this.ref.child('names').child(id).remove();
      this.ref.child('themes').child(id).remove();
    } catch {
      return;
    }
  };

  getActiveStyleTheme = async () => {
    try {
      const activeID = await this.getActiveID();

      const activeStyleTheme: StyleTheme = await this.ref
        .child('themes')
        .child(activeID)
        .once('value')
        .then((snap) => snap.val());

      return activeStyleTheme;
    } catch {
      return styleTheme;
    }
  };

  getActiveID = () =>
    this.ref
      .child('activeID')
      .once('value')
      .then((snap) => snap.val());

  setActiveID = (newActiveID: string) => this.ref.child('activeID').set(newActiveID);

  updateActiveName = async (selectedTheme: string) => {
    try {
      const activeID = await this.getActiveID();
      this.ref.child('names').child(activeID).set(selectedTheme);
    } catch (e) {
      console.error(e);
    }
  };

  getAllNames = async () => {
    try {
      const projectStyleThemeNames = await this.ref
        .child('names')
        .once('value')
        .then((snap) => snap.val());

      return projectStyleThemeNames;
    } catch (e) {
      console.error(e);
    }
  };

  updateActiveStyleTheme = async (theme: StyleTheme) => {
    try {
      const styleThemeID = await this.getActiveID();
      await this.ref.child('themes').child(styleThemeID).update(theme);
    } catch (e) {
      console.error(e);
    }
  };
}
