import React, { FC, useEffect, useState } from 'react';
import { IntlProvider as ReactIntlProvider, defineMessages } from 'react-intl';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';

import { DBLanguages } from '@firebase/dbUtil';
import SettingsStore from 'stores/settingsStore';
import useLocale from 'hooks/useLocale';

export type ProjectIntlProvider = {
  settingsStore: SettingsStore;
};

const IntlProvider: FC<ProjectIntlProvider> = ({ children }) => {
  const [texts, setTexts] = useState(null);
  const { locale } = useLocale();

  useEffect(() => {
    const value = new DBLanguages().getTexts(locale);
    setTexts(value);
  }, [locale]);

  if (!texts) return null; // loading

  return (
    <ReactIntlProvider locale={locale} messages={defineMessages(texts[locale])}>
      {children}
    </ReactIntlProvider>
  );
};

export default compose(inject('settingsStore'), observer)(IntlProvider);
