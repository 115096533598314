import { observable, action, makeObservable } from 'mobx';

import { ProjectState, ProjectStatus } from 'objects/Project';
import { DBMembers, DBProject, DBSettings, DBStyleThemes } from '@firebase/dbUtil';
import { auth } from '@firebase/firebaseUtil';

import { RootStore } from './index';
import { generateTimestamp } from 'utils/DateUtils';
import PublishGameService from 'services/Publish/PublishGameService';
import { ThemeColor } from 'objects/StyleTheme';
import { encryptEmail } from 'utils/StringUtils';

class ProjectStore {
  rootStore: RootStore;
  uid: string;
  name: string;
  state: ProjectState;
  dateCreated: number;
  authBackgroundImg: string;
  memberRole: string;
  activeStyleID: string;
  backgroundColor: string;
  loaded: boolean;
  loadingProgress = 0;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      uid: observable,
      name: observable,
      state: observable,
      dateCreated: observable,
      authBackgroundImg: observable,
      activeStyleID: observable,
      loaded: observable,
      memberRole: observable,
      setUID: action,
      setName: action,
      setLoaded: action,
      setState: action,
      getState: action,
      setLoadingProgress: action,
      updateStateField: action,
      updateLastPublishedAt: action,
      subscribeOnStateChange: action,
      makeDraft: action,
      resetLocales: action,
      updateName: action,
      publishGame: action,
    });

    this.rootStore = rootStore;
  }

  setUID = (uid) => {
    this.uid = uid;
  };

  setName = (name) => {
    this.name = name;
  };

  setLoaded = (loaded: boolean) => {
    this.loaded = loaded;
  };

  setLoadingProgress = (value: number) => {
    if (this.loadingProgress + value >= 100) {
      this.loadingProgress = 100;
    } else {
      this.loadingProgress = value;
    }
  };

  getState = async (uid): Promise<ProjectState> => {
    const response = await new DBProject(uid).getProperty('state');
    const state = response.val();
    this.state = state || { lastPublishedAt: null, status: ProjectStatus.Draft };

    return this.state;
  };

  subscribeOnStateChange = async (uid, callback?): Promise<void> => {
    new DBProject(uid).ref.child('state').on('value', (snapshot) => {
      const newState = snapshot.val();
      if (this.state && newState !== this.state) callback?.(this.state.status);
      this.state = newState || { lastPublishedAt: null, status: ProjectStatus.Draft };
    });
  };

  getDateCreated = async (uid): Promise<number> => {
    const response = await new DBProject(uid).getProperty('dateCreated');
    const dateCreated = response.val();
    this.dateCreated = dateCreated;

    return this.dateCreated;
  };

  getBackgroundImg = async (uid): Promise<string> => {
    const response = await new DBSettings(uid).getProperty('authBackgroundImg');
    const authBackgroungImg: string = response.val();
    this.authBackgroundImg = authBackgroungImg || '';

    return this.authBackgroundImg;
  };

  getBackgroundColor = async (uid): Promise<string> => {
    const response = await new DBStyleThemes(uid).getActiveStyleTheme();
    const color: ThemeColor = response.game.backgroundColor;
    const backgroundColorCSS = `rgba(${color.r},${color.g},${color.b},${color.a})`;
    this.backgroundColor = backgroundColorCSS || '';
    return this.backgroundColor;
  };

  getMemberRole = async (projectUID): Promise<string> => {
    const memberuid = encryptEmail(auth.currentUser.email);
    const response = await new DBMembers(projectUID).getMemberRole(memberuid);
    const memberRole: string = response.val();
    this.memberRole = memberRole || '';
    return this.memberRole;
  };

  setState = (value: ProjectState) => {
    this.state = value;
    new DBProject(this.uid).setProperty('state', value);
  };

  updateStateField = (name: keyof ProjectState, value: any) => {
    this.setState({ ...this.state, [name]: value });
  };

  updateLastPublishedAt = () => {
    const timestamp = generateTimestamp();
    this.updateStateField('lastPublishedAt', timestamp);
    this.updateStateField('status', 'published');
    this.state = { ...this.state, lastPublishedAt: timestamp, status: ProjectStatus.Published };
  };

  makeNotPublished = async () => {
    if (this.state.status === ProjectStatus.NotPublished) {
      return;
    }
    this.updateStateField('status', 'notPublished');
    this.state = { ...this.state, status: ProjectStatus.NotPublished };
  };

  makeDraft() {
    if (this.state.status === ProjectStatus.Draft) return;

    this.updateStateField('status', ProjectStatus.Draft);
    this.resetLocales();
  }

  get settingsStore() {
    return this.rootStore.settingsStore;
  }

  resetLocales = () => {
    if (this.state.status === ProjectStatus.Published) return;

    const {
      settings: {
        languages: { default: defaultLocale, availableLocales },
      },
    } = this.rootStore.settingsStore;

    Object.keys(availableLocales)
      .filter((locale) => locale !== defaultLocale)
      .forEach((locale) => {
        if (availableLocales[locale].uploadedAt) {
          availableLocales[locale].error = true;
        }
      });

    this.settingsStore.updateLanguages({ availableLocales });
  };

  updateName = (name) => {
    this.name = name;
    new DBProject(this.uid).setName(name);
    this.rootStore.userStore.updateProjectName(this.uid, name);
  };

  publishGame = async () => {
    const {
      chapterStore: { chapters },
      learnerStore: { isAdmin },
      styleThemes: { activeStyleTheme },
      settingsStore: { settings },
    } = this.rootStore;

    const { uid: projectUID } = this;

    const {
      region,
      languages: { default: defaultLocale },
    } = settings;

    if (!projectUID || !isAdmin) return;

    this.updateLastPublishedAt();

    const publishGameService = new PublishGameService({
      projectUID,
      locale: defaultLocale,
      chapters,
      settings,
      styles: activeStyleTheme,
      region,
    });
    await publishGameService.perform();
  };
}

export default ProjectStore;
