export default class AppUtils {
  public static isMobile = false;

  public static findIndexByKey(array: Array<any>, key: string, value: any) {
    for (let i = 0; i < array.length; i++) {
      if (array[i][key] === value) {
        return i;
      }
    }
    return -1;
  }

  public static getStoryIndex(props: any, storyId: string): number {
    return AppUtils.findIndexByKey(props.storys, 'id', parseInt(storyId));
  }

  public static getChapterIndex(props: any, chapterId: string): number {
    return AppUtils.findIndexByKey(props.chapters, 'id', parseInt(chapterId));
  }

  public static getDecisionIndex(decisions: Array<any>, props: any, decisionId: string): number {
    return AppUtils.findIndexByKey(decisions, 'id', parseInt(decisionId));
  }
}

export interface SortAlphabeticallyInterface {
  name: string;
}

export function sortAlphabetically(
  a: SortAlphabeticallyInterface | any,
  b: SortAlphabeticallyInterface | any,
  field = 'name',
): number {
  const itemA = a[field].toUpperCase();
  const itemB = b[field].toUpperCase();

  if (itemA < itemB) {
    return -1;
  } else if (itemA > itemB) {
    return 1;
  }
  return 0;
}

export interface SortAvailabilityInterface {
  available: boolean;
}

export function sortAvailability(
  a: SortAvailabilityInterface | any,
  b: SortAvailabilityInterface | any,
  field = 'available',
): number {
  const itemA = a[field];
  const itemB = b[field];

  if (itemA && !itemB) {
    return -1;
  } else if (!itemA && itemB) {
    return 1;
  }
  return 0;
}

export function percentage(num, per, method = Math.ceil) {
  return method((num / (per || 1)) * 100);
}
