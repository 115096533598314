import { makeStyles, createStyles } from '@material-ui/core';
import { CustomThemeStyles } from './FullscreenLoading';

export interface RgbaColor {
  r: string;
  g: string;
  b: string;
  a: string;
}

interface StyleProps extends CustomThemeStyles {
  isLearner: boolean;
}

const formatColor = (object: RgbaColor) => `rgba(${object?.r}, ${object?.g}, ${object?.b}, ${object?.a})`;

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      overflow: 'hidden',
      height: '100%',
      width: '100%',
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: (props: any) =>
        props.isLearner && props.backgroundColor ? formatColor(props.backgroundColor) : '#f1f3f',
    },
    spinner: {
      '& svg': {
        color: (props: StyleProps) => props.isLearner && props.primaryColor && formatColor(props.primaryColor),
      },
    },
  }),
);
