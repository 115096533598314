export default {
  type: 'object',
  required: ['canDelete', 'currentDecisionId', 'hasError', 'imgUrl', 'locked', 'multiselect', 'text'],
  properties: {
    canDelete: { type: 'boolean' },
    currentDecisionId: { type: 'integer' },
    hasError: { type: 'boolean' },
    id: { type: ['string', 'number'] },
    imgUrl: { type: 'string' },
    locked: { type: 'boolean' },
    multiselect: { type: 'boolean' },
    text: { type: 'string' },
  },
};
