import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';
import { auth } from '@firebase/firebaseUtil';
import { Routes } from 'constants/routes';
import { FullscreenLoading } from 'components/Loadings';

export const withAuthorization = (condition, props?) => (Component) => {
  function WithAuthorization(props: any) {
    useEffect(() => {
      const unsubscribe = auth.onAuthStateChanged((authUser) => {
        const path: Array<string> = props.match.path.split('/');
        if (!condition(authUser)) {
          // NOT AUTHENTICATED
          if (path[1] === ':projectUID') {
            props.history.push('/' + props.match.params.projectUID + Routes.Auth);
          } else {
            props.history.push(Routes.Auth);
          }
        } else {
          if (authUser.isAnonymous && path[1] !== ':projectUID') {
            props.history.push(Routes.Auth);
          }
          // AUTHENTICATED
        }
      });

      return unsubscribe();
    });

    return props.sessionStore.authUser ? <Component {...props} /> : <FullscreenLoading />;
  }

  return compose(withRouter, inject('sessionStore'), observer)(WithAuthorization);
};
