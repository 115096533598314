import { AvailableRegions } from 'storage-client';
import FileUploadService from '../FileUploadService';

interface PublishStylesServiceParams {
  projectUID: string;
  locale: string;
  styles: any;
  region?: AvailableRegions;
}

export default class PublishStylesService {
  locale: string;
  projectUID: string;
  path: string;
  region?: AvailableRegions;
  styles: any;

  constructor({ projectUID, locale = 'en-US', region = 'us', styles }: PublishStylesServiceParams) {
    this.projectUID = projectUID;
    this.locale = locale;
    this.styles = styles;
    this.region = region;
    this.path = this.locale;
  }

  async perform() {
    const service = new FileUploadService({
      type: 'styles',
      projectUID: this.projectUID,
      region: this.region,
    });

    service.addJSON(this.styles, this.path);
    return await service.upload();
  }
}
