import { useEffect, useState } from 'react';
import { useStores } from 'stores/useStores';

export enum PlatformLocaleMapping {
  es = 'es-ES',
  de = 'de-DE',
  en = 'en-US',
  fr = 'fr-FR',
  it = 'it-IT',
  ja = 'ja-JA',
  nl = 'nl-NL',
  pt = 'pt-BR',
  sv = 'sv-SV',
}

const prefixLanguage = (language: string | undefined) => {
  if (!language) {
    return 'en';
  }
  return language.split('-')[0];
};

const useLocale = () => {
  const {
    settingsStore: {
      settings: {
        languages: { default: defaultLanguage = 'en-US' },
      },
    },
    learnerStore: { isAdmin },
  } = useStores();
  const prefixDefaultLanguage = prefixLanguage(defaultLanguage);
  const useBrowserLanguage = (!isAdmin && navigator.language) || PlatformLocaleMapping[prefixDefaultLanguage];
  const prefixUseBrowserLanguage = prefixLanguage(useBrowserLanguage);
  const [locale, setLocale] = useState<string>(PlatformLocaleMapping[prefixDefaultLanguage] || 'en-US');
  const [gameLocale, setGameLocale] = useState<string>(defaultLanguage);

  useEffect(() => {
    setGameLocale(useBrowserLanguage);
    setLocale(PlatformLocaleMapping[prefixUseBrowserLanguage] || 'en-US');
  }, []);

  return {
    locale,
    setLocale,
    gameLocale,
    setGameLocale,
    localeMapping: PlatformLocaleMapping,
  };
};

export default useLocale;
