export default class Analytics {
  started = 0;
  compTimes: Array<number> = []; // Average Completition Time
  chapterStarted = 0;
  chapterFailed = 0;
  chapterCompleted = 0;
  chapterEnded = 0; // FIREBASE_TODO: strange data, chapterCompleted & chapterEnded, they've same purpose?
  fails = 0;
  completed = 0;
  decisions = 0;
  highestPositiveTotal = 0;
  highscore = 0;
  worstscore = null;
  positiveTotal = 0;
  negativeTotal = 0;
  positiveHuge = 0;
  positiveLarge = 0;
  positiveMedium = 0;
  positiveSmall = 0;
  positiveTiny = 0;
  negativeTiny = 0;
  negativeSmall = 0;
  negativeMedium = 0;
  negativeLarge = 0;
  negativeHuge = 0;
  behaviourScoreBenchmark: number = null; // Behaviour score from first gameplay
  behaviourScore: number = null; // Highest behaviour score. This score adjusts based on the impact of the decision
  upliftScore = 0;
  upliftPositive = 0;
}
