import { DBList } from './DBStructures';
import { DBProject } from './DBProject';
import { analytics } from '../analyticsUtil';
import { Learner } from '../../objects/Learner';

export class DBLearners extends DBList<Learner> {
  public projectUID: string;

  constructor(projectUID: string) {
    super();
    this.projectUID = projectUID.toString();
    const projectRef = new DBProject(projectUID).ref;
    this.ref = projectRef.child('users'); // FIREBASE_TODO: change to learners
  }

  getBy =
    <K extends keyof Learner>(learnerKey: K) =>
    (learnerValue: any, callback?) =>
      this.ref
        .orderByChild(learnerKey)
        .equalTo(learnerValue)
        .limitToFirst(1)
        .once('value', callback)
        .then((snapshot) => {
          if (snapshot.exists()) {
            const learnerSnapshot = snapshot.val();
            return { key: Object.keys(learnerSnapshot)[0], data: learnerSnapshot[Object.keys(learnerSnapshot)[0]] };
          }
          return null;
        });

  getByUserUID = this.getBy('userUID');

  getByEmail = this.getBy('email');

  add = (learner: Learner, callback?) =>
    this.ref.push(learner, (a) => {
      analytics.learnerAdded({ projectUID: this.projectUID });
      callback && callback(a);
    });
}
