import { DBLearners, DBUsers } from '@firebase/dbUtil';

import { LEARNER_STATUS } from '../Learner';
import { Plan, Rules, planRules } from './PlanRules';

export class UserPlan {
  rules: Rules = planRules[Plan.Beginner];
  projects = 0;
  learners = 0;

  setUser(user) {
    // TODO: all migrations let some situational code, maybe if we put some effort to create a README to do migrations
    // with firebase by CLI (or some Node code) it could remove this kind of code
    // Migration
    new DBUsers().getUserByEmail(user.email).then((resp) => {
      if (!resp) return;

      const { key, data } = resp;
      if (!data.plan && !resp) {
        new DBUsers().setItem(key, { plan: Plan.None, ...data });
      }
    });

    this.rules = planRules[user.plan];
    const projectsArray = Object.keys(user.projects || []);
    this.projects = projectsArray.length;
    projectsArray.forEach((project) => {
      new DBLearners(project).getAll().then((snapshot) => {
        const learners = snapshot.val();

        if (learners) {
          Object.keys(learners).forEach((learner) => {
            if (learners[learner].status !== LEARNER_STATUS.ADMIN) {
              this.learners++;
            }
          });
        }
      });
    });
  }

  canCreateProject() {
    return this.projects < this.rules.maxProjects;
  }

  canAddLearner() {
    return this.learners < this.rules.maxLearners;
  }
}
