import { observable, action, makeObservable } from 'mobx';

import Language from 'objects/Language';
import { DBLanguages } from '@firebase/dbUtil';

import { RootStore } from './index';

export type Languages = Record<string, Language>;

class LanguagesStore {
  rootStore: RootStore;
  languages: Languages = {};

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      setLanguages: action,
      fetchLanguages: action,
      languages: observable,
    });

    this.rootStore = rootStore;
    this.fetchLanguages();
  }

  setLanguages(languages: Languages) {
    this.languages = languages;
  }

  async fetchLanguages(): Promise<any> {
    if (Object.values(this.languages).length > 0) {
      return this.languages;
    }

    const languages: Languages = new DBLanguages().listOptions();
    this.setLanguages(languages);
    return this.languages;
  }
}

export default LanguagesStore;
