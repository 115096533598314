import {
  stylingFontSizes,
  ThemeBorder,
  ThemeBoxShadow,
  ThemeColor,
  ThemeFont,
  ThemeFontsKey,
} from 'objects/StyleTheme';

export class CSSVariables {
  public static update(key: string, value: string) {
    if (value) {
      document.documentElement.style.setProperty(`--${key}`, value);
    }
  }

  public static updateFont(fontKey: ThemeFontsKey, font: ThemeFont) {
    let fontInfo = stylingFontSizes[font.size];
    if (!fontInfo?.lineHeight) fontInfo = stylingFontSizes.small;
    this.update(`${fontKey}-family`, font.family);
    this.update(`${fontKey}-line-height`, fontInfo.lineHeight);
    this.update(`${fontKey}-size`, fontInfo.fontSize);
    this.update(`${fontKey}-padding`, fontInfo.padding);
    this.update(`${fontKey}-color`, this.getColor(font.color));
  }

  public static updateFontColor(fontKey: string, fontColor: ThemeColor) {
    this.updateColorStyles(`${fontKey}-color`, fontColor);
  }

  public static updateHoverFontColor(fontKey: string, fontColor: ThemeColor) {
    this.updateColorStyles(`${fontKey}-hover-color`, fontColor);
  }

  public static updateColorStyles(colorKey: string, color: ThemeColor) {
    if (color) {
      this.update(colorKey, this.getColor(color));
    }
  }

  public static getColor({ r, g, b, a }: ThemeColor) {
    return `rgba(${r},${g},${b},${a})`;
  }

  public static getJSSFont(font: ThemeFont) {
    let fontInfo = stylingFontSizes[font.size];
    if (!fontInfo?.lineHeight) fontInfo = stylingFontSizes.small;
    const jssFont = {
      fontFamily: `"${font.family}"`,
      color: this.getColor(font.color),
      fontWeight: font.weight,
      ...fontInfo,
    };

    return jssFont;
  }

  public static getJSSWidthHeight(font: ThemeFont) {
    const size = stylingFontSizes[font.size]?.fontSize || 30;
    const jssWidthHeight = {
      width: size,
      height: size,
    };

    return jssWidthHeight;
  }

  public static getJSSBorder(border: ThemeBorder) {
    return {
      borderStyle: 'solid',
      borderWidth: border.width,
      borderRadius: border.radius,
      borderColor: this.getColor(border.color),
      ['WebkitBackfaceVisibility' as any]: 'visible',
    };
  }

  public static getJSSBoxShadow(boxShadow: ThemeBoxShadow) {
    return `${boxShadow?.size} ${boxShadow?.size} ${boxShadow?.blur} ${CSSVariables.getColor({
      ...boxShadow?.color,
      a: boxShadow?.opacity,
    })}`;
  }
}
