import Chapter, { ChapterStates } from 'objects/Chapter';
import Decision, { Movement } from 'objects/Decision';
import Step, { StepType } from 'objects/Step';

export const createGameWin = (): Chapter => {
  const chapter = new Chapter();
  chapter.id = ChapterStates.GAME_WIN;
  chapter.name = 'Game Complete';

  const endpoint = new Step({
    type: StepType.GameWin,
    decisions: [{ ...new Decision(), jumpChapter: '-1', jumpPage: '-1', hidden: true, move: Movement.Stay }],
  });
  const highscore = new Step({
    type: StepType.Highscore,
    decisions: [
      {
        ...new Decision(),
        decision: 'Play again',
        jumpChapter: chapter.id,
        jumpPage: endpoint.id,
        move: Movement.Stay,
      },
    ],
  });
  const secondStep = new Step({
    text: '<p>Congratulations {name}. You’ve made it to the end of the game.</p>',
    decisions: [
      {
        ...new Decision(),
        decision: 'View my score',
        jumpChapter: chapter.id,
        jumpPage: highscore.id,
        move: Movement.Stay,
      },
    ],
  });
  const firstStep = new Step({
    ...chapter.storys[chapter.firstStep],
    decisions: [
      { ...new Decision(), jumpChapter: chapter.id, jumpPage: secondStep.id, hidden: true, move: Movement.Stay },
    ],
  });

  chapter.storys = {
    [firstStep.id]: firstStep,
    [secondStep.id]: secondStep,
    [highscore.id]: highscore,
    [endpoint.id]: endpoint,
  };

  return chapter;
};

export const createGameFail = (): Chapter => {
  const chapter = new Chapter();
  chapter.id = ChapterStates.GAME_FAIL;
  chapter.name = 'Game Over';

  const endpoint = new Step({
    type: StepType.GameFail,
    decisions: [{ ...new Decision(), jumpChapter: '-1', jumpPage: '-1', hidden: true, move: Movement.Stay }],
  });
  const highscore = new Step({
    type: StepType.Highscore,
    decisions: [
      {
        ...new Decision(),
        decision: 'Play again',
        jumpChapter: chapter.id,
        jumpPage: endpoint.id,
        move: Movement.Stay,
      },
    ],
  });
  const secondStep = new Step({
    text: "<p>You haven't made it to the end</p>",
    decisions: [
      {
        ...new Decision(),
        decision: 'View my score',
        jumpChapter: chapter.id,
        jumpPage: highscore.id,
        move: Movement.Stay,
      },
    ],
  });
  const firstStep = new Step({
    ...chapter.storys[chapter.firstStep],
    decisions: [
      { ...new Decision(), jumpChapter: chapter.id, jumpPage: secondStep.id, hidden: true, move: Movement.Stay },
    ],
  });

  chapter.storys = {
    [firstStep.id]: firstStep,
    [secondStep.id]: secondStep,
    [highscore.id]: highscore,
    [endpoint.id]: endpoint,
  };

  return chapter;
};

export const createInitialChapters = (): Chapter[] => {
  return [createGameWin(), createGameFail(), new Chapter()];
};
