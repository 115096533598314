export const overrides = {
  MuiSwitch: {
    colorPrimary: {
      color: '#1B68FF',
      '&$checked': {
        color: '#0E3888 !important',
        '& .MuiSwitch-thumb': {
          backgroundColor: '#1B68FF !important',
          opacity: 1,
        },
      },
      '&$checked + $track': {
        backgroundColor: '#0E3888 !important',
        opacity: 1,
      },
    },
    root: {
      alignItems: 'center',
    },
    thumb: {
      height: 20,
      backgroundColor: '#F6F6F6',
    },
    track: {
      height: 16,
      opacity: 1,
      borderRadius: 8,
      backgroundColor: '#D8D8D8',
    },
  },
  MuiTypography: {
    gutterBottom: {
      marginBottom: 24,
    },
  },
};
