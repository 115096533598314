import { font } from './font';
import { border } from './border';
import { angle } from './angle';
import { boxShadow } from './boxShadow';
import { speed } from './speed';

const StylingOptions = {
  transitionTimeout: 500,
  speed,
  boxShadow,
  font,
  border,
  angle,
};

export default StylingOptions;
