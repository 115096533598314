import React, { useEffect } from 'react';
import { inject } from 'mobx-react';
import { Routes } from 'constants/routes';
import { useHistory, useLocation } from 'react-router-dom';

import { auth } from '@firebase/firebaseUtil';

export const withAuthentication = (Component) => {
  function WithAuthentication(props: any) {
    const history = useHistory();
    const location = useLocation();
    useEffect(() => {
      const { sessionStore } = props;

      auth.onAuthStateChanged((authUser) => {
        authUser ? sessionStore.setAuthUser(authUser) : sessionStore.setAuthUser(null);
        const pathName = location.pathname;
        if (pathName === '/' && !authUser) {
          history.push(Routes.Auth);
        }
      });
    });

    return <Component />;
  }

  return inject('sessionStore')(WithAuthentication);
};
