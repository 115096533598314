import React, { FC } from 'react';
import clsx from 'clsx';

import { SpinnerLoading, SpinnerLoadingProps } from 'components/Loadings';

import { useStyles, RgbaColor } from './FullscreenLoading.styles';

export interface CustomThemeStyles {
  backgroundColor: RgbaColor;
  primaryColor: RgbaColor;
}

export type FullscreenLoadingProps = {
  themeStyles?: CustomThemeStyles;
  isLearner?: boolean;
} & SpinnerLoadingProps;

export const FullscreenLoading: FC<FullscreenLoadingProps> = ({ themeStyles, isLearner }) => {
  const classes = useStyles({
    isLearner,
    backgroundColor: themeStyles?.backgroundColor,
    primaryColor: themeStyles?.primaryColor,
  });

  return (
    <div className={clsx('pageContainer', classes.root)}>
      <SpinnerLoading className={classes.spinner} />
    </div>
  );
};
