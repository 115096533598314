import { useSnackbar } from 'notistack';
import { createContext, FC, useContext, useEffect } from 'react';
import RootStore from 'stores';

export interface KeyBindingsContextValues {}

const initialContextValues = {};

const KeyBindings = createContext<KeyBindingsContextValues>(initialContextValues);

export const useKeyBindings = () => useContext(KeyBindings);

export const KeyBindingsProvider: FC<KeyBindingsContextValues> = ({ children, ...values }) => {
  const { enqueueSnackbar } = useSnackbar();

  const publishGame = async () => {
    const { projectStore } = RootStore;
    await projectStore.publishGame();
    enqueueSnackbar('Game published', { variant: 'success' });
  };

  const onCtrlSave = (e) => {
    if (e.ctrlKey && e.key === 's') {
      e.preventDefault();
      e.stopPropagation();
      publishGame();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onCtrlSave);

    return () => {
      document.removeEventListener('keydown', onCtrlSave);
    };
  }, []);

  return <KeyBindings.Provider value={values}>{children}</KeyBindings.Provider>;
};
