import { observable, action, makeObservable } from 'mobx';

import Theme from 'objects/Theme';
import { DBThemes } from '@firebase/dbUtil';
import { sortAlphabetically, sortAvailability } from 'utils/AppUtils';

import { RootStore } from './index';

export type ThemesStoreThemeType = Record<string, Theme>;

class ThemesStore {
  rootStore: RootStore;
  themes: ThemesStoreThemeType = {};

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      setThemes: action,
      fetchThemes: action,
      themes: observable,
    });

    this.rootStore = rootStore;
    this.fetchThemes();
  }

  setThemes(themes: ThemesStoreThemeType) {
    this.themes = themes;
  }

  async fetchThemes(): Promise<ThemesStoreThemeType> {
    if (Object.values(this.themes).length > 0) {
      return this.themes;
    }

    const themes = new DBThemes().list();
    this.setThemes(themes);

    return themes;
  }

  asOptions(): Theme[] {
    return Object.values(this.themes).sort(sortAlphabetically).sort(sortAvailability);
  }
}

export default ThemesStore;
