import { FC } from 'react';

import { useGameTheme } from 'features/Game/useGameTheme';

export type GameDecisionButtonProps = {
  label: string;
  onClick: () => void;
};

export const GameDecisionButton: FC<GameDecisionButtonProps> = ({ label, onClick }) => {
  const gameTheme = useGameTheme();

  return (
    <button className={gameTheme.classes.decision} onClick={onClick}>
      {label}
    </button>
  );
};
