import { useMemo, Fragment } from 'react';
import { Grow as MuiGrow } from '@material-ui/core';
import { Pace, Pause, WindupChildren } from 'windups';

export const EffectText = ({ children, pace = 30, InEffect = MuiGrow, inEffectStyle = {}, ...otherProps }) => {
  const Children = useMemo(() => {
    if (Array.isArray(children)) {
      return children.map((node, index) => (
        <Fragment key={index}>
          {node}
          <Pause ms={500} />
        </Fragment>
      ));
    }

    return children;
  }, [children]);

  return (
    <WindupChildren {...otherProps}>
      <Pace
        getPace={(char) => {
          if (pace === 0) return pace;
          if (char === '.') return pace * 10;
          if (char === '!') return pace * 10;
          if (char === '?') return pace * 10;
          if (char === ',') return pace * 5;

          return pace;
        }}
      >
        {Children}
      </Pace>
    </WindupChildren>
  );
};
