import { firebase } from '@firebase/firebaseUtil';
import { NodePosition } from 'features/FlowchartEditor/diagrams/DiagramSerializer';
import { FlowchartConfiguration } from 'objects/FlowchartConfiguration';

import { DBMap } from './DBStructures';
import { DBStepGroups } from './DBStepGroups';

export class DBFlowchart extends DBMap<FlowchartConfiguration> {
  public projectUID: string;
  public processUID: string;
  private processesRef: firebase.database.Reference;

  constructor(projectUID: string, processUID: string) {
    super();
    this.projectUID = projectUID.toString();
    this.processUID = processUID?.toString();
    this.processesRef = new DBStepGroups(this.projectUID).ref;
    this.ref = this.processesRef.child(this.processUID).child('flowchartConfiguration');
  }

  updateNodePosition = (id: string, position: NodePosition) => {
    return this.ref.child('nodesPosition').child(id).set(position);
  };
}
