import Settings from '../objects/Settings';
import { ImpactOptions } from 'objects/Impact';

interface Scores {
  score: number;
  stat: number;
}

interface ImpactScoreValues {
  tiny: number;
  small: number;
  medium: number;
  large: number;
  huge: number;
}

export default class ScoreUtils {
  public static BASE_SCORE = 10; // min score for each decision
  public static BONUS_COUNTDOWN = 20;

  public static gameNegativeTotals: ImpactScoreValues = {
    tiny: 0,
    small: 0,
    medium: 0,
    large: 0,
    huge: 0,
  };

  public static gamePositiveTotals: ImpactScoreValues = {
    tiny: 0,
    small: 0,
    medium: 0,
    large: 0,
    huge: 0,
  };

  public static resetTotals() {
    ScoreUtils.gamePositiveTotals = {
      tiny: 0,
      small: 0,
      medium: 0,
      large: 0,
      huge: 0,
    };

    ScoreUtils.gameNegativeTotals = {
      tiny: 0,
      small: 0,
      medium: 0,
      large: 0,
      huge: 0,
    };
  }

  public static getImpactScores(impact: ImpactOptions, settings: Settings): Scores {
    const scores: Scores = {
      score: 0,
      stat: 0,
    };
    switch (impact) {
      case 'Tiny':
        scores.score = settings.impactScoreValues.tiny;
        scores.stat = settings.impactStatValues.tiny;
        break;
      case 'Small':
        scores.score = settings.impactScoreValues.small;
        scores.stat = settings.impactStatValues.small;
        break;
      case 'Medium':
        scores.score = settings.impactScoreValues.medium;
        scores.stat = settings.impactStatValues.medium;
        break;
      case 'Large':
        scores.score = settings.impactScoreValues.large;
        scores.stat = settings.impactStatValues.large;
        break;
      case 'Huge':
        scores.score = settings.impactScoreValues.huge;
        scores.stat = settings.impactStatValues.huge;
        break;
      default:
        break;
    }
    return scores;
  }
}
