import React, { FC } from 'react';
import { CircularProgress, CircularProgressProps } from '@material-ui/core';

export type SpinnerLoadingProps = CircularProgressProps;

export const SpinnerLoading: FC<SpinnerLoadingProps> = (props) => {
  return <CircularProgress {...props} />;
};

export type SpinnerLoadingDivProps = {
  width: string | number;
  height: string | number;
  color: string;
} & Omit<SpinnerLoadingProps, 'color'>;

export const SpinnerLoadingDiv: FC<SpinnerLoadingDivProps> = ({ width, height, color, ...props }) => {
  return (
    <div style={{ width, height, color, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <SpinnerLoading color="inherit" {...props} />
    </div>
  );
};
