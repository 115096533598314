import { HttpRequester } from './HttpRequester';

export enum EmailTemplates {
  SignUp = 'd-46e5326135db4174be7c8848a3e4eccc',
  InviteMember = 'd-1e502b64bd744776bfeff74ff482e51b',
  KickMember = 'd-1e502b64bd744776bfeff74ff482e51b',
  LeftProject = 'd-1e502b64bd744776bfeff74ff482e51b',
}

const DEFAULT_TEMPLATE_ID = EmailTemplates.SignUp;

export interface UploadFileResponse {
  data: {
    id: string;
    type: string;
    attributes: {
      id: string;
      recipient_id: string;
      recipient_email: string;
      email_type: string;
      params: any;
      template_id: string;
      mail_service: string;
      created_at: string;
      updated_at: string;
    };
  };
}

interface BaseRequestParams {
  email: string;
  userUID?: string;
  templateId?: string;
  params?: any;
}

const baseRequest = ({ templateId = DEFAULT_TEMPLATE_ID, email, userUID, params = {} }: BaseRequestParams) => {
  return HttpRequester.post('/api/v1/transactionals', {
    email: {
      recipient_id: userUID,
      recipient_email: email,
      source: 'platform',
      template_id: templateId,
      params,
    },
  });
};

export const sendInviteMemberMail = ({
  email,
  userUID,
  projectUID,
  projectName,
  adminName,
  adminEmail,
  permission,
}: {
  email: string;
  userUID: string;
  projectUID: string;
  projectName: string;
  permission: 'edit' | 'view';
  adminEmail: string;
  adminName: string;
}) => {
  return baseRequest({
    email,
    userUID,
    templateId: EmailTemplates.InviteMember,
    params: { projectUID, project_name: projectName, admin_name: adminName, admin_email: adminEmail, permission },
  });
};

export const sendYouLeftProjectMail = ({ email, projectUID }: { email: string; projectUID: string }) => {
  return baseRequest({ email, templateId: EmailTemplates.LeftProject, params: { projectUID } });
};

export const sendRemoveMemberEmail = ({ email, projectUID }: { email: string; projectUID: string }) => {
  return baseRequest({ email, templateId: EmailTemplates.KickMember, params: { projectUID } });
};

export const sendSignUpMail = ({
  email,
  firstName,
  lastName,
}: {
  email: string;
  firstName: string;
  lastName: string;
}) => {
  return baseRequest({ email, templateId: EmailTemplates.SignUp, params: { email, firstName, lastName } });
};
