import Bugsnag from '@bugsnag/js';
import { observable, action, makeObservable } from 'mobx';
import Chapter from 'objects/Chapter';
import ProgressObject from 'objects/ProgressObject';
import { RootStore } from 'stores';

class GameStore {
  rootStore: RootStore;
  loaded = false;
  playerChapters: Chapter[];

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      loaded: observable,
      setPlayerChapters: action,
    });

    this.rootStore = rootStore;
  }

  setPlayerChapters = (playerChapters: Chapter[]): void => {
    this.playerChapters = playerChapters;
  };

  updateGameProgress = (progress: ProgressObject): void => {
    try {
      if (window.updateProgress) {
        window.updateProgress(progress);
      }
    } catch (e) {
      Bugsnag.addMetadata('Metadata', {
        method: 'Game.updateGameProgress',
        progress,
      });
    }
  };
}

export default GameStore;
