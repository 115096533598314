import { makeAutoObservable } from 'mobx';
import { ConfirmDialog, ConfirmDialogProps } from 'components/Dialogs';

import UIStore from './UIStore';
import { observer } from 'mobx-react';

class ModalStore {
  uiStore: UIStore;
  isOpen: boolean;
  Component: any;

  constructor(uiStore: UIStore) {
    makeAutoObservable(this);

    this.uiStore = uiStore;
  }

  confirm = (props: Partial<ConfirmDialogProps>) => {
    this.isOpen = true;
    this.Component = observer(() => (
      <ConfirmDialog
        {...props}
        open={this.isOpen}
        TransitionProps={{
          onExited: () => {
            this.clear();
          },
        }}
        onConfirm={() => {
          props.onConfirm?.();
          this.hide();
        }}
        onCancel={() => {
          props.onCancel?.();
          this.hide();
        }}
      />
    ));
  };

  hide = () => {
    this.isOpen = false;
  };

  show = () => {
    this.isOpen = true;
  };

  clear = () => {
    this.isOpen = false;
    this.Component = null;
  };
}

export default ModalStore;
