import { DBActiveUsers } from '@firebase/dbUtil/DBActiveUsers';
import { makeAutoObservable } from 'mobx';
import { auth } from '@firebase/firebaseUtil';

import { ActiveUser, ActiveUsers } from 'objects/Project';

import { RootStore } from './index';
import { generateTimestamp } from 'utils/DateUtils';

class ActiveUsersStore {
  rootStore: RootStore;
  activeUsers: ActiveUsers;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    this.activeUsers = {};
  }

  get projectUID() {
    return this.rootStore.projectStore.uid;
  }

  async getActiveUsersForProject(projectUID, callback?) {
    return new DBActiveUsers(projectUID).ref.on('value', callback);
  }

  async unsubscribe(projectUID) {
    return new DBActiveUsers(projectUID).ref.off('value');
  }

  async getCurrentProjectActiveUsers() {
    new DBActiveUsers(this.projectUID).ref.on('value', (snapshot) => {
      const val = snapshot.val() as ActiveUsers;
      this.activeUsers = val || {};
    });
  }

  addActiveUser(activeUser: ActiveUser) {
    new DBActiveUsers(this.projectUID).setItem(activeUser.userUID, {
      ...activeUser,
      lastActivity: generateTimestamp(),
    });
    this.activeUsers[activeUser.userUID] = activeUser;
  }

  removeCurrentActiveUser() {
    const userUID = auth.currentUser.uid;
    new DBActiveUsers(this.projectUID).removeItem(userUID);
    delete this.activeUsers[userUID];
  }

  updateActiveUser({ route, stepUID, processUID }: { route?: string; stepUID?: string; processUID?: number }) {
    if (!auth.currentUser) {
      return;
    }
    new DBActiveUsers(this.projectUID).setItem(auth.currentUser.uid, {
      ...this.currentActiveUser,
      route,
      stepUID,
      processUID,
      lastActivity: generateTimestamp(),
    });
  }

  get currentActiveUser(): ActiveUser {
    const defaultValue = {
      userUID: auth.currentUser?.uid,
      firstName: this.rootStore.userStore.user.firstName,
      avatarUrl: auth.currentUser?.photoURL,
      route: '/edit',
    };

    return this.activeUsers[auth.currentUser?.uid] || defaultValue;
  }
}

export default ActiveUsersStore;
