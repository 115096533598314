import React, { FC, useEffect, useCallback, lazy, Suspense } from 'react';
import { observer, inject } from 'mobx-react';
import { compose } from 'recompose';
import { Route, Switch } from 'react-router-dom';
import Bugsnag from '@bugsnag/js';
import qs from 'query-string';

import 'App.css';

import AppConfig from 'config/AppConfig';
import { Routes } from 'constants/routes';

import { DBUsers } from '@firebase/dbUtil';
import { auth } from '@firebase/firebaseUtil';

import AppUtils from 'utils/AppUtils';

import User from 'objects/User';

import { withAuthentication } from 'components/Sessions';

import { useNetwork } from 'hooks/useNetwork';
import { ModalContainer } from 'components/Dialogs/ModalContainer';

const DashboardAuth = lazy(() => import(/* webpackChunkName: "DashboardAuth" */ 'features/DashboardAuth'));
const Home = lazy(() => import(/* webpackChunkName: "Home" */ 'features/Home'));
const Account = lazy(() => import(/* webpackChunkName: "Account" */ 'features/Account'));
const Page404 = lazy(() => import(/* webpackChunkName: "Page404" */ 'features/Page404'));

const AppComponent: FC<any> = (props) => {
  const { learnerStore } = props;

  AppUtils.isMobile = window.innerWidth <= AppConfig.MOBILE_WIDTH;
  useNetwork();

  const updateUserStore = useCallback(
    (snapshot): void => {
      let user: User = new User();

      if (snapshot.val()) {
        user = { ...user, ...snapshot.val() };
        props.userStore.setUser(user);
      } else {
        if (auth?.currentUser?.uid) {
          if (!props.userStore.loaded) {
            props.userStore.setUser(user);
          }
        } else {
          return;
        }
      }
    },
    [props.userStore],
  );

  useEffect(() => {
    const params: any = qs.parse(window.location.search);

    if (params.actor) {
      try {
        const learner = JSON.parse(params.actor as string);
        const lmsParams = { ...params, actor: learner };
        learnerStore.setLMSParams(lmsParams);
        learnerStore.startLMS();
        Bugsnag.addMetadata('Params', params);
        Bugsnag.addMetadata('LMS Learner', lmsParams);
      } catch (e) {
        console.error(e);
        Bugsnag.addMetadata('Params', params);
        Bugsnag.notify('LMS configuration not set properly');
      }
    }

    auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        new DBUsers().getItem(authUser.uid, updateUserStore);
      } else {
        props.userStore.clear();
      }
    });
  }, []);

  return (
    <div className="app">
      <ModalContainer />
      <Suspense fallback={null}>
        <Switch>
          <Route exact path={Routes.SignUp}>
            <DashboardAuth page={Routes.SignUp} />
          </Route>
          <Route exact path={Routes.Auth}>
            <DashboardAuth page={Routes.SignIn} />
          </Route>
          <Route exact path={Routes.SignIn}>
            <DashboardAuth page={Routes.SignIn} />
          </Route>
          <Route exact path={Routes.PasswordForget}>
            <DashboardAuth page={Routes.PasswordForget} />
          </Route>
          <Route exact path={Routes.Account}>
            <Account />
          </Route>
          <Route exact path={Routes.NotFound}>
            <Page404 />
          </Route>
          <Route path={Routes.Home}>
            <Home />
          </Route>
        </Switch>
      </Suspense>
    </div>
  );
};

export const App = compose(withAuthentication, inject('userStore', 'learnerStore'), observer)(AppComponent);
