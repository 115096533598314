import React, { FC, useEffect, useState } from 'react';

import { storage } from '@firebase/firebaseUtil';
import { SpinnerLoading } from 'components/Loadings';

import { useOnboardingVideoStyles } from './OnboardingVideo.styles';

const videosRef = storage.ref('videos');

export const OnboardingVideo: FC<{ file: string }> = ({ file }) => {
  const classes = useOnboardingVideoStyles();
  const [mp4, setMp4] = useState(null);
  const [webm, setWebm] = useState(null);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  useEffect(() => {
    setIsVideoLoaded(false);
    setMp4(null);
    setWebm(null);
    videosRef
      .child(`${file}.mp4`)
      .getDownloadURL()
      .then((mp4Src) => setMp4(mp4Src));
    videosRef
      .child(`${file}.webm`)
      .getDownloadURL()
      .then((webmSrc) => setWebm(webmSrc));
  }, [file]);

  return (
    <div className={classes.root} key={file}>
      {!isVideoLoaded && <SpinnerLoading className={classes.loading} />}
      <video autoPlay loop className={classes.video} onLoadedData={() => setIsVideoLoaded(true)}>
        {webm && <source src={webm} type="video/webm; codecs=vp9,vorbis" />}
        {mp4 && <source src={mp4} type="video/mp4" />}
      </video>
    </div>
  );
};
