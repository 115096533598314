export const removeHTMLFromString = (string = ''): string => {
  return string.replace(/<\/?[^>]+(>|$)/g, '');
};

export const removeParagraphFromString = (string = ''): string => {
  return string.replaceAll(/<\/?p[^>]*>/g, '');
};

export class Regex {
  static wordUnderscoreSpace(str = ''): boolean {
    const reg = /^(\w+\s?)*$/;
    return reg.test(str);
  }
}

export const encryptEmail = (email: string): string => {
  return btoa(email);
};

export const decryptEmail = (hash: string): string => {
  return atob(hash);
};
