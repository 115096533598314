import { observable, action, makeObservable } from 'mobx';

import { DBVariables } from '@firebase/dbUtil';

import { RootStore } from './index';
import { Variables } from 'objects/Variable';

class VariableStore {
  rootStore: RootStore;
  variables: Variables;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      variables: observable,
      setVariable: action,
    });

    this.rootStore = rootStore;
  }

  load = async () => {
    const variablesSnapshot = await new DBVariables(this.rootStore.projectUID).get();
    const variables = variablesSnapshot.val();
    this.variables = variables || {};
  };

  setVariable = (key: string, value: string) => {
    this.variables[key] = value;
    new DBVariables(this.rootStore.projectUID).setProperty(key, value);
  };
}

export default VariableStore;
