import { Dialog as MuiDialog, DialogProps as MuiDialogProps } from '@material-ui/core';
import React, { FC } from 'react';

export type DialogProps = MuiDialogProps;

export const Dialog: FC<DialogProps> = ({ children, ...props }) => (
  <MuiDialog fullWidth maxWidth="sm" {...props}>
    {children}
  </MuiDialog>
);
