import { v4 as uuidv4 } from 'uuid';

import { generateTimestamp } from 'utils/DateUtils';

import Decision, { DecisionOutcome, Movement } from './Decision';
import States from './States';
import { ImpactOptions } from './Impact';
import { Logic } from './Logic';

import StepStyle from './StepStyle';

export type Steps = Record<string, Step>;

export enum StepType {
  Start = 'start',
  GameWin = 'gameWin',
  GameFail = 'gameFail',
  Highscore = 'highscore',
  Decision = 'decision',
  Logic = 'logic',
  Style = 'style',
  Dialog = 'dialog',
  VideoBackground = 'videoBackground',
}

export enum StepVariant {
  Stacked = 'stacked',
  Fullscreen = 'fullscreen',
}

export enum StepAlignmentStyle {
  Default = 'left',
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

export type StepAlignmentOptions = 'left' | 'center' | 'right';

export default class Step {
  id = uuidv4();
  text = '';
  currentDecisionId = 1;
  canDelete = true;
  decisions: Decision[] = [{ ...new Decision() }];
  multiselect = true;
  imgUrl = '';
  imgId = '';
  videoUrl = '';
  avatarUrl = '';
  logic?: Logic = null;
  style?: StepStyle = null;
  type = StepType.Decision;
  variant? = StepVariant.Stacked;
  locked = false;
  hasError = false;
  nickname = '';
  updatedAt?: number = generateTimestamp();
  textAlignment: StepAlignmentOptions = null;

  constructor(initial: Partial<Step> = {}) {
    Object.assign(this, initial);
  }

  public static getContinueStory({
    step,
    stepGroup,
    messages,
  }: {
    step: string;
    stepGroup: number;
    messages: Record<string, string>;
  }): Step {
    return {
      id: -1,
      text: messages.game_continue_from_checkpoint,
      imgUrl: '',
      imgId: '',
      videoUrl: '',
      avatarUrl: '',
      currentDecisionId: 1,
      canDelete: false,
      hasError: false,
      nickname: '',
      type: StepType.Decision,
      textAlignment: null,
      decisions: [
        {
          id: uuidv4(),
          decision: messages.game_continue,
          outcome: DecisionOutcome.None,
          impact: ImpactOptions.none,
          jumpPage: step,
          jumpChapter: stepGroup.toString(),
          move: Movement.Continue,
          linkURL: '',
          showPlayerStats: false,
          hidden: false,
        },
        {
          id: uuidv4(),
          decision: messages.game_replay,
          outcome: DecisionOutcome.None,
          impact: ImpactOptions.none,
          jumpPage: States.restart,
          jumpChapter: '0',
          move: Movement.Stay,
          linkURL: '',
          showPlayerStats: false,
          hidden: false,
        },
      ],
      multiselect: true,
      locked: true,
    };
  }

  public static getAcceptTermsStory({
    step,
    stepGroup,
    intl,
    fileUrl,
  }: {
    step: string;
    stepGroup: number;
    intl: any;
    fileUrl: string;
  }): Step[] {
    const { messages } = intl;

    const confirmRefuseTermsStep = {
      id: uuidv4(),
      text: `<p>${messages.game_confirm_refusing_policy?.replace(/{fileUrl}/, fileUrl)}</p>`,
      imgUrl: '',
      imgId: '',
      videoUrl: '',
      avatarUrl: '',
      currentDecisionId: 1,
      canDelete: false,
      hasError: false,
      nickname: '',
      type: StepType.Decision,
      textAlignment: null,
      decisions: [
        {
          id: uuidv4(),
          decision: messages.game_accept_customer_policy,
          outcome: DecisionOutcome.None,
          impact: ImpactOptions.none,
          jumpChapter: stepGroup.toString(),
          jumpPage: step,
          move: Movement.Stay,
          linkURL: '',
          termsAcceptance: States.acceptPrivacyPolicy,
          showPlayerStats: false,
          hidden: false,
        },
        {
          id: uuidv4(),
          decision: messages.game_refused_policy,
          outcome: DecisionOutcome.None,
          impact: ImpactOptions.none,
          jumpChapter: States.signOut,
          jumpPage: States.signOut,
          move: Movement.Stay,
          linkURL: '',
          termsAcceptance: States.refusePrivacyPolicy,
          showPlayerStats: false,
          hidden: false,
        },
      ],
      multiselect: true,
      locked: true,
    };

    return [
      {
        id: uuidv4(),
        text: `<p>${messages.game_customer_policy_acceptance?.replace(/{fileUrl}/, fileUrl)}</p>`,
        imgUrl: '',
        imgId: '',
        avatarUrl: '',
        videoUrl: '',
        currentDecisionId: 1,
        canDelete: false,
        hasError: false,
        nickname: '',
        type: StepType.Decision,
        textAlignment: null,
        decisions: [
          {
            id: uuidv4(),
            decision: messages.game_accept_customer_policy,
            outcome: DecisionOutcome.None,
            impact: ImpactOptions.none,
            jumpChapter: stepGroup.toString(),
            jumpPage: step,
            move: Movement.Stay,
            linkURL: '',
            termsAcceptance: States.acceptPrivacyPolicy,
            showPlayerStats: false,
            hidden: false,
          },
          {
            id: uuidv4(),
            decision: messages.game_refuse_customer_policy,
            outcome: DecisionOutcome.None,
            impact: ImpactOptions.none,
            jumpChapter: States.nextPage,
            jumpPage: confirmRefuseTermsStep.id,
            move: Movement.Stay,
            linkURL: '',
            termsAcceptance: States.refusePrivacyPolicy,
            showPlayerStats: false,
            hidden: false,
          },
        ],
        multiselect: true,
        locked: true,
      },
      confirmRefuseTermsStep,
    ];
  }

  static buildNewStep() {
    const step = new Step();
    step.text = '';

    const decision = new Decision();
    decision.hidden = true;
    step.decisions = [decision];

    return { ...step };
  }

  static buildNewDialogStep() {
    const step = new Step();
    step.text = '';
    step.type = StepType.Dialog;

    const decision = new Decision();
    decision.hidden = true;
    step.decisions = [decision];

    return { ...step };
  }

  static buildNewVideoBackgroundStep() {
    const step = new Step();
    step.type = StepType.VideoBackground;
    step.text = '';

    step.decisions = [{ ...new Decision() }];

    return { ...step };
  }

  static buildNewStyleStep({ type = 'avatar' }: { type: 'avatar' | 'background' }) {
    const step = new Step();
    step.type = StepType.Style;
    step.style = new StepStyle();
    step.style.type = type;

    return step;
  }

  static buildNewLogicStep({ type = 'IF' }: { type?: 'IF' | 'SET' }) {
    const step = this.buildNewStep();
    step.type = StepType.Logic;
    step.logic = {
      type,
      variable: null,
      value: 'TRUE',
      operator: '=',
      true: {
        jumpChapter: '-1',
        jumpPage: '-1',
      },
      false: {
        jumpChapter: '-1',
        jumpPage: '-1',
      },
    };

    return { ...step };
  }

  static buildLeaderboardStep() {
    const step = new Step();
    step.type = StepType.Highscore;

    const decision = new Decision();
    step.decisions = [decision];

    return { ...step };
  }
}
