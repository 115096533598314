import { remoteConfig } from './firebaseUtil';

remoteConfig.settings = {
  fetchTimeoutMillis: 60000, // 1 minute
  minimumFetchIntervalMillis: process.env.NODE_ENV === 'development' ? 1000 : 3600000, // 1 hour
};

remoteConfig.defaultConfig = {
  flowchart: false,
  maintenance: JSON.stringify({
    app: false,
  }),
};

export { remoteConfig };
