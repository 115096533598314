import React, { FC } from 'react';

import { IconButton } from '@material-ui/core';
import { Close as IconClose } from '@material-ui/icons';
import { useSnackbar } from 'notistack';

export const SnackbarCloseButton: FC = () => {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton aria-label="close" onClick={() => closeSnackbar()}>
      <IconClose style={{ color: 'white' }} />
    </IconButton>
  );
};
