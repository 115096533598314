declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    componentsSpacing: {
      adminSideMenu: number;
      adminHeader: number;
      flowchartHeaderSpacing: number;
    };
  }
  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    componentsSpacing?: {
      adminSideMenu: number;
      adminHeader: number;
      flowchartHeaderSpacing: number;
    };
  }
}

export const componentsSpacing = {
  adminSideMenu: 73,
  adminHeader: 64,
  flowchartHeaderSpacing: 62,
};
