import Settings from 'objects/Settings';
import { AvailableRegions } from 'storage-client';
import FileUploadService from '../FileUploadService';

interface PublishSettingsServiceParams {
  projectUID: string;
  locale: string;
  settings: Settings;
  region?: AvailableRegions;
}

export default class PublishSettingsService {
  locale: string;
  projectUID: string;
  path: string;
  region?: AvailableRegions;
  settings: Settings;

  constructor({ projectUID, locale = 'en-US', region = 'us', settings }: PublishSettingsServiceParams) {
    this.projectUID = projectUID;
    this.locale = locale;
    this.settings = settings;
    this.region = region;
    this.path = this.locale;
  }

  async perform() {
    const service = new FileUploadService({
      type: 'settings',
      projectUID: this.projectUID,
      region: this.region,
    });

    service.addJSON(this.settings, this.path);
    return await service.upload();
  }
}
