import { ABILITIES, Ability } from 'objects/Ability';
import { UserRoles } from 'objects/User';
import { Timestamp } from 'utils/DateUtils';

export class Member {
  email: string;
  userUID?: string = null;
  lastActive: Timestamp = null;
  role: UserRoles = UserRoles.Viewer;

  constructor(params: Partial<Member>) {
    Object.assign(this, params);
  }

  can(doSomething: keyof Ability) {
    try {
      return ABILITIES[this.role][doSomething];
    } catch {
      return false;
    }
  }

  cannot(doSomething: keyof Ability) {
    return !this.can(doSomething);
  }
}
