import { MouseEvent, useState, FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { Icon } from 'react-icons-kit';
import { ic_fullscreen as ICFullscreen } from 'react-icons-kit/md/ic_fullscreen';
import { ic_zoom_out_map as ICZoomOutMap } from 'react-icons-kit/md/ic_zoom_out_map';

import SettingsStore from 'stores/settingsStore';

export enum STEP_IMAGE_POSITIONS {
  contain = 'contain',
  cover = 'cover',
}

const useStyles = makeStyles((theme) => ({
  toggleContainer: {
    margin: theme.spacing(2, 0),
  },
}));

export interface SettingsStepImageFitToggleComponentProps {
  settingsStore: SettingsStore;
}

export const SettingsStepImageFitToggleComponent: FC<SettingsStepImageFitToggleComponentProps> = ({
  settingsStore,
}) => {
  const classes = useStyles();
  const [alignment, setAlignment] = useState<string | null>(settingsStore.settings.stepImageFit);

  const handleAlignment = (_event: MouseEvent<HTMLElement>, newAlignment: string | null) => {
    setAlignment(newAlignment);
    settingsStore.setSettingsProperty('stepImageFit', newAlignment);
  };

  return (
    <div className={classes.toggleContainer}>
      <ToggleButtonGroup
        value={alignment}
        exclusive
        size="small"
        onChange={handleAlignment}
        aria-label="text alignment"
      >
        <ToggleButton value={STEP_IMAGE_POSITIONS.contain} aria-label={STEP_IMAGE_POSITIONS.contain}>
          <Icon size={24} icon={ICFullscreen} />
        </ToggleButton>
        <ToggleButton value={STEP_IMAGE_POSITIONS.cover} aria-label={STEP_IMAGE_POSITIONS.cover}>
          <Icon size={24} icon={ICZoomOutMap} />
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};
