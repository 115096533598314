import { db } from '../firebaseUtil';
import { DBList } from './DBStructures';
import Invite from 'objects/Invite';

export class DBInvites extends DBList<Invite> {
  constructor() {
    super();
    this.ref = db.ref('invites');
  }

  public async inviteUser(email: string, projectUID: string) {
    const userUID = btoa(email);
    this.ref.child(userUID).child(projectUID).set(projectUID);
  }
}
