export type LocalStorageKeys = 'lmsParams';

export default class LocalStorageService {
  provider: Storage;

  constructor() {
    this.provider = window.localStorage;
  }

  static get(key: LocalStorageKeys) {
    const instance = new LocalStorageService();
    return instance.getItem(key);
  }

  static set(key: LocalStorageKeys, value: any) {
    const instance = new LocalStorageService();
    return instance.setItem(key, value);
  }

  static delete(key: LocalStorageKeys) {
    const instance = new LocalStorageService();
    instance.removeItem(key);
  }

  setItem(key: LocalStorageKeys, value: any): void {
    this.provider.setItem(key, JSON.stringify(value));
  }

  getItem(key: LocalStorageKeys) {
    const value = this.provider.getItem(key);

    if (value) {
      try {
        return JSON.parse(value);
      } catch {
        return value;
      }
    }

    return undefined;
  }

  removeItem(key: string): void {
    this.provider.removeItem(key);
  }
}
