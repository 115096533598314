export enum StepStyleTransitionEffects {
  Crossfade = 'crossfade',
  FadeOver = 'fade_over',
  SlideUp = 'slide_up',
  SlideDown = 'slide_down',
  SlideLeft = 'slide_left',
  SlideRight = 'slide_right',
  None = 'none',
}

export type StepStylePosition = 'background' | 'middle' | 'foreground';
export type StepStyleType = 'avatar' | 'background';

export enum StepStyleTransitionEffectsLabels {
  crossfade = 'Crossfade',
  fade_over = 'Fade over',
  slide_up = 'Slide up',
  slide_down = 'Slide down',
  slide_left = 'Slide left',
  slide_right = 'Slide right',
  none = 'None',
}

export enum StepStylePositionLabels {
  background = 'Background',
  middle = 'Middle',
  foreground = 'Foreground',
}

export const stepStyleTransitionEffectsInverted: Record<StepStyleTransitionEffects, StepStyleTransitionEffects> = {
  crossfade: StepStyleTransitionEffects.Crossfade,
  fade_over: StepStyleTransitionEffects.FadeOver,
  slide_up: StepStyleTransitionEffects.SlideDown,
  slide_down: StepStyleTransitionEffects.SlideUp,
  slide_left: StepStyleTransitionEffects.SlideRight,
  slide_right: StepStyleTransitionEffects.SlideLeft,
  none: StepStyleTransitionEffects.None,
};

export default class StepStyle {
  transition: StepStyleTransitionEffects = StepStyleTransitionEffects.SlideUp;
  position: StepStylePosition = 'background';
  type: StepStyleType = 'avatar';
  loopVideo = false;
}
