import { v4 as uuid } from 'uuid';

import { Timestamp } from 'utils/DateUtils';
import { createInitialChapters } from 'factories/chaptersFactory';

import Settings from '../Settings';
import User from '../User';
import Chapter from '../Chapter';
import LeaderboardObject from '../LeaderboardObject';
import { Team } from '../Team';
import { styleTheme } from '../StyleTheme';

import { Member } from './Member';
import { ActiveUser } from './ActiveUser';

const firstStyleThemeUUID = uuid();

export enum ProjectStatus {
  NotPublished = 'notPublished',
  Draft = 'draft',
  Published = 'published',
  Inactive = 'inactive',
}

export type ProjectStatuses =
  | ProjectStatus.Draft
  | ProjectStatus.Published
  | ProjectStatus.Inactive
  | ProjectStatus.NotPublished;

export interface ProjectState {
  lastPublishedAt?: Timestamp;
  status: ProjectStatuses;
}

export type ActiveUsers = Record<string, ActiveUser>;

export default class Project {
  slug: string;
  dateCreated: number;
  adminUID: string;
  name = 'My New Project';
  chapters: Chapter[] = createInitialChapters();
  settings: Settings = new Settings();
  leaderboard: { [key: string]: LeaderboardObject } = {};
  users: User[] = [];
  state: ProjectState = {
    status: ProjectStatus.NotPublished,
  };
  activeUsers: ActiveUsers = {};
  teams?: { [key: string]: Team } = {};
  members: Record<string, Member> = {};
  styleThemes = {
    activeID: firstStyleThemeUUID,
    names: {
      [firstStyleThemeUUID]: 'custom',
    },
    themes: {
      [firstStyleThemeUUID]: styleTheme,
    },
  };
}
