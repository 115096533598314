import { UploadFileResponse, HttpRequester, AvailableRegions } from './HttpRequester';

interface UploadMethodParams {
  projectUID: string;
  file: File | string;
  filename: string;
  region: AvailableRegions;
}

export default class FileUploader {
  static async upload({ projectUID, file, filename, region }: UploadMethodParams): Promise<UploadFileResponse> {
    const params = new FormData();

    params.append('file', file);
    params.append('filename', filename);
    params.append('projectUID', projectUID);
    params.append('region', region);

    return HttpRequester.post('/upload', params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}
