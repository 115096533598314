import { observable, action, makeObservable } from 'mobx';
import { RootStore } from 'stores';
import ModalStore from './ModalStore';

class UIStore {
  rootStore: RootStore;
  loaded = true;
  drawer = {
    open: false,
  };
  modal: ModalStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      loaded: observable,
      drawer: observable,
      setDrawerOpen: action,
    });

    this.rootStore = rootStore;
    this.modal = new ModalStore(this);
  }

  setDrawerOpen = (open) => {
    this.drawer.open = open;
  };
}

export default UIStore;
